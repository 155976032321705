import React, { useEffect, useState, Fragment, useContext } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import { Button } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import PhonelinkOffIcon from '@mui/icons-material/PhonelinkOff';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { QRCodeSVG } from 'qrcode.react';
import domtoimage from 'dom-to-image';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import { makeStyles } from 'tss-react/mui';
import { SurveyContext } from './context';
import SurveysService from '../../services/SurveysService';
import ISO6391 from 'iso-639-1';
import { isBlank } from '../../utils/utils';
import {LoadData} from '../../Constants.js';

const surveysService = new SurveysService();

const useStyles = makeStyles()(theme => ({
  titleIcon: {
      marginRight: '12px',
      verticalAlign: 'sub'
  },
  popupContentHeight: {
    minHeight: '560px',
  },
  selectLanguage: {
    width: 100,
    marginBottom: 20,
  },
  marginBottom: {
    marginBottom: 3
  }
}));

export default function CopyLinkDialog(props) {

  const { classes } = useStyles();

  const {
    t,
    openState,
    openSnackbar,
    showSpinner,
    callbackOnclose,
    handleCopyFormLinkInClipboard
  } = props;

  const { formIdParameter, getFormConfiguration } = useContext(SurveyContext);

  const [langsAvailable, setLangsAvailable] = useState([]);
  const [selectedLang, setSelectedLang] = useState(getFormConfiguration().lang);
  const [selectedType, setSelectedType] = useState(undefined);
  const [generatedLink, setGeneratedLink] = useState(undefined);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (!openState || loadData !== LoadData.Load) return;

    showSpinner(true);

    setLoadData(LoadData.Loading);

    setSelectedType(undefined);
    setGeneratedLink(undefined);

    surveysService.getFormTranslations(formIdParameter)
        .then(result => {
          setLangsAvailable(result.data.map(l => l.locale));
          setSelectedLang(result.data.find(l => l.primary)?.locale);
        })
        .catch(err => {
          setLangsAvailable([]);
          setSelectedLang(undefined);
          openSnackbar('error', t("react.error.fetch.message"));
        }).finally(() => {
          setLoadData(LoadData.Loaded);
          showSpinner(false);
        });
  }, [openState, loadData]);

  const handleSelectLang = (lang) => {
    setSelectedLang(lang);
    handleGenerateLink(lang, selectedType);
  };

  const handleSelectType = (type) => {
    setSelectedType(type);
    handleGenerateLink(selectedLang, type);
  };

  const handleGenerateLink = (lang, type) => {
    // base URL is now treated by ObfuscatedCollector ('/of/')
    var baseUrl = `${window.location.origin}/collect/of/${getFormConfiguration().collectUuid}`;

    var params = [];
    if(!isBlank(lang)) {
      params.push(`lang=${lang}`);
    }

    if (type === 'normal') {
      // no specific parameter
    } else if (type === 'resusable') {
      // Type Of Link is 'resusable'
      params.push('tol=r');
    } else if (type === 'mailing') {
      // Type Of Link is 'mailing'
      params.push(`id=[user_mailing_id]`);
    } else if (type === 'testing') {
      // change the baseUrl in this case
      baseUrl = `${window.location.origin}/collect/test/${getFormConfiguration().id}`;
      params.push('tol=t');
    }

    var targetUrl = baseUrl + (params.length === 0 ? '' : `?${params.join('&')}`);
    setGeneratedLink(targetUrl);
  };

  const handleSelectFormLink = () => {
    handleCopyFormLinkInClipboard(generatedLink);
    callbackOnclose();
  };

  const handleOnclose = (event) => {
    event.preventDefault();
    callbackOnclose();
  };

  const downloadQRCode = () => {
    const input = document.getElementById(`QRCodeSVG-${formIdParameter}`);
    var fname = `QRCode-questionnaire-${formIdParameter}.jpg`;
    return domtoimage
      .toJpeg(input, { bgcolor: '#fff' })
      .then(function (imgData) {
        window.saveAs(imgData, fname);
      });
  };

  if(!openState || loadData !== LoadData.Loaded) return null;

  return (
    <Dialog
      open={openState}
      onClose={handleOnclose}
    >
      <DialogTitleWithCloseIcon
        startIcon={<HelpIcon color='secondary' className={classes.titleIcon}/>}
        title={t('react.project.collectforms.toolbar.link.popup.title')}
        callbackOnclose={handleOnclose}
      />
      <DialogContent className={classes.popupContentHeight}>
        {langsAvailable.length > 0 && <>
        <DialogContentText id="alert-dialog-description" className={classes.marginBottom}>
          {t('react.project.collectforms.toolbar.link.popup.select.language')}
        </DialogContentText>
        <Select
          value={selectedLang}
          onChange={(e) => handleSelectLang(e.target.value)}
          className={classes.selectLanguage}
        >
          {langsAvailable.map((lang) => (
              <MenuItem key={lang} value={lang}>
                  {ISO6391.getNativeName(lang)}
              </MenuItem>
          ))}
        </Select></>}
        <DialogContentText id="alert-dialog-description">
          {t('react.project.collectforms.toolbar.link.popup.description')}
        </DialogContentText>
        <List component="nav">
          <ListItem button selected={selectedType === 'testing'} onClick={(event) => handleSelectType('testing')}>
            <ListItemIcon>
              <PhonelinkOffIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('react.project.collectforms.toolbar.link.popup.type.testing')}
              secondary={<Fragment>{t('react.project.collectforms.toolbar.link.popup.type.testing.description')}</Fragment>}
            />
          </ListItem>
          <ListItem button selected={selectedType === 'normal'} onClick={(event) => handleSelectType('normal')}>
            <ListItemIcon>
              <HowToRegIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('react.project.collectforms.toolbar.link.popup.type.normal')}
              secondary={<Fragment>{t('react.project.collectforms.toolbar.link.popup.type.normal.description')}</Fragment>}
            />
          </ListItem>
          <ListItem button selected={selectedType === 'resusable'} onClick={(event) => handleSelectType('resusable')}>
            <ListItemIcon>
              <AllInclusiveIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('react.project.collectforms.toolbar.link.popup.type.resusable')}
              secondary={<Fragment>{t('react.project.collectforms.toolbar.link.popup.type.resusable.description')}</Fragment>}
            />
          </ListItem>
          <ListItem button selected={selectedType === 'mailing'} onClick={(event) => handleSelectType('mailing')}>
            <ListItemIcon>
              <MailOutlineIcon />
            </ListItemIcon>
            <ListItemText
              primary={t('react.project.collectforms.toolbar.link.popup.type.mailing')}
              secondary={<Fragment>{t('react.project.collectforms.toolbar.link.popup.type.mailing.description')}</Fragment>}
            />
          </ListItem>
        </List>
        {generatedLink !== undefined && selectedType !== 'mailing' && selectedType !== undefined &&
          <Tooltip title={t('react.project.collectforms.toolbar.link.popup.download.qrcode')} onClick={e => downloadQRCode()}>
            <Box id={`QRCodeSVG-${formIdParameter}`} style={{ float: 'right', cursor: 'pointer' }}>
              <QRCodeSVG value={generatedLink} level='L' />
            </Box>
          </Tooltip>
        }
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnclose} color="primary">
          {t('react.button.cancel')}
        </Button>
        <Button onClick={e => handleSelectFormLink()} color="primary" disabled={selectedType === undefined}>
          {t('react.project.collectforms.toolbar.link.popup.copy')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
