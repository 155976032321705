import React, { useState, useEffect } from 'react';
import { RestClientService } from '../../services/RestClientService';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import CachedIcon from '@mui/icons-material/Cached';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FlipCameraIosIcon from '@mui/icons-material/FlipCameraIos';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';
import SettingsIcon from '@mui/icons-material/Settings';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import {LoadData} from '../../Constants.js'
import CastConnectedIcon from '@mui/icons-material/CastConnected';
import Button from '@mui/material/Button';
import CastIcon from '@mui/icons-material/Cast';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import StopScreenShareIcon from '@mui/icons-material/StopScreenShare';
import { Box } from '@mui/system';
import ReactJson from '@microlink/react-json-view'
import {Helmet} from "react-helmet";

const restClient = RestClientService.getClient();

const useStyles = makeStyles()(theme => ({
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  dividerFullWidth: {
    margin: '20px 0 15px 0',
  },
  toolbar: {
    paddingLeft: theme.spacing(0),
  },
  reactJson: {
    fontSize: '14px',
  }
}));

export default function Status(props) {

  const {openSnackbar, t} = props;

  const { classes } = useStyles();

  const [ping, setPing] = useState({});
  const [properties, setProperties] = useState({});
  const [onlineUsers, setOnlineUsers] = useState({});
  const [openConfirmStopAgentsDialog, setOpenConfirmStopAgentsDialog] = useState(false);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    var promises = [];
    promises.push(restClient.get('/api/info/v1.0/properties'));
    promises.push(restClient.get('/api/info/v1.0/ping'));
    promises.push(restClient.get('/api/users/v1.0/online'));

    Promise.all(promises)
    .then(results => {
      setProperties(results[0].data);
      setPing(results[1].data);
      setOnlineUsers(results[2].data);
    })
    .catch(err => {
    }).finally(() => {
      setLoadData(LoadData.Loaded);
    });
  }, [loadData]);

  if(loadData !== LoadData.Loaded) {
    return (
      <LinearProgress />
    );
  }

  const handleRefreshProperties = event => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  }

  const handleReloadProperties = event => {
    event.preventDefault();
    restClient.put('/api/info/v1.0/properties')
      .then(result => {
        setLoadData(LoadData.Load);
      })
      .catch(err => {
      });
  }

  const handleOpenConfirmStopAgents = event => {
    event.preventDefault();
    setOpenConfirmStopAgentsDialog(true);
  };
  const handleCancelStopAgents = () => {
    setOpenConfirmStopAgentsDialog(false);
  };
  const handleApplyStopAgents = () => {
    restClient.put('/api/info/v1.0/agents/permanent/stop')
      .then(result => {
        openSnackbar('success', 'Stop request has been sent to permanent agents')
        setOpenConfirmStopAgentsDialog(false);
      })
      .catch(err => {
        openSnackbar('error', 'An error occurred while trying to send the stop request to permanent agents')
      });
  };

  const handleTestMessaging = event => {
    event.preventDefault();
    restClient.put('/api/info/v1.0/messaging/test/connectivity')
      .then(result => {
        openSnackbar('success', 'Broadcast message has been successfully sent')
      })
      .catch(err => {
        openSnackbar('error', 'An error occurred while trying to broadcast a test message')
      });
  }

  return (
    <Paper className={classes.paper}>
      <Helmet title={t('react.helmet.status')} />
      <PopupTwoButtons
          variant='warning'
          openState={openConfirmStopAgentsDialog}
          callbackOnclose={handleCancelStopAgents}
          callbackOnclickLeftButton={handleCancelStopAgents}
          callbackOnclickRightButton={handleApplyStopAgents}
          title='Confirm stop of permanent agents'
          content='You are going to send a "Stop message" to permanent agents. This action requires a confirmation because you will not be able to restart agents from frontend. Do you really want to stop permanent agents ?'
          leftButton={t('react.button.cancel')}
          rightButton={t('react.button.confirm')}
      />

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <SettingsIcon style={{ marginRight: '10px' }}/><Typography className={classes.heading}>Application properties</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Toolbar className={classes.toolbar}>
                <Tooltip title="Rafraichir">
                  <IconButton onClick={handleRefreshProperties} size="large">
                    <CachedIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Recharger les properties">
                  <IconButton onClick={handleReloadProperties} size="large">
                    <FlipCameraIosIcon />
                  </IconButton>
                </Tooltip>
              </Toolbar>
              <Box className={classes.reactJson}>
                <ReactJson src={properties} />
              </Box>
            </Grid>
          </Grid>
          </AccordionDetails>
      </Accordion>

      <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            id="panel1a-header"
          >
            <SettingsRemoteIcon style={{ marginRight: '10px' }}/><Typography className={classes.heading}>Last Ping</Typography>
          </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box className={classes.reactJson}>
              <ReactJson src={ping} />
            </Box>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <RecentActorsIcon style={{ marginRight: '10px' }}/><Typography className={classes.heading}>Online users</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {properties.monitorOnlineUsers === true ? (
            <Box className={classes.reactJson}>
              <ReactJson src={onlineUsers} />
            </Box>
            ) : ('This feature is currently disabled (Refer to property: properties.monitorOnlineUsers)')
          }
          </Grid>
        </Grid>
      </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <StopScreenShareIcon style={{ marginRight: '10px' }}/><Typography className={classes.heading}>Stop permanent agents</Typography>
        </AccordionSummary>
        <AccordionDetails>
        <Grid container spacing={3}>
        <Grid item xs={12}>
            <Button
              variant="contained"
              className={classes.button}
              startIcon={<CancelPresentationIcon />}
              onClick={handleOpenConfirmStopAgents}>
              Send stop message
            </Button>
          </Grid>
        </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <CastConnectedIcon style={{ marginRight: '10px' }}/><Typography className={classes.heading}>Test Messaging</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Button
                variant="contained"
                className={classes.button}
                startIcon={<CastIcon />}
                onClick={handleTestMessaging}>
                Send broadcast message
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

    </Paper>
  );
}
