import React from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import { Status } from '../components/Status';
import { Welcome } from '../components/Welcome';
import { ResellersList } from '../components/ResellersList';
import { CustomersList } from '../components/CustomersList';
import { UsersList } from '../components/UsersList';
import { UserEdit } from '../components/UserEdit';
import { ProjectsList } from '../components/ProjectsList';
import { ProjectEdit } from '../components/ProjectEdit';
import { ProjectDashboard} from '../components/ProjectDashboard';
import { TranslationList } from '../components/TranslationList';
import { Grouping } from '../components/Grouping';
import { AdminTools } from '../components/AdminTools';
import { ObfuscatedCollectors, CollectorsTest } from '../components/Collectors';
import { Codification } from '../components/Codification';
import { SurveysList, Surveys } from '../components/Surveys';
import { Reporting} from '../components/SurveyReporting';
import { Datamap} from '../components/SurveyDatamap';
import { BearerSignIn } from '../components/SignIn';
import { Documentation } from '../components/Documentation';
import { DictionaryList } from '../components/Dictionary';
import { SubscriptionsList } from '../components/SubscriptionsList';
import { ProjectAnalytics } from '../components/ProjectAnalytics';
import { AiTools } from '../components/AiTools';
import SubscriptionsReporting from '../components/SubscriptionsList/SubscriptionsReporting';

import { RouteWithLayout, DashboardLayout, LoginLayout, LogoutLayout, EmptyLayout } from '../layouts';

/*
####################################################################################
## ADDING A NEW ROUTE ABOVE IMPLIES TO DECLARE THE ROUTE IN THE FILE: rewrite.config
####################################################################################
*/

export default function ApplicationRoutes() {

  return (
    <Routes>
      {/* this is the login page - no auth required */}
      <Route
        path="/login/:actionParameter?"
        element={<LoginLayout />} />

      {/* this is the logout page - no auth required */}
      <Route
        path="/logout"
        element={<LogoutLayout />} />

      {/* this is the SignIn with a token page - no auth required */}
      <Route path="/signin/bearer" element={<RouteWithLayout authRequired={false} layout={EmptyLayout} component={BearerSignIn}/>} />

      {/* this is the collect page - no auth required */}
      <Route path="/collect/test/:collectIdParameter" element={<RouteWithLayout authRequired={true} layout={EmptyLayout} hideCopyright={true} component={CollectorsTest}/>}/>
      <Route path="/collect/of/:collectUuidParameter" element={<RouteWithLayout authRequired={false} layout={EmptyLayout} hideCopyright={true} component={ObfuscatedCollectors}/>}/>

      {/* --------------- AFTER THIS POINT USER (OR VISITOR) MUST BE authenticated --------------- */}

      {/* Redirect home to the dashboard */}
      <Route
        path="/"
        element={<Navigate to="/dashboard" replace/>} />

      {/* special routes below */}
      <Route path="/dashboard" element={<RouteWithLayout layout={DashboardLayout} component={Welcome} />} />

      <Route path="/projects" element={<RouteWithLayout layout={DashboardLayout} component={ProjectsList}/>} />
      <Route path="/projects/:projectIdParameter/dashboard" element={<RouteWithLayout layout={DashboardLayout} component={ProjectDashboard}/>} />
      <Route path="/projects/:projectIdParameter/dashboard/analytics" element={<RouteWithLayout layout={DashboardLayout} component={ProjectAnalytics}/>} />
      <Route path="/projects/:projectIdParameter/:activeTabParameter?" element={<RouteWithLayout layout={DashboardLayout} component={ProjectEdit}/>} />

      <Route path="/surveys" element={<RouteWithLayout layout={DashboardLayout} component={SurveysList}/>} />
      <Route path="/surveys/:formIdParameter" element={<RouteWithLayout layout={DashboardLayout} component={Surveys}/>} />
      <Route path="/surveys/:formIdParameter/reporting" element={<RouteWithLayout layout={DashboardLayout} component={Reporting}/>} />
      <Route path="/surveys/:formIdParameter/datamap" element={<RouteWithLayout layout={DashboardLayout} component={Datamap}/>} />
      
      <Route path="/resellers/:resellerIdParameter?" element={<RouteWithLayout layout={DashboardLayout} component={ResellersList}/>} />
      <Route path="/customers/:customerIdParameter?" element={<RouteWithLayout layout={DashboardLayout} component={CustomersList}/>} />
      <Route path="/users" element={<RouteWithLayout layout={DashboardLayout} component={UsersList}/>} />
      <Route path="/users/:userIdParameter" element={<RouteWithLayout layout={DashboardLayout} component={UserEdit}/>} />
      <Route path="/status" element={<RouteWithLayout layout={DashboardLayout} component={Status}/>} />
      <Route path="/subscriptions" element={<RouteWithLayout layout={DashboardLayout} component={SubscriptionsList}/>}/>
      <Route path="/subscriptions/reporting" element={<RouteWithLayout layout={DashboardLayout} component={SubscriptionsReporting}/>} />
      <Route path="/translations" element={<RouteWithLayout layout={DashboardLayout} component={TranslationList}/>} />
      <Route path="/grouping/:projectIdParameter" element={<RouteWithLayout maxWidth={'xl'} layout={DashboardLayout} component={Grouping}/>} />
      <Route path="/admintools" element={<RouteWithLayout layout={DashboardLayout} component={AdminTools}/>} />
      <Route path="/codification" element={<RouteWithLayout layout={DashboardLayout} component={Codification}/>} />
      <Route path="/codification/dictionay" element={<RouteWithLayout layout={DashboardLayout} component={DictionaryList}/>} />
      <Route path="/aitools" element={<RouteWithLayout layout={DashboardLayout} component={AiTools}/>} />

      <Route path="/doc" element={<RouteWithLayout layout={DashboardLayout} component={Documentation}/>} />

      {/* visitors routes below */}
      <Route path="/projects/:projectIdParameter/dashboard/visitor" element={<RouteWithLayout visitorAllowed={true} layout={EmptyLayout} component={ProjectDashboard}/>} />

      {/* unknown route goes to '/' */}
      <Route
        path="*"
        element={<Navigate to="/" replace/>} />
    </Routes>
  );
};
