import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    titleDialog: {
        minHeight: '50px',
        width: 'calc(100% - 45px)',
        '& .MuiSvgIcon-root': {
            verticalAlign: 'sub'
        }
    },
    closeButton: {
        position: 'absolute',
        top: 5,
        right: 5
    },
}));

const DialogTitleWithCloseIcon = props => {

    const { classes } = useStyles();
    const {
        startIcon,
        title,
        callbackOnclose,
    } = props;

    return (
        <DialogTitle id="form-dialog-title" className={classes.titleDialog}>
            {startIcon}
            {title}
            <IconButton className={classes.closeButton} onClick={callbackOnclose} size="large">
                <CloseIcon />
            </IconButton>
        </DialogTitle>
    );
}

export default DialogTitleWithCloseIcon;