import { RestClientService } from './RestClientService';
require('dotenv').config();

class SubscriptionService {

    constructor() {
        this.state = {
            restClient: RestClientService.getClient()
        };
    }

    getRestClient() {
        return this.state.restClient;
    }

    async getSubscriptionById(id) {
        return this.getRestClient().get(`/api/subscriptions/v1.0/${id}`);
    }

    async create(payload) {
        return this.getRestClient().post('/api/subscriptions/v1.0/', payload);
    }

    async getAllCustomerSubscriptions(customerId) {
        return this.getRestClient().get(`/api/subscriptions/v1.0/customer/${customerId}`);
    }

    async getCurrentCustomerSubscription(customerId) {
        return this.getRestClient().get(`/api/subscriptions/v1.0/customer/${customerId}/current`);
    }

    async updateSubscription(subscriptionId, payload) {
        return this.getRestClient().put(`/api/subscriptions/v1.0/${subscriptionId}`, payload);
    }

    async deleteSubscription(subscriptionId) {
        return this.getRestClient().delete(`/api/subscriptions/v1.0/${subscriptionId}`);
    }

    async getSubscriptionTypes() {
        return this.getRestClient().get('/api/subscriptions/v1.0/types');
    }

    async getAllSubscriptions(from = 0, size = 25, sort = "id", order = "asc", onlyActive = false, onlyExpired = false) {
        let apiUrl = `/api/subscriptions/v1.0/?includes=customers&from=${from}&size=${size}&sort=${sort}&order=${order}`;
        if (onlyActive) apiUrl += `&onlyActive=${onlyActive}`;
        if (onlyExpired) apiUrl += `&onlyExpired=${onlyExpired}`;
        return this.getRestClient().get(apiUrl);
    }

    async applySubscriptionExpirationToAllCustomerUsers(subscriptionId) {
        return this.getRestClient().put(`/api/subscriptions/v1.0/${subscriptionId}/apply-expiration-date-to-users`);
    }

    async getSubscriptionsReporting(startDate, endDate) {
        return this.getRestClient().get(`/api/subscriptions/v1.0/reporting?startDate=${startDate}&endDate=${endDate}`);
    }
}

export default SubscriptionService;