import { useState, useContext, useReducer, useRef} from 'react';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { SurveyContext } from './context';
import CircularProgress from '@mui/material/CircularProgress';
import { isBlankHtml } from '../../utils/surveysUtils.js';
import QuillEditor from './QuillEditor';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ParamsImage from './ParamsImage';
import Button from '@mui/material/Button';
import BlockHeader from './BlockHeader.js';

const useStyles = makeStyles()(theme => ({
  previewRoot: {
    marginBottom: '25px',
    margin: '0',
    padding: '5px',
    width: `250px`,
    height: `150px`,
    display: 'inline-block',
    borderStyle: 'dotted',
    borderRadius: '7px',
  },
  previewImg: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '140px',
    position: 'relative',
    top: '-50px'
  },
  previewBtns: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    height: '50px',
    position: 'relative', top: '25%', zIndex: '100'
  },
  previewFullscreen: {
    margin: '0px 0 15px 0',
    textWrap: 'nowrap'
  },
  alignCenter: {
    textAlign: 'center',
    verticalAlign: 'middle',
    maxWidth: '100%',
    maxHeight: '200px'
  },
  paddingPaper: {
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 1,
    paddingBottom: 30
  },
  gridImage: {
    marginTop: 20,
  },
  powerSettingsNewIcon: {
    float: 'right',
  },
}));

export default function ThankYou(props) {

  const { classes } = useStyles();

  const { t, uuid } = props

  const { formIdParameter, generateSetImageButton, generateDeleteImageButton, isBlockCurrentlyDisplayed,
    setNeedToSave, getComponentConfiguration, mediaFiles } = useContext(SurveyContext);

  const [uploading, setUploading] = useState(false);
  const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
  const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
  const [openDialogImage, setOpenDialogImage] = useState(false);

  const quillRef = useRef();

  const changeField = (fieldName, value) => {
    switch (fieldName) {
      case "ref":
        getComponentConfiguration(uuid).ref = value;
        break;
      case "content":
        getComponentConfiguration(uuid).content = value;
        break;
      case "openImageFullscreen":
        getComponentConfiguration(uuid).openImageFullscreen = !getComponentConfiguration(uuid).openImageFullscreen;
        break;
      case "enabledBlock":
        getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
        break;
    }
    setNeedToSave(true);
    forceUpdate();
  }

  if(!isBlockCurrentlyDisplayed(uuid)) return null;

  return (
    <>
    {/* Define some popup and dialog */}
    <ParamsImage
      {...props}
      uuid={uuid}
      openDialogImage={openDialogImage}
      setOpenDialogImage={setOpenDialogImage}
      enableFullScreenMode={true}
    />
    {/* Header of the block */}
    <BlockHeader
      {...props}
      blockRef={getComponentConfiguration(uuid).ref}
      setBlockRef={(newValue) => changeField('ref', newValue)}
      isActive={getComponentConfiguration(uuid).active}
      setIsActive={(newValue) => changeField('enabledBlock', newValue)}
      title={t("react.project.collectforms.block.thankyou.title")}
      hideRefInTitle={true}
    />
    <Paper className={classes.paddingPaper}>
      <Grid item xs={3} xm={4} className={classes.gridImage}>
        <div id={`preview_img_${uuid}`} className={classes.previewRoot}
          onMouseOver={e => setUploadButtonVisibility(true)}
          onMouseOut={e => setUploadButtonVisibility(false)}
        >
          {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
            <div style={{ display: (formIdParameter > 0 && (uploading || getComponentConfiguration(uuid).imageId === 0 || uploadButtonVisibility)) ? 'block' : 'none' }}>
              {!uploading && generateSetImageButton(uuid, setUploading)}
              {!uploading && generateDeleteImageButton(uuid)}
              {uploading && <CircularProgress />}
            </div>
          </div>}
          {getComponentConfiguration(uuid).imageId > 0 && <div className={classes.alignCenter}>
            <img
              id={`img_${uuid}`}
              className={classes.previewImg}
              src={mediaFiles[getComponentConfiguration(uuid).imageId]}
              style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
          </div>}
        </div>
      </Grid>
      {getComponentConfiguration(uuid).imageId > 0 &&
        <Button style={{marginTop: 20, marginBottom: 20}} onClick={() => setOpenDialogImage(true)} variant="contained" endIcon={<ImageSearchIcon />}>
            {t("react.surveys.button.params.image")}
        </Button>
      }
      <QuillEditor
        ref={quillRef}
        value={getComponentConfiguration(uuid).content}
        onChange={(value) => changeField('content', value)}
        isError={formIdParameter > 0 && isBlankHtml(getComponentConfiguration(uuid).content) && getComponentConfiguration(uuid).imageId === 0}
        style={{height: 400, marginTop: getComponentConfiguration(uuid).imageId > 0 ? 0 : 20}}
        placeholder= {t("react.project.collectforms.content")}
        {...props}
      />
    </Paper>
    </>
  );
}
