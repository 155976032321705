import { RestClientService } from './RestClientService';
require('dotenv').config();

class CustomerService {

  constructor() {
    this.state = {
      restClient: RestClientService.getClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  async get500CustomersOrderByNameAsc() {
      return this.getRestClient().get('/api/customers/v1.0/?from=0&size=500&sort=name');
  }

  async fetchCustomer(customerId) {
      return this.getRestClient().get('/api/customers/v1.0/' + customerId);
  }

  async fetchAllResellers() {
      return this.getRestClient().get('/api/resellers/v1.0/?from=0&size=500&sort=name');
  }

  async saveCustomer(customer) {
    if(customer.id === 0) {
        return this.getRestClient().post('/api/customers/v1.0/', customer);
    } else {
        return this.getRestClient().put('/api/customers/v1.0/' + customer.id, customer);
    }
  }
}

export default CustomerService;
