import React, { useState, useEffect, useContext } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import CircleIcon from '@mui/icons-material/Circle';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { makeStyles } from 'tss-react/mui';
import { CollectorContext } from './context';
import useDeviceDetection, { MOBILE } from "../shared/DetectDevice/useDeviceDetection";
import CollectorsTestRef from './CollectorsTestRef';

const useStyles = makeStyles()(theme => ({
    checkCircleIcon: {
      color: '#003945',
    },
    circleIcon: {
      color: 'grey',
    },
    styleBox: {
        width: '100%',
        marginTop: 5
    },
    styleStep: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
}));

export default function CustomSlider(props) {

  const { classes } = useStyles();

  const {
    embeddedReplaceDynamicElements } = useContext(CollectorContext);

  const { identifier /* used to identify when to reload because 'answers' may reload unexpectedly (= question or item id) */, 
    answers, selectAnswer, selectedIndex } = props;

  const [mouseOverIndex, setMouseOverIndex] = useState(-1);

  const device = useDeviceDetection();

  useEffect(() => {
    setMouseOverIndex(-1);
  }, [identifier]);

  const handleMouseClick = (index) => {
    selectAnswer(answers[index]);
  };

  const CustomStepIcon = ({ index }) => {
    // checked if before or equals to the selected index
    let isChecked = index === selectedIndex;
    let isIlluminated = index <= selectedIndex;

    // follow the mouse if hover
    if(mouseOverIndex >= 0) {
      isChecked = index === mouseOverIndex;
      isIlluminated = index <= mouseOverIndex;
    }

    if(isChecked) {
      return <CheckCircleIcon className={classes.checkCircleIcon}/>;
    } else if (isIlluminated) {
      return <CircleIcon className={classes.checkCircleIcon}/>
    } else {
      return <CircleIcon className={classes.circleIcon}/>
    }
  };

  const isActive = (index) => {
    let isActive = index <= selectedIndex;

    if(mouseOverIndex >= 0) {
      isActive = index <= mouseOverIndex;
    }

    return isActive;
  }

  if(!device) return null;

  return (
      <Box className={classes.styleBox}>
              <Stepper activeStep={mouseOverIndex} className={classes.styleStep}
                       orientation={device === MOBILE ? "vertical" : "horizontal"} alternativeLabel={device !== MOBILE}>
                  {answers.map((answer, index) => (
                      <Step
                          key={answer.id}
                          onMouseOver={() => setMouseOverIndex(index)}
                          onMouseOut={() => setMouseOverIndex(-1)}
                          active={isActive(index)}
                      >
                          <StepLabel 
                            onClick={() => handleMouseClick(index)} 
                            StepIconComponent={() => <CustomStepIcon index={index}/>}>
                                <CollectorsTestRef {...props} refToDisplay={answer.ref}/>
                                {embeddedReplaceDynamicElements(answer.text)}
                          </StepLabel>
                      </Step>
                  ))}
              </Stepper>
      </Box>
  );
}
