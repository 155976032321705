import { RestClientService } from './RestClientService';
import { findById, indexOf, cloneDeep } from '../utils/utils.js';
import { getDefaultConfiguration, LOW_SCORE_LIMIT, HIGHT_SCORE_LIMIT } from '../Constants.js';
require('dotenv').config();

const defaultConfig = getDefaultConfiguration();

class DashboardService {

  constructor() {
    this.state = {
      restClient: RestClientService.getClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  /**
   * This method will prepare data used by comparison between stimuli.
   * 3 MAJOR steps are done for compare mode:
   * 
   *  - If a group is missing in the current stimulus and present in the opposite => clone the opposite group and add with the property '.missing = true'
   *  - If both groups are in the RED ZONE (or if opposite is missing) and the score diff greater than configuration.missingGroupsDiamondsThreshold
   *      => mark the current group with the property '.diamond = true'
   *  - If the current group is in the RED ZONE and the score diff greater than configuration.comparisonTriggeringValue
   *      => mark the current group with the property '.relevant = true'
   * 
   * @param {*} stimuli 
   * @param {*} tags 
   * @param {*} configuration 
   * @returns 
   */
  computeCompareWithParameters(stimuli, tags, configuration) {
    // if no comparison active - no job to do
    if (configuration.compareWith.length === 0) {
      return;
    }

    // build set of colors
    let tagColors = [...new Set(tags.map(item => item.color))];

    // we loop over all stimuli
    for (let idx in stimuli) {
      // get current stimulus and it's opposite
      let currentStimulus = stimuli[idx];
      let oppositeStimulus = this.getCompareWith(currentStimulus, stimuli, configuration);

      if (currentStimulus.id < 0 /* not a real stimulus */ || oppositeStimulus === undefined || configuration.hidden.indexOf(currentStimulus.id) >= 0) {
        continue;
      }

      let currentStimulusGroups = currentStimulus.groups;
      let oppositeStimulusGroups = oppositeStimulus.groups;

      // loop over all tags to get all groups
      for(let tagColorIdx in tagColors) {
        let tagColor = tagColors[tagColorIdx];

        // 1°/ LOOP OVER GROUPS OF THE CURRENT STIMULUS
        for (let grpIdx in currentStimulusGroups[tagColor]) {
          // get the current group
          let currentGroup = currentStimulusGroups[tagColor][grpIdx];

          // if the current group is in the RED ZONE [-20 => 60]
          if(this.isInRedZone(currentGroup.score, configuration)) {
            // get the group from the opposite stimulus
            let oppositeGroup = findById(oppositeStimulusGroups[tagColor], currentGroup.id);

            if (configuration.missingGroupsShowDiamonds && currentGroup.score > 0) {
              // get the opposite group score
              let oppositeGroupScore = oppositeGroup !== undefined && oppositeGroup.missing !== true
                  ? Math.max(oppositeGroup.score, 0) /* if negative, use 0 */
                  : 0; /* if not exists, by setting 0 it will be in the red zone */

              // check if the opposite group is also in the RED ZONE [-20 => 60] OR missing
              if (this.isInRedZone(oppositeGroupScore, configuration)) {
                  // so currentGroup must have a score greater than oppositeGroup
                  if ((currentGroup.score - oppositeGroupScore) > configuration.missingGroupsDiamondsThreshold) {
                    // tag currentGroup as a diamond group
                    currentGroup.diamond = true;
                  }
              }
            }

            // if it's not a diamond group, check if this group is 'relevant'
            if(configuration.showMissingGroups && currentGroup.diamond !== true) {
              // get the opposite group score
              let oppositeGroupScore = oppositeGroup !== undefined && oppositeGroup.missing !== true
                  ? oppositeGroup.score
                  : 0;

              if(Math.abs(currentGroup.score - oppositeGroupScore) > configuration.comparisonTriggeringValue) {
                // NOTE: When comparing with a Total, the score you see cannot be the same than
                // the one of the oppositeGroup due to compareWithExcept (id -200)
                currentGroup.relevant = true;
              }
            }
          }
        }

        // if we don't care of missing groups - skip
        if (!configuration.showMissingGroups) {
          continue;
        }

        // 2°/ LOOP OVER GROUPS OF THE OPPOSITE STIMULUS - TO FIND POSSIBLE MISSING GROUPS IN 'CURRENT'
        for (let grpIdx in oppositeStimulusGroups[tagColor]) {
          // get the group from the opposite stimulus & search this group in the current stimulus
          let oppositeGroup = cloneDeep(oppositeStimulusGroups[tagColor][grpIdx]);
          let currentGroup = findById(currentStimulusGroups[tagColor], oppositeGroup.id);

          // is the group missing in the current stimulus ?
          if(currentGroup !== undefined) {
            continue;
          }

          // let's go only if the opposite group doesn't exist in the current

          // it's another copied group - skip
          if (oppositeGroup.missing === true) {
            continue;
          }

          // skip if oppositeGroup is in the RED ZONE [-20 => 60]
          if (this.isInRedZone(oppositeGroup.score, configuration)) {
            continue;
          }

          // skip if exists oppositeGroup.score is not relevant regarding the comparisonTriggeringValue value
          if (Math.abs(oppositeGroup.score /* - 0 because no score */) < configuration.comparisonTriggeringValue) {
            continue;
          }

          // we add a clone as missing and set a default score
          oppositeGroup.missing = true;
          oppositeGroup.score = oppositeGroup.score >= 0 ? HIGHT_SCORE_LIMIT : LOW_SCORE_LIMIT;

          // find index where to insert this new missing group
          let index = currentStimulusGroups[tagColor].length;
          for (let j in currentStimulusGroups[tagColor]) {
            // we position the missing ones directly in the right place in the list
            let score = (oppositeGroup.score === HIGHT_SCORE_LIMIT) ? 0 : oppositeGroup.score;
            if (currentStimulusGroups[tagColor][j].score < score) {
              index = j;
              break;
            }
          }
          currentStimulusGroups[tagColor].splice(index, 0, oppositeGroup);
        }
      }
    }
  }

  isInRedZone(score, configuration) {
    return score >= configuration.low && score <= configuration.high;
  }

  /***
   * How groups are sorted verticaly in a card:
   * - A: are 'normal' groups with a positive score
   * - B: are Diamants
   * - C: are 'relevant' groups with positive score
   * - D: are 'missing groups (score are HIGHT_SCORE_LIMIT or LOW_SCORE_LIMIT)
   * - E: are 'relevant' groups with negative score
   * - F: are 'normal' groups with a negative score
   * - Z: avoid error with the localeCompare() above if _displayGroup_ is undefined
   ***/
  getGroupsOrderedForDisplay(groups, configuration) {
    let tempGroups = [];

    groups.forEach(group => {
      group._displayGroup_ = 'Z';

      if(group.score > configuration.high) {
        group._displayGroup_ = 'A';
      } else if(group.score < configuration.low) {
        group._displayGroup_ = 'F';
      }

      if(group.diamond === true) {
        group._displayGroup_ = 'B';
      } else if(group.relevant === true && group.score > 0) {
        group._displayGroup_ = 'C';
      } else if(group.missing === true) {
        group._displayGroup_ = 'D';
      } if(group.relevant === true && group.score < 0) {
        group._displayGroup_ = 'E';
      }

      tempGroups.push(group);
    });

    tempGroups = tempGroups.sort((grp1, grp2) => {
      let diff = grp1._displayGroup_.localeCompare(grp2._displayGroup_);
      if(diff === 0) diff = grp2.score - grp1.score;
      return diff;
      return 0;
    });

    return tempGroups;
  }

  buildComparationMap(compareWith) {
    const comparationMap = {};
    if (!compareWith) return comparationMap;
    // loop over all tag colors
    for (let i in compareWith.groups || {}) {
      // loop over all groups in the tag
      for (let j in compareWith.groups[i]) {
        // skip groups marked as 'missing'
        if (compareWith.groups[i][j].missing) {
          continue;
        }
        comparationMap[compareWith.groups[i][j].id] = compareWith.groups[i][j].score;
      }
    }
    return comparationMap;
  }

  async fetchStimuli(projectId, configuration) {
    let url = `/api/dashboard/v1.0/${projectId}`;
    let postedConfiguration = { ...configuration, 'include': ['filters', 'tags', 'sequences'] };
    const result = await this.getRestClient().post(url, postedConfiguration);

    let stimuli = this.convertStimuliResponse(result.data);
    let filters = result.data.filters;
    let tags = result.data.tags;
    let sequences = result.data.sequences;

    // adjust Missing, Diamond & Relevant groups for "compare with" mode
    this.computeCompareWithParameters(stimuli, tags, configuration);

    // compute size of groups and max values
    var specifications = [];
    specifications['groups'] = this.computeGroupsSpecifications(stimuli, tags, configuration);
    specifications['tags'] = this.computeTagsSpecifications(stimuli, tags, configuration);

    return [stimuli, filters, tags, sequences, specifications];
  }

  async removeConfiguration(projectId, configId) {
    return this.getRestClient().delete(`/api/dashboard/v1.0/${projectId}/configurations/${configId}`);
  }

  async saveConfiguration(projectId, configId, stimuli, payload) {
    payload.updated = false;

    for (let id in payload.compareWith) {
      if (payload.compareWith[id]) {
        payload.compareWith[id] = payload.compareWith[id].id;
      }
    }

    let result;
    if (configId) {
      result = await this.getRestClient().put(`/api/dashboard/v1.0/${projectId}/configurations/${configId}`, { json: JSON.stringify(payload) });
    } else {
      result = await this.getRestClient().post(`/api/dashboard/v1.0/${projectId}/configurations`, { json: JSON.stringify(payload) });
    }

    const config = this.convertConfigurationResponse(JSON.parse(result.data.json), stimuli);
    config.id = result.data.id;
    return this.getConsolidatedConfiguration(config, defaultConfig);
  }

  async fetchConfigurations(projectId, stimuli) {
    const result = await this.getRestClient().get(`/api/dashboard/v1.0/${projectId}/configurations`);
    return result.data.map(c => {
      const config = this.convertConfigurationResponse(JSON.parse(c.json), stimuli);
      config.id = c.id;
      return this.getConsolidatedConfiguration(config, defaultConfig);
    });
  }

  async fetchConfiguration(projectId, configurationId, stimuli) {
    const result = await this.getRestClient().get(`/api/dashboard/v1.0/${projectId}/configurations/${configurationId}`);
    const config = this.convertConfigurationResponse(JSON.parse(result.data.json), stimuli);
    config.id = configurationId;
    return this.getConsolidatedConfiguration(config, defaultConfig);
  }

  async downloadDashboardDataFile(projectId, configuration) {
    let url = `/api/dashboard/v1.0/${projectId}/export/xlsx`;
    let postedConfiguration = { ...configuration };
    return this.getRestClient().post(url, postedConfiguration, { responseType: 'blob' });
  }

  getStimulusName(stimulus, configuration, stimuli, sequences, t) {
    // get configurated name if any
    let specialName = configuration.names[stimulus.id];
    if (specialName !== undefined && specialName.length > 0) return specialName;

    if (stimulus.id === -100) {
      return t('react.projectdashboard.totaloverallexcept.title');
    } else if (stimulus.id === -200) {
      return t('react.projectdashboard.totaloverall.title');
    }

    // compute the card name
    let name = stimulus.name;

    if (configuration.showSequenceName === true) {
      // compareWith Stimulus don't have all attributes
      let realStimulus = findById(stimuli, stimulus.id);
      if (realStimulus !== undefined) {
        let sequence = findById(sequences, realStimulus.sequenceId);
        if (sequence !== undefined) name = `${name} - ${sequence.name}`;
      }
    }

    return name;
  }

  getCompareWith(stimulus, stimuli, configuration) {
    // with which stimulus this card is compared to
    let compareWithStimulus = undefined;
    let compareWith = configuration.compareWith[stimulus.id];
    if (compareWith) {
      if (compareWith.id === -200) {
        let index = indexOf(stimuli, { id: compareWith.id, totalOverallExceptOf: stimulus.id });
        compareWithStimulus = stimuli[index];
      } else {
        compareWithStimulus = findById(stimuli, compareWith.id);
      }
    }
    return compareWithStimulus;
  }

  /* This method returns a configuration 'up to date' with potential missing fields and default value */
  getConsolidatedConfiguration(configuration, defaultConfiguration) {
    for (let field in defaultConfiguration) {
      if (field === 'id') continue; // ignore this field
      if (configuration[field] === undefined || configuration[field] === null) {
        configuration[field] = defaultConfiguration[field];
      }
    }
    return configuration;
  }

  convertConfigurationResponse(data, stimuli) {
    // check all comparisons
    for (let f in data.compareWith) {
      let id = data.compareWith[f];
      // attach the stimulus to the compared id
      var compareWith = findById(stimuli, id);
      // in some cases (total or virtual cards) we need to prepare an empty compareWith,
      // full data will be fetched from the server
      if (compareWith === undefined) compareWith = { 'id': id };
      data.compareWith[f] = compareWith;
    }
    return data;
  }

  convertStimuliResponse(data) {
    const significativities = data.significativities || [];

    const significativityMap = {};
    significativities.forEach(s => {
      significativityMap[s.of] = {};
      significativityMap[s.of][s.with] = {
        'value': s.value,
        'loversValue': s.loversValue,
        'rejectorsValue': s.rejectorsValue,
        'topicValues': s.topicValues
      };
    });

    const stimuli = [];
    data.stimuli.forEach(s => {
      if (s.stimulus.id === -100) stimuli.splice(0, 0, this.convertToStimulus(s, significativityMap[s.stimulus.id]));
      else stimuli.push(this.convertToStimulus(s, significativityMap[s.stimulus.id]));
    });

    return stimuli;
  }

  convertToStimulus(data, significativityMap) {
    if (data === null) return null;
    return {
      id: data.stimulus.id,
      name: data.stimulus.name,
      sequenceId: data.stimulus.sequenceId,
      imageId: data.stimulus.imageId,
      weightedScore: data.stimulus.weightedScore,
      groups: data.groups,
      nbMatchedResponses: data.stimulus.nbMatchedResponses,
      addictsPercentil: data.stimulus.addictsPercentil,
      hatersPercentil: data.stimulus.hatersPercentil,
      significativity: significativityMap,
      totalOverall: data.stimulus.totalOverall,
      totalOverallExcept: data.stimulus.totalOverallExcept,
      totalOverallExceptOf: data.stimulus.totalOverallExceptOf,
      valid: data.stimulus.valid,
      virtual: data.stimulus.virtual,
      virtualOf: data.stimulus.virtualOf,
      topics: data.topics,
      assembled: data.stimulus.assembled,
    };
  }

  computeGroupsSpecifications(stimuli, tags, configuration) {
    var minScore = HIGHT_SCORE_LIMIT;
    var maxScore = LOW_SCORE_LIMIT;

    var groupIds = new Set();
    var groupsPerTag = new Map();

    // loop over all tags colors
    var tagColors = [...new Set(tags.map(tag => tag.color))];
    for (let i = 0; i < tagColors.length; i++) {
      var tagColor = tagColors[i];

      // get current detected groups
      var nbGroups = groupsPerTag.get(tagColor);
      if (nbGroups === undefined) {
        // initialize if needed
        nbGroups = 0;
      }

      // loop over all stimuli
      for (let j = 0; j < stimuli.length; j++) {
        var stimulus = stimuli[j];

        // if stimulus is hidden skip
        let isHidden = configuration.hidden.indexOf(stimulus.id) >= 0;
        if (isHidden) continue;

        // get groups of this tag in the stimulus
        var stimulusGroupsOfTagColor = stimulus.groups[tagColor];

        if (stimulusGroupsOfTagColor !== undefined) {
          var _nbGroups = 0;

          for (let k = 0; k < stimulusGroupsOfTagColor.length; k++) {
            var grp = stimulusGroupsOfTagColor[k];

            // add to set to count nb groups we have
            groupIds.add(grp.id);

            // count groups out of the RED ZONE (not inside limits) or Diamond groups
            if (!this.isInRedZone(grp.score, configuration) || grp.diamond || grp.relevant /* || grp.missing => missing have score eq to HIGHT_SCORE_LIMIT or LOW_SCORE_LIMIT */) {
              _nbGroups++;
              if (grp.score < minScore && grp.score !== LOW_SCORE_LIMIT) minScore = grp.score;
              if (grp.score > maxScore && grp.score !== HIGHT_SCORE_LIMIT) maxScore = grp.score;
            }
          }
          if (_nbGroups > nbGroups) {
            nbGroups = _nbGroups;
          }
        }
      }

      // if there is some comparisons we need to "merge" groups of compared stimuli
      groupsPerTag.set(tagColor, nbGroups);
    }

    var specifications = [];
    specifications['minScore'] = minScore;
    specifications['maxScore'] = maxScore;
    specifications['absMaxScore'] = this.computeAbsMaxScoreLimit(minScore, maxScore, configuration);

    var maxGroupSizes = [];
    groupsPerTag.forEach(function (value, key) {
      maxGroupSizes[key] = value;
    });
    specifications['maxGroupSizes'] = maxGroupSizes;
    specifications['count'] = groupIds.size;

    return specifications;
  }

  computeTagsSpecifications(stimuli, tags, configuration) {
    if (configuration.showThemeMode === undefined || configuration.showThemeMode === false) {
      return [];
    }

    let reducer = (previousValue, currentValue) => previousValue + currentValue;

    let minScore = HIGHT_SCORE_LIMIT;
    let maxScore = LOW_SCORE_LIMIT;

    let tagSizesPerStimulus = new Map();

    // loop over all tags colors
    let tagColors = [...new Set(tags.map(tag => tag.color))];
    for (let i = 0; i < tagColors.length; i++) {
      let tagColor = tagColors[i];

      let stimulusTagSizes = new Map();

      // loop over all stimuli
      for (let j = 0; j < stimuli.length; j++) {
        let stimulus = stimuli[j];

        // get groups of this tag in the stimulus
        let stimulusGroupsOfTagColor = stimulus.groups[tagColor];

        if (stimulusGroupsOfTagColor !== undefined) {
          let elementsToSum = stimulusGroupsOfTagColor.filter(d => d.score !== LOW_SCORE_LIMIT && d.score !== HIGHT_SCORE_LIMIT);
          if(elementsToSum.length > 0) {
            let _totalScorePerTagOfStimulus = Math.round(elementsToSum.map(d => d.score).reduce(reducer, 0));

            if (_totalScorePerTagOfStimulus < minScore) minScore = _totalScorePerTagOfStimulus;
            if (_totalScorePerTagOfStimulus > maxScore) maxScore = _totalScorePerTagOfStimulus;

            stimulusTagSizes.set(stimulus.id, _totalScorePerTagOfStimulus);
          }
        }
      }

      tagSizesPerStimulus.set(tagColor, stimulusTagSizes);
    }

    let specifications = [];

    specifications['sizes'] = [];
    specifications['minScore'] = minScore;
    specifications['maxScore'] = maxScore;
    specifications['absMaxScore'] = this.computeAbsMaxScoreLimit(minScore, maxScore, configuration);

    tagSizesPerStimulus.forEach(function (stimulusTagSizes, tagColor) {
      specifications['sizes'][tagColor] = [];
      stimulusTagSizes.forEach(function (stsValue, stsKey) {
        specifications['sizes'][tagColor][stsKey] = stsValue;
      });
    });

    return specifications;
  }

  computeAbsMaxScoreLimit(minScore, maxScore, configuration) {
    let absMaxScore = Math.max(Math.abs(minScore), Math.abs(maxScore));

    if(configuration.absMaxScoreLimit !== undefined && configuration.absMaxScoreLimit > 0) {
      if(absMaxScore > configuration.absMaxScoreLimit) absMaxScore = configuration.absMaxScoreLimit;
    }

    return absMaxScore;
  }

  computeBarScoreRatio(score, absMaxScore) {
    if(score === undefined || absMaxScore === undefined || score === 0) return 0;

    // compute the abs ratio & control it's not greater than 1
    let ratio = Math.abs(score) / absMaxScore;
    if(ratio > 1) ratio = 1;

    // to re-set the good sign
    return ratio * (score < 0 ? -1  : 1);
  }

  async clearProjectCaches(projectId) {
    let url = `/api/dashboard/v1.0/cache/?projectId=${projectId}`;
    return this.getRestClient().delete(url);
  }

  async createAssembledStimulus(projectId, name, assembledIds) {
    let url = `/api/dashboard/v1.0/${projectId}/assembled-stimulus`;
    let payload = {
      "name": name,
      "assembledIds": assembledIds
    };
    return this.getRestClient().post(url, payload);
  }

  async deleteAssembledStimulus(projectId, assembledId) {
    return this.getRestClient().delete(`/api/dashboard/v1.0/${projectId}/assembled-stimulus/${assembledId}`);
  }

  async renameAssembledStimulus(projectId, assembledId, newName) {
    return this.getRestClient().put(`/api/dashboard/v1.0/${projectId}/assembled-stimulus/${assembledId}?newName=${newName}`);
  }
}

export default DashboardService;
