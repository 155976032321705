import React, { useState, useEffect, useContext } from 'react';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Image from './Image';
import { CollectorContext, CollectorTestContext } from './context';
import CollectorsTestRef from './CollectorsTestRef';
import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(theme => ({
    flexBaseline: {
        display: 'flex',
        alignItems: 'baseline'
    }
}));

export default function ThankYou(props) {

    const { classes } = useStyles();

    const { 
        t, showSpinner, openSnackbar, block 
    } = props;

    const { 
        collectParameter, collectorsService, imagesOfForm, embeddedReplaceDynamicElements
    } = useContext(CollectorContext);

    // get the TEST context
    const _testContext_ = useContext(CollectorTestContext);

    const [ thankYouImage, setThankYouImage ] = useState(undefined);

    const [dataReady, setDataReady] = useState(false);
    const [imageReady, setImageReady] = useState(false);

    useEffect(() => {
        setDataReady(false);

        if (!block) return;

        if(_testContext_) {
            // if we are in test mode, we have a testContext - replace the automatic answering system
            _testContext_.setAutomaticAnsweringCallback(() => () => { /* DO NOTHING */ });
        }

        setDataReady(true);
    }, [block]);

    /*
     * This useEffect() is only used to control the display of the image
     */
    useEffect(() => {
        setImageReady(false);
        if (!block || block.imageId === 0) {
            setThankYouImage(undefined);
            setImageReady(true);
            return;
        }

        let img = imagesOfForm.get(block.imageId);
        if(img !== undefined) {
            setThankYouImage(img);
            setImageReady(true);
            return;
        }

        showSpinner(true);

        collectorsService.getPublicFormFileUrl(collectParameter, block.imageId)
        .then(result => {
            imagesOfForm.set(block.imageId, result.url);
            setThankYouImage(result.url);
            setImageReady(true);
            showSpinner(false);
        }).catch(e => {
            setThankYouImage(undefined);
            setImageReady(false);
            showSpinner(false);
            openSnackbar('error', t('react.error.fetch.message'));
        });
    }, [block]);

    if(!dataReady || !imageReady) return null;

    return (
        <Container>
            <Grid container spacing={4}>
                {thankYouImage !== undefined &&
                    <Grid item xs={12}>
                        <Image
                            imageUrl={thankYouImage}
                            position={block.imagePosition}
                            width={block.imageWidth}
                            openFullscreen={block.openImageFullscreen}
                        />
                    </Grid>
                }
                <Grid item xs={12} sm={block.image !== undefined ? 8 : 12}>
                    {block.text && <Box className={classes.flexBaseline}>
                        <CollectorsTestRef {...props} displayType={"typography"} refToDisplay={block.ref}/>
                        <div className="ql-view" dangerouslySetInnerHTML={{ __html: embeddedReplaceDynamicElements(block.text) }} />
                    </Box>}
                </Grid>
            </Grid>
        </Container>
    )
};
