import { RestClientService } from './RestClientService';
import { isBlank } from '../utils/utils.js';
require('dotenv').config();

class CodificationService {

  constructor() {
    this.state = {
      restClient: RestClientService.getClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  async applyProjectCodificationActions(projectId, payloadWithActions) {
    return this.getRestClient().put(`/api/projects/v1.0/${projectId}/codification/apply-actions`, payloadWithActions);
  }

  async fetchProjectCodificationUnknownWords(projectId, unfixedOnly, pendingOnly, forcedOnly, autoPrecodedOnly, keywords, from, size) {
    var params = [];
    if(unfixedOnly) params.push('&unfixedOnly=true');
    if(pendingOnly) params.push('&pendingOnly=true');
    if(forcedOnly) params.push('&forcedOnly=true');
    if(autoPrecodedOnly) params.push('&autoPrecodedOnly=true');
    if(keywords) params.push(`&keywords=${encodeURIComponent(keywords)}`);

    return this.getRestClient().get(`/api/projects/v1.0/${projectId}/codification/unknown-words?include=stimulus,sequences&sort=word&from=${from}&size=${size}${params.join('')}`);
  }

  async downloadProjectCodificationFile(projectId) {
    return this.getRestClient().get(`/api/projects/v1.0/${projectId}/codification/export`,  {responseType: 'blob'});
  }

  // This is the old method to retrieve the codification file, when the codification was out-side of the platform
  /*
  async downloadProjectCodificationFile(projectFileId) {
    return this.getRestClient().get(`/api/files/v1.0/codification/${projectFileId}`, {responseType: 'blob'});
  }
  */

  async fetchLibraries(parentId = 0, includeSpecificToProjectId = 0) {
    let includes = '&include=paths';
    let fetchUrl = `/api/codification/v1.0/libraries?${includes}`;
    if (parentId > 0) fetchUrl += `&parentId=${parentId}`;
    if (includeSpecificToProjectId > 0) fetchUrl += `&includeSpecificToProjectId=${includeSpecificToProjectId}`;

    return this.getRestClient().get(fetchUrl);
  }

  async fetchLibrary(libraryId) {
    return this.getRestClient().get(`/api/codification/v1.0/libraries/${libraryId}`);
  }

  async addLibrary(libraryAsJson) {
    return this.getRestClient().post('/api/codification/v1.0/libraries/', libraryAsJson);
  }

  async updateLibrary(libraryId, libraryAsJson) {
    return this.getRestClient().put(`/api/codification/v1.0/libraries/${libraryId}`, libraryAsJson);
  }

  async deleteLibrary(libraryId) {
    return this.getRestClient().delete(`/api/codification/v1.0/libraries/${libraryId}`);
  }

  async importLibraryDataFile(libraryId, formData) {
    return this.getRestClient().post(`/api/codification/v1.0/libraries/${libraryId}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    });
  }

  async downloadLibraryDataFile(libraryId) {
    return this.getRestClient().get(`/api/codification/v1.0/libraries/${libraryId}/export`, {responseType: 'blob'});
  }

  async searchBiblioEntries(from = 0, size = 25, sort = 'id', order = 'asc',
      libraryIds = undefined, keywords = undefined, logics = undefined, natures = undefined,
      valences = undefined, treeMode = false, ids = undefined, wordIds = undefined, include = undefined) {
    let fetchUrl = `/api/codification/v1.0/libraries/search/entries?`;

    if (from !== undefined) fetchUrl += `from=${from}&`;
    if (size !== undefined) fetchUrl += `size=${size}&`;
    if (sort !== undefined) fetchUrl += `sort=${sort}&`;
    if (order !== undefined) fetchUrl += `order=${order}&`;
    if (libraryIds !== undefined) fetchUrl += `libraryIds=${libraryIds}&`;
    if (!isBlank(keywords)) fetchUrl += `keywords=${encodeURIComponent(keywords)}&`;
    if (!isBlank(logics)) fetchUrl += `logics=${logics}&`;
    if (!isBlank(natures)) fetchUrl += `natures=${natures}&`;
    if (!isBlank(valences)) fetchUrl += `valences=${valences}&`;
    if (treeMode === true) fetchUrl += `treeMode=true&`;
    if (ids !== undefined) fetchUrl += `ids=${ids}&`;
    if (wordIds !== undefined) fetchUrl += `wordIds=${wordIds}&`;
    if (include !== undefined) fetchUrl += `include=${include}&`;

    return this.getRestClient().get(fetchUrl);
  }

  async fetchBiblioEntry(libraryId, biblioEntryId) {
    return this.getRestClient().get(`/api/codification/v1.0/libraries/${libraryId}/entries/${biblioEntryId}`);
  }

  async addBiblioEntry(libraryId, biblioEntryAsJson) {
    return this.getRestClient().post(`/api/codification/v1.0/libraries/${libraryId}/entries`, biblioEntryAsJson);
  }

  async updateBiblioEntry(libraryId, biblioEntryId, biblioEntryAsJson) {
    return this.getRestClient().put(`/api/codification/v1.0/libraries/${libraryId}/entries/${biblioEntryId}`, biblioEntryAsJson);
  }

  async addVariantsToBiblioEntry(libraryId, biblioEntryId, newVariantsAsJson) {
    return this.getRestClient().put(`/api/codification/v1.0/libraries/${libraryId}/entries/${biblioEntryId}/add-variants`, newVariantsAsJson);
  }

  async moveBiblioEntries(payload) {
    return this.getRestClient().put('/api/codification/v1.0/libraries/move/entries', payload);
  }

  async mergeBiblioEntries(payload) {
    return this.getRestClient().put('/api/codification/v1.0/libraries/merge/entries', payload);
  }

  async copyBiblioEntries(payload) {
    return this.getRestClient().post('/api/codification/v1.0/libraries/copy/entries', payload);
  }

  async deleteBiblioEntry(libraryId, biblioEntryId) {
    let delUrl = `/api/codification/v1.0/libraries/${libraryId}/entries/${biblioEntryId}`;
    return this.getRestClient().delete(delUrl);
  }

  // codification word ----------------------------------------------------------------------------

  async searchWords(from, size, sort, order, keywords, language, withoutCodification) {
    let searchWordsUrl = `/api/codification/v1.0/words/search?from=${from}&size=${size}&sort=${sort}&order=${order}&keywords=${encodeURIComponent(keywords)}&language=${language}&withoutCodification=${withoutCodification}`;
    return this.getRestClient().get(searchWordsUrl);
  }

  async getWord(wordId) {
    return this.getRestClient().get(`/api/codification/v1.0/words/${wordId}`);
  }

  async saveWord(word) {
    if(word.id > 0) return this.getRestClient().put(`/api/codification/v1.0/words/${word.id}`, word);
    else return this.getRestClient().post(`/api/codification/v1.0/words/`, word);
  }

  async deleteWord(wordId) {
    return this.getRestClient().delete(`/api/codification/v1.0/words/${wordId}`);
  }

  async mergeBiblioWords(payload) {
    return this.getRestClient().put('/api/codification/v1.0/words/merge', payload);
  }

  /**
   * For codification ONLY
   * Returns the list of languages supported by codification tools
   **/
  async getSupportedLanguages() {
      return this.getRestClient().get('/api/codification/v1.0/languages');
  }

  async getVariantsExistingAsDuplicate(wordId) {
    return this.getRestClient().get(`/api/codification/v1.0/words/${wordId}/variants-existing-as-duplicate`);
  }

  // codification translations ----------------------------------------------------------------------------

  async saveWordTranslations(word, translateTo, translations) {
    let payload = {
      "translateTo": translateTo,
      "translations": translations,
    };
    return this.getRestClient().post(`/api/codification/v1.0/translations/${word.id}`, payload);
  }

  async translateEntry(word, fromLanguage, toLanguage) {
    let payload = {
      "word": word,
      "from": fromLanguage,
      "to": toLanguage
    };
    return this.getRestClient().post(`/api/codification/v1.0/translate/entry`, payload);
  }

  async translateEntries(words /* array of words */, fromLanguage, toLanguage) {
    let payload = {
      "words": words,
      "from": fromLanguage,
      "to": toLanguage
    };
    return this.getRestClient().post(`/api/codification/v1.0/translate/entries`, payload);
  }

  getParentLanguage(libraries, currentLibrary) {
    if (!currentLibrary) {
      return undefined;
    }
  
    if (currentLibrary.optionalLanguage !== undefined) {
      return currentLibrary.optionalLanguage;
    }
  
    if (currentLibrary.parentLibraryId !== 0) {
      const parentLibrary = libraries.find(l => l.id === currentLibrary.parentLibraryId);
      return this.getParentLanguage(libraries, parentLibrary);
    }
  
    return undefined;
  }
}

export default CodificationService;
