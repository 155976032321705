import { RestClientService } from './RestClientService';
require('dotenv').config();

class NotificationService {

    constructor() {
        this.state = {
            restClient: RestClientService.getClient()
        };
    }

    getRestClient() {
        return this.state.restClient;
    }

    async getMyNotifications(from, size) {
        return this.getRestClient().get('/api/notifications/v1.0/?from=' + from + '&size=' + size);
    }

    async markAsRead(notificationId) {
        return this.getRestClient().put('/api/notifications/v1.0/'+notificationId+'/read');
    }

    async markAllAsRead() {
        return this.getRestClient().put('/api/notifications/v1.0/all/read');
    }
}

export default NotificationService;
