import React from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { isBlank } from '../../utils/utils.js';

const useStyles = makeStyles()((theme, { displayType }) => ({
    customBox: {
        fontSize: displayType === "typography" ? '1.2em' : '',
        marginRight: 5,
        color: '#bbbbbb'
    },
}));

export default function CollectorsTestRef(props) {
    const { refToDisplay, displayType, testMode } = props;

    const { classes } = useStyles({ displayType });

    if (!testMode || isBlank(refToDisplay)) return null;

    return (
        <>
            {displayType === "typography" ? (
                    <Typography className={classes.customBox}>[{refToDisplay}]</Typography>
                ) : (
                    <span className={classes.customBox}>[{refToDisplay}]</span>
                )}
        </>
    );
};