import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Button from '@mui/material/Button';
import {TextField, Select, InputLabel} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CodificationService from '../../services/CodificationService'
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import GetAppIcon from '@mui/icons-material/GetApp';
import Tooltip from '@mui/material/Tooltip';
import { indexOf, isBlank, orderBy } from '../../utils/utils.js';
import { LoadData } from '../../Constants.js';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Typography from '@mui/material/Typography';
import {getShorthandNotationFromBiblioEntry, getShorthandNotation} from '../../utils/codificationUtils'

const codificationService = new CodificationService();

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  spaceUnderControl: {
    paddingBottom: '12px',
  },
}));

const EditCodifDialog = (props) => {
  // expected parameters
  const {
    t,
    openState,
    showSpinner,
    biblioEntryId,            // ONLY when editing an existing biblio
    wordPlaceHolder,          // NOT mandatory
    languagePlaceHolder,      // NOT mandatory
    appendToLibraryId,        // NOT mandatory if codificationPaths is given for selection
    codificationPaths,        // NOT mandatory, only if the user is allowed to select another library
    naturePlaceHolder,        // NOT mandatory
    logicPlaceHolder,         // NOT mandatory
    valencePlaceHolder,       // NOT mandatory
    callbackAfterConfirm,
    callbackAfterCancel,
    openSnackbar,
    closeSnackbar,
    preferredLanguage
  } = props;

  const { classes } = useStyles();

  const [id, setId] = useState(0);
  const [libraryId, setLibraryId] = useState(0);
  const [word, setWord] = useState('');
  const [language, setLanguage] = useState('');
  const [shorthandNotation, setShorthandNotation] = useState('');
  const [nature, setNature] = useState('');
  const [logic, setLogic] = useState('');
  const [valence, setValence] = useState('');
  const [creationDate, setCreationDate] = useState(new Date());
  const [variants, setVariants] = useState([]);
  const [confirmInProgress, setConfirmInProgress] = useState(false);
  const [variantFieldValue, setVariantFieldValue] = useState('');
  const [associatedBiblioEntries, setAssociatedBiblioEntries] = useState([]);
  const [biblioEntriesToDelete, setBiblioEntriesToDelete] = useState([]);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (!openState) {
      setLoadData(LoadData.Load);
      return;
    }

    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    showSpinner(true);

    if (biblioEntryId && biblioEntryId > 0) {
      codificationService.fetchBiblioEntry(appendToLibraryId, biblioEntryId)
        .then(result => {
          setId(result.data.id);
          setLibraryId(result.data.libraryId);
          setWord(result.data.biblioWord.word);
          setLanguage(result.data.biblioWord.language);
          setNature(result.data.nature);
          setLogic(result.data.logic);
          setValence(result.data.valence);
          setVariants(result.data.biblioWord.variants || []);
          setCreationDate(result.data.creationDate);
          buildAndSetShorthandNotation(result.data.nature, result.data.logic, result.data.valence);
          setVariantFieldValue('');

          codificationService.searchBiblioEntries(0, 100, 'id', 'asc', undefined, undefined, undefined, undefined, undefined, undefined, undefined, result.data.biblioWord.id, 'paths')
            .then(result2 => {
              let abe = [];
              result2.data.hits.forEach((item, i) => {
                // add the path to the item
                item.path = result2.data.paths[item.libraryId];
                abe.push(item);
              });
              setAssociatedBiblioEntries(orderBy(abe, 'path'));
            });

          setBiblioEntriesToDelete([]);
        }).catch(err => {
          openSnackbar('error', t("react.codification.entries.error.while.loading"));
        }).finally(() => {
          setLoadData(LoadData.Loaded);
          showSpinner(false);
        });
    } else {
      setId(0);
      setLibraryId(appendToLibraryId);
      setWord(wordPlaceHolder ? wordPlaceHolder : '');
      setLanguage(languagePlaceHolder ? languagePlaceHolder : preferredLanguage.toLowerCase());
      setNature(naturePlaceHolder ? naturePlaceHolder : '');
      setLogic(logicPlaceHolder ? logicPlaceHolder : '');
      setValence(valencePlaceHolder ? valencePlaceHolder : 0);
      setVariants([]);
      setCreationDate(new Date());
      buildAndSetShorthandNotation(
        naturePlaceHolder ? naturePlaceHolder : '',
        logicPlaceHolder ? logicPlaceHolder : '',
        valencePlaceHolder ? valencePlaceHolder : 0);
      setVariantFieldValue('');
      setAssociatedBiblioEntries([]);
      setBiblioEntriesToDelete([]);
      setLoadData(LoadData.Loaded);
      showSpinner(false);
    }
  }, [loadData, biblioEntryId, appendToLibraryId, openState, wordPlaceHolder]);

  const handleTypeShorthandNotation = (value) => {
    setShorthandNotation(value);

    var _nature = '';
    var _logic = '';
    var _valence = '';

    if (!isBlank(value)) {
      if (value.length > 0) {
        switch (value[0].toUpperCase()) {
          case 'A':
            _nature = 'Adjectivale'
            break;
          case 'N':
            _nature = 'Nominale'
            break;
          case 'P':
            _nature = 'Puissance'
            break;
          case 'S':
            _nature = 'SigneIconique'
            break;
          case 'V':
            _nature = 'Verbale'
            break;
          case 'U':
            _nature = 'Undefined'
            break;
        }
      }

      if (value.length > 1) {
        switch (value[1].toUpperCase()) {
          case 'C':
            _logic = 'Caracterisation'
            break;
          case 'D':
            _logic = 'Designation'
            break;
          case 'E':
            _logic = 'Effet'
            break;
          case 'J':
            _logic = 'Jugement'
            break;
          case 'M':
            _logic = 'Metaphore'
            break;
          case 'U':
            _logic = 'Undefined'
            break;
        }
      }

      if (value.length > 2) {
        var num = NaN;
        var sign = undefined;

        if (value[2] === '+') {
          // NOTE: this notation is never used (e.g AJ+1)
          sign = 1;
          if(value.length === 4) num = parseInt(value[3]);
        } else if (value[2] === '-') {
          // NOTE: can be something like: NC-3
          sign = -1;
          if(value.length === 4) num = parseInt(value[3]);
        } else {
          sign = 1;
          num = parseInt(value[2]);
        }

        // check if value is valid and authorized
        if(!isNaN(num) && sign !== undefined && [-3, 0, 1, 2].includes(num * sign)) {
          _valence = num * sign;
        }
      }
    }

    setNature(_nature);
    setLogic(_logic);
    setValence(_valence);
  };

  const handleChangeFieldValue = (field, value) => {
    let n = nature;
    let l = logic;
    let v = valence;

    if (field === 'nature') {
      n = value;
      setNature(value);
    } else if (field === 'logic') {
      l = value;
      setLogic(value);
    } else if (field === 'valence') {
      v = value;
      setValence(value);
    }

    buildAndSetShorthandNotation(n, l, v);
  };

  const buildAndSetShorthandNotation = (n, l, v) => {
    setShorthandNotation(getShorthandNotation(n, l, v))
  };

  const handleClickCancel = (event) => {
    event.preventDefault();
    closeSnackbar();
    callbackAfterCancel();
  };

  const handleClickConfirm = (event) => {
    event.preventDefault();

    if (word.length === 0) {
      openSnackbar('error', t("react.codification.entries.error.empty.name"));
      return;
    } else if (language.length !== 2) {
      openSnackbar('error', t("react.codification.entries.error.empty.language"));
      return;
    } else if (nature.length === 0) {
      openSnackbar('error', t("react.codification.entries.error.empty.nature"));
      return;
    } else if (logic.length === 0) {
      openSnackbar('error', t("react.codification.entries.error.empty.logic"));
      return;
    } else if (valence.length === 0) {
      openSnackbar('error', t("react.codification.entries.error.empty.valence"));
      return;
    } else if (!(libraryId > 0)) {
      openSnackbar('error', t("react.codification.entries.error.empty.destination"));
      return;
    }

    setConfirmInProgress(true);

    let biblioEntryAsJson = {
      'id': id,
      'libraryId': libraryId,
      'nature': nature,
      'logic': logic,
      'valence': valence,
      'creationDate': creationDate,
      'biblioWord': {
        'word': word,
        'language': language,
        'variants': variants,
      }
    };

    if (id === 0) {
      codificationService.addBiblioEntry(libraryId, biblioEntryAsJson)
        .then(result => {
          callbackAfterConfirm(result.data);
        })
        .catch(err => {
          if (err.response.data && !isBlank(err.response.data.message)) {
            openSnackbar('error', t('react.generic.error.while.saving.with.message', { message: err.response.data.message }));
          } else {
            openSnackbar('error', t('react.generic.error.while.saving'));
          }
        }).finally(() => {
          setConfirmInProgress(false);
        });
    } else {
      codificationService.updateBiblioEntry(libraryId, id, biblioEntryAsJson)
        .then(result => {
          // if it's OK, delete biblio-entries selected to delete
          let promises = [];
          biblioEntriesToDelete.forEach((biblioEntryToDelete) => {
            codificationService.deleteBiblioEntry(biblioEntryToDelete.libraryId, biblioEntryToDelete.id);
          });

          Promise.all(promises)
          .then(results => {
            callbackAfterConfirm(result.data);
          })
          .catch(err => {
            openSnackbar('error', t('react.generic.error.while.saving'));
          });
        })
        .catch(err => {
          if (err.response.data && !isBlank(err.response.data.message)) {
            openSnackbar('error', t('react.generic.error.while.saving.with.message', { message: err.response.data.message }));
          } else {
            openSnackbar('error', t('react.generic.error.while.saving'));
          }
        }).finally(() => {
          setConfirmInProgress(false);
        });
    }
  };

  const handleClickDeleteVariant = (event, idx) => {
    event.preventDefault();
    const newvariants = [...variants];
    newvariants.splice(idx, 1);
    setVariants(newvariants);
  };

  const addWordToVariants = (word) => {
    const newvariants = [...variants];

    if (indexOf(newvariants, 'variant', word) >= 0) {
      return;
    }

    let newVariant = {
      'id': 0,
      'variant': word,
      'creationDate': new Date()
    };
    newvariants.push(newVariant);
    setVariants(newvariants);
  };

  const handleClickDeleteBiblioEntry = (event, idx) => {
    event.preventDefault();

    // move the selected entry to the list to delete
    const newbiblioEntriesToDelete = [...biblioEntriesToDelete];
    newbiblioEntriesToDelete.push(associatedBiblioEntries[idx]);

    // remove from the displayed list
    const newassociatedBiblioEntries = [...associatedBiblioEntries];
    newassociatedBiblioEntries.splice(idx, 1);

    setAssociatedBiblioEntries(newassociatedBiblioEntries);
    setBiblioEntriesToDelete(newbiblioEntriesToDelete);
  };

  if (loadData !== LoadData.Loaded) {
    return null;
  }

  return (
    <div>
      <Dialog open={openState} onClose={handleClickCancel}>
        <DialogTitleWithCloseIcon
          title={t("react.codification.editcodif.title") + (biblioEntryId > 0 ? ` (id = ${biblioEntryId})` : '')}
          callbackOnclose={handleClickCancel}
        />
        <DialogContent>
          {codificationPaths !== undefined &&
          <FormControl fullWidth className={classes.spaceUnderControl}>
            <InputLabel id="libraryId-label" variant='standard'>{t("react.codification.editcodif.field.codification.path")}</InputLabel>
            <Select 
              id="libraryId"
              labelId="libraryId-label"
              value={libraryId}
              onChange={e => setLibraryId(parseInt(e.target.value))}
            >
              {
                codificationPaths.filter((k, v) => parseInt(k) > 0).map(([key, value]) => (
                  <MenuItem key={`listItemPath${key}`} value={parseInt(key)}>{value}</MenuItem>
                ))
              }
            </Select>
          </FormControl>
          }
          <TextField
            id="word"
            label={t("react.codification.editcodif.field.originalWord")}
            value={word}
            onChange={e => setWord(e.target.value)}
            autoFocus
            type="text"
            fullWidth
            className={classes.spaceUnderControl}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={t("react.codification.editcodif.field.copy.to.variants")}>
                    <span>
                      <IconButton
                        onClick={e => addWordToVariants(word)}
                        edge="end"
                        disabled={word.length === 0}
                        size="large">
                        <GetAppIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              )
            }}
          />
          <TextField
            id="language"
            label={t("react.codification.adddialog.field.language")}
            value={language}
            onChange={e => setLanguage(e.target.value.toLowerCase())}
            type="text"
            fullWidth
            className={classes.spaceUnderControl}
          />
          <TextField
            id="shorthandNotation"
            label={t("react.codification.adddialog.field.shorthandNotation")}
            value={shorthandNotation}
            onChange={e => handleTypeShorthandNotation(e.target.value)}
            type="text"
            inputProps={{ 'maxLength': 4 }}
            fullWidth
            className={classes.spaceUnderControl}
          />
          <FormControl fullWidth className={classes.spaceUnderControl}>
            <InputLabel id="nature-label" variant='standard'>{t("react.codification.editcodif.field.nature")}</InputLabel>
            <Select
              id="nature"
              labelId="nature-label"
              value={nature}
              onChange={e => handleChangeFieldValue('nature', e.target.value)}
              inputProps={{id: 'input-id-nature'}}
            >
              <MenuItem value={'Adjectivale'}>Adjectivale</MenuItem>
              <MenuItem value={'Nominale'}>Nominale</MenuItem>
              <MenuItem value={'Puissance'}>Puissance</MenuItem>
              <MenuItem value={'SigneIconique'}>SigneIconique</MenuItem>
              <MenuItem value={'Verbale'}>Verbale</MenuItem>
              <MenuItem disabled value={'Undefined'}>Undefined</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.spaceUnderControl}>
            <InputLabel id="logic-label" variant='standard'>{t("react.codification.editcodif.field.logic")}</InputLabel>
            <Select
              id="logic"
              labelId="logic-label"
              value={logic}
              onChange={e => handleChangeFieldValue('logic', e.target.value)}
              inputProps={{id: 'input-id-logic'}}
            >
              <MenuItem value={'Caracterisation'}>Caracterisation</MenuItem>
              <MenuItem value={'Designation'}>Designation</MenuItem>
              <MenuItem value={'Effet'}>Effet</MenuItem>
              <MenuItem value={'Jugement'}>Jugement</MenuItem>
              <MenuItem value={'Metaphore'}>Metaphore</MenuItem>
              <MenuItem disabled value={'Undefined'}>Undefined</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth className={classes.spaceUnderControl}>
            <InputLabel id="valence-label" variant='standard'>{t("react.codification.editcodif.field.valence")}</InputLabel>
            <Select
              id="valence"
              labelId="valence-label"
              value={valence}
              onChange={e => handleChangeFieldValue('valence', e.target.value)}
              inputProps={{id: 'input-id-valence'}}
            >
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={0}>0</MenuItem>
              <MenuItem value={-3}>-3</MenuItem>
            </Select>
          </FormControl>
          <TextField
            label={t("react.codification.editcodif.field.variants")}
            value={variantFieldValue}
            onChange={e => setVariantFieldValue(e.target.value)}
            type="text"
            fullWidth
            className={classes.spaceUnderControl}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip title={t("react.codification.editcodif.field.add.to.variants")}>
                    <span>
                      <IconButton
                        edge="end"
                        onClick={e => addWordToVariants(variantFieldValue)}
                        disabled={variantFieldValue.length === 0}
                        size="large">
                        <AddIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
          <List dense={true} className={classes.root}>
            <div style={{ height: '300px', overflowY: 'scroll', overflowX: 'hidden' }}>
              {variants.map((variant, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemText
                      primary={variant.variant}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        onClick={e => handleClickDeleteVariant(e, index)}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
              })
              }
            </div>
          </List>
          <Typography variant="subtitle1" gutterBottom>{t('react.dictionary.editword.usages')}</Typography>
          <List dense={true} className={classes.root}>
            <div style={{ height: '150px', overflowY: 'scroll', overflowX: 'hidden' }}>
            {associatedBiblioEntries.map((biblioEntry, index) => {
                return (
                  <ListItem key={`biblioEntry-${index}`}>
                    <ListItemText
                      primary={`${getShorthandNotationFromBiblioEntry(biblioEntry)} : ${biblioEntry.path}`}
                    />
                    <ListItemSecondaryAction>
                      <IconButton
                        edge="end"
                        disabled={biblioEntry.id === biblioEntryId}
                        onClick={e => handleClickDeleteBiblioEntry(e, index)}
                        size="large">
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                );
            })}
            </div>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={handleClickCancel}
          >
            {t("react.button.cancel")}
          </Button>
          <Button
            variant="outlined"
            size="large"
            color="primary"
            onClick={handleClickConfirm}
            disabled={(libraryId === undefined || libraryId <= 0) || isBlank(word) || isBlank(nature) || isBlank(logic) || isBlank(valence) || confirmInProgress}
          >
            {t(biblioEntryId === 0 ? "react.button.add" : "react.button.save")}
            {confirmInProgress && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default EditCodifDialog;
