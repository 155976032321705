import { RestClientService } from './RestClientService';
import i18n from 'i18next';

export const AuthService = {
    getUser,
    signIn,
    signInBearer,
    signOut,
    isVisitor,
    isManager,
    isAdmin,
    isSuperAdmin,
    getUserInitials,
    getUserFullname,
    refreshUser,
    updateCurrentUserPreferredLanguage,
    buildBearerAccessToken,
    markUserAsUnderSupervision,
    hasRightOfSupervision,
    hasRightToManageVisitor,
    hasRightToManageGrouping,
    hasAccessToAIFeatures
};

var isLoaded = false;

async function refreshUser() {
  // get current user in storage
  let currentUser = JSON.parse(localStorage.getItem('user'));

  // do nothing if not valid
  if(!currentUser || (!currentUser.authdata && !currentUser.bearerAuthdata)) return;

  if(!isLoaded) {
    // set auth into client
    if(currentUser.authdata) {
      RestClientService.setBasicAuth(currentUser.authdata);
    } else if (currentUser.bearerAuthdata) {
      RestClientService.setBearerAuth(currentUser.bearerAuthdata);
    }
    isLoaded = true;
  }

  let client = RestClientService.getClient();

  // retrieve me and my rights
  var promises = [];
  promises.push(client.get('/api/users/v1.0/me'));
  promises.push(client.get('/api/rights/v1.0/me'));

  return Promise.all(promises)
  .then(results => {
    // get user and keep auth alive
    let user = results[0].data;
    user.authdata = currentUser.authdata;
    user.bearerAuthdata = currentUser.bearerAuthdata;
    user.isUnderSupervision =  currentUser.isUnderSupervision;
    localStorage.setItem('user', JSON.stringify(user));
    localStorage.setItem('i18nextLng', user.preferredLanguage.toLowerCase());

    // only update i18n module language if has changed else infinit loop !
    if(currentUser.preferredLanguage !== user.preferredLanguage) {
      i18n.changeLanguage(user.preferredLanguage.toLowerCase());
    }

    // update my rights
    setUserRights(results[1].data);

    return user;
  })
  .catch(err => {
    return null;
  });
}

async function signIn(username, password) {
  let basicAuthClient = RestClientService.getAnonymousClient();
  return basicAuthClient.post('/api/auth/v1.0/authenticate', JSON.stringify({ username, password }))
    .then(response => {
      if (response.status === 200) {
        let user = response.data;
        user.authdata = window.btoa(username + ':' + password);
        user.bearerAuthdata = undefined;

        // use the basicAuthClient until full authentication is done
        basicAuthClient.defaults.headers.common['Authorization'] = 'Basic ' + user.authdata;
        return basicAuthClient.get('/api/rights/v1.0/me')
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem('user', JSON.stringify(user));
              i18n.changeLanguage(user.preferredLanguage.toLowerCase());
              localStorage.setItem('i18nextLng', user.preferredLanguage.toLowerCase());
              setUserRights(response.data);

              // authentication is done - set auth for all calls
              RestClientService.setBasicAuth(user.authdata);

              return user;
            }
            throw new Error("Authentication failed");
          });
      }
      throw new Error("Authentication failed");
    });
}

async function signInBearer(token) {
  let bearerAuthClient = RestClientService.getAnonymousClient();
  bearerAuthClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
  return bearerAuthClient.post('/api/auth/v1.0/bearer-token/authenticate')
    .then(response => {
      if (response.status === 200) {
        let user = response.data;
        user.authdata = undefined;
        user.bearerAuthdata = token;

        // use the bearerAuthClient until full authentication is done
        return bearerAuthClient.get('/api/rights/v1.0/me')
          .then(response => {
            if (response.status === 200) {
              localStorage.setItem('user', JSON.stringify(user));
              i18n.changeLanguage(user.preferredLanguage.toLowerCase());
              localStorage.setItem('i18nextLng', user.preferredLanguage.toLowerCase());
              setUserRights(response.data);

              // authentication is done - set auth for all calls
              RestClientService.setBearerAuth(user.bearerAuthdata);

              return user;
            }
            throw new Error("Authentication failed");
          });
      }
      throw new Error("Authentication failed");
    });
}

function signOut() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    localStorage.removeItem('user_rights');
    localStorage.removeItem('i18nextLng');
}

function getUser() {
  let user = JSON.parse(localStorage.getItem('user'));

  if(!isLoaded && user) {
    // set auth into client
    if(user.authdata) {
      RestClientService.setBasicAuth(user.authdata);
    } else if (user.bearerAuthdata) {
      RestClientService.setBearerAuth(user.bearerAuthdata);
    }
    isLoaded = true;
  }

  return user;
}

function getUserFullname() {
  let user = getUser();
  if(user) {
    let text = user.firstname + ' ' + user.lastname;
    return text;
  }
  return '';
}

function getUserInitials() {
  let user = getUser();
  if(user) {
    let text = user.firstname.slice(0,1).toUpperCase() + user.lastname.slice(0,1).toUpperCase();
    return text;
  }
  return '';
}

function getUserRights() {
  return isLoaded ? JSON.parse(localStorage.getItem('user_rights')) : {};
}

var isVisitorState = false;
var isManagerState = false;
var isAdminState = false;
var isSuperAdminState = false;

function setUserRights(rights) {
  // reset
  isVisitorState = false;
  isManagerState = false;
  isAdminState = false;
  isSuperAdminState = false;

  localStorage.setItem('user_rights', JSON.stringify(rights));
  for(var i = 0; i < rights.length; i++) {
    switch (rights[i].name) {
      case 'app_level_visitor':
        isVisitorState = true;
        break;
      case 'app_level_manager':
        isManagerState = true;
        break;
      case 'app_level_admin':
        isAdminState = true;
        break;
      case 'app_level_super_admin':
        isSuperAdminState = true;
        break;
    }
  }
}

function isVisitor() { return isVisitorState; }
function isManager() { return isManagerState || isAdminState || isSuperAdminState; }
function isAdmin() { return isAdminState || isSuperAdminState; }
function isSuperAdmin() { return isSuperAdminState; }

/**
* NOTE: Rights of supervision are NOT open to all "admin",
* having one of the acquire_token_obo_* is MANDATORY
**/
function hasRightOfSupervision() {
  if(isSuperAdmin()) return true;
  let userRights = getUserRights();
  if(userRights) {
    for(var i = 0; i < userRights.length; i++) {
      if(userRights[i].name === 'acquire_token_obo_teammembers') return isManager() /* he must be Manager too */;
      if(userRights[i].name === 'acquire_token_obo_overall') return isAdmin() /* he must be Admin too */;
    }
  }
  return false;
}

/**
* NOTE: Right to manage visitors is NOT open to all,
* user must be SuperAdmin or at least Manager with the right 'visitors_management'.
* Only Admin is not enough.
**/
function hasRightToManageVisitor() {
  if(isSuperAdmin()) return true;
  let userRights = getUserRights();
  if(userRights) {
    for(var i = 0; i < userRights.length; i++) {
      if(userRights[i].name === 'visitors_management') return isManager() /* he must be Manager too */;
    }
  }
  return false;
}

/**
* NOTE: Right to manage grouping is NOT open to all managers,
* user must be Admin or at least Manager with the right 'grouping_management'
**/
function hasRightToManageGrouping() {
  if(isAdmin()) return true;
  let userRights = getUserRights();
  if(userRights) {
    for(var i = 0; i < userRights.length; i++) {
      if(userRights[i].name === 'grouping_management') return isManager() /* he must be Manager too */;
    }
  }
  return false;
}

function updateCurrentUserPreferredLanguage(newPreferredLanguage) {
  let currentUser = JSON.parse(localStorage.getItem('user'));

  // do nothing if not valid
  if(!currentUser || !newPreferredLanguage) return;

  currentUser.preferredLanguage = newPreferredLanguage;
  localStorage.setItem('user', JSON.stringify(currentUser));
  i18n.changeLanguage(currentUser.preferredLanguage.toLowerCase());
  localStorage.setItem('i18nextLng', currentUser.preferredLanguage.toLowerCase());
}

async function buildBearerAccessToken(expire, target, type, id) {
  let jsonPayload = {
    'expire' : expire.toISOString(),
    'target' : target,
  };

  if(type !== undefined) {
    jsonPayload.type = type;
  }

  if(id !== undefined) {
    jsonPayload.id = id;
  }

  return RestClientService.getClient().post(`/api/auth/v1.0/bearer-token/build`, JSON.stringify(jsonPayload));
}

function markUserAsUnderSupervision() {
  let currentUser = JSON.parse(localStorage.getItem('user'));

  // do nothing if not valid
  if(!currentUser) return;

  currentUser.isUnderSupervision = true;
  localStorage.setItem('user', JSON.stringify(currentUser));
}

function hasAccessToAIFeatures() {
  if(isAdmin()) return true;
  let userRights = getUserRights();
  if(userRights) {
    for(var i = 0; i < userRights.length; i++) {
      if(userRights[i].name === 'access_to_ai_features') return true;
    }
  }
  return false;
}
