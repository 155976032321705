import { useState, useEffect } from 'react';

export const MOBILE = 1;
export const TABLET = 2;
export const DESKTOP = 3;

const useDeviceDetection = () => {
  const [device, setDevice] = useState(undefined);

  useEffect(() => {
    const handleDeviceDetection = () => {
      const userAgent = navigator.userAgent.toLowerCase();
      const isMobile = /iphone|ipad|ipod|android|blackberry|windows phone/g.test(userAgent);
      const isTablet = /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);

      if (isMobile) {
        setDevice(MOBILE);
      } else if (isTablet) {
        setDevice(TABLET);
      } else {
        setDevice(DESKTOP);
      }
    };

    handleDeviceDetection();
    window.addEventListener('resize', handleDeviceDetection);

    return () => {
      window.removeEventListener('resize', handleDeviceDetection);
    };
  }, []);

  return device;
};

export default useDeviceDetection;