import { useState, useEffect } from 'react';
import { useParams } from "react-router-dom";
import {LoadData} from '../../Constants.js';
import CollectorsService from '../../services/CollectorsService';
import Collectors  from './Collectors.js';
import Box from '@mui/material/Box';
import queryString from 'query-string';
import { isBlank } from '../../utils/utils.js';
import { v4 as uuidv4 } from 'uuid';

const collectorsService = new CollectorsService();

export default function ObfuscatedCollectors(props) {

    const { 
        collectUuidParameter
    } = useParams();

    const {
        t,
        openSnackbar,
        showSpinner
    } = props;

    const [surveyId, setSurveyId] = useState(0);
    const [participantUuid, setParticipantUuid] = useState(undefined);
    const [queryParameters, setQueryParameters] = useState(undefined);

    const [loadData, setLoadData] = useState(LoadData.Load);

    useEffect(() => {
        if(loadData !== LoadData.Load) return;

        showSpinner(true);

        // read parameters
        let queryParameters = queryString.parse(window.location.search);
        setQueryParameters(queryParameters);

        // NOTE: if no participant uuid is provided, the backend will affect a new one to the user
        let participantIdToSet = undefined;

        // check the Type Of Link, is any
        if (!isBlank(queryParameters.tol)) {
            switch (queryParameters.tol) {
                case 'r':
                    // link type is 'resusable'
                    // force generation of a participant uuid to avoid security checks
                    participantIdToSet = uuidv4();
                    break;
                default:
                    // unsupported type of link
                    setSurveyId(0);

                    showSpinner(false);
                    setLoadData(LoadData.Loaded);

                    openSnackbar('error', t('react.error.collectform.forbidden'));

                    return;
            }
        }

        // finaly, if an external participant uuid is forced, use it
        if (!isBlank(queryParameters.uuid)) {
            participantIdToSet = queryParameters.uuid;
        }

        setParticipantUuid(participantIdToSet);
        
        async function fetchData() {
            const form = await collectorsService.getClientFormByCollectUuid(collectUuidParameter)
                .then(result => {
                    setSurveyId(result.data.id);
                    showSpinner(false);
                    setLoadData(LoadData.Loaded);
                })
                .catch(e => {
                    setSurveyId(0);
                    showSpinner(false);
                    setLoadData(LoadData.Loaded);

                    if (e.response && e.response.status && e.response.status === 403) {
                        openSnackbar('error', t('react.error.collectform.forbidden'));
                    } else {
                        openSnackbar('error', t('react.error.fetch.message'));
                    }
                });
        }
        fetchData();
    }, [collectUuidParameter]);

    if (loadData !== LoadData.Loaded) return null;

    // data are loaded but user is not allowed to participate
    if(surveyId === 0) {
        return (<Box>{t('react.error.collectform.forbidden.message')}</Box>);
    }

    return (<Collectors 
        {...props} 
        providedSurveyId={surveyId} 
        providedParticipantUuid={participantUuid} 
        providedQueryParameters={queryParameters} 
        testMode={false}
    />);
};