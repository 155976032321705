import { RestClientService } from './RestClientService';
require('dotenv').config();

class PreferenceService {

    constructor() {
        this.state = {
            restClient: RestClientService.getClient()
        };
    }

    getRestClient() {
        return this.state.restClient;
    }

    async setMyDefaultDashboardConfiguration(configurationToUseAsDefaultPreferences) {
        return this.getRestClient().post('/api/preferences/v1.0/dashboard', configurationToUseAsDefaultPreferences);
    }

    async getMyDefaultDashboardConfiguration() {
        return this.getRestClient().get('/api/preferences/v1.0/dashboard');
    }

    async setMenubarState(newState) {
        return this.getRestClient().post(`/api/preferences/v1.0/menubar/state/${newState}`);
    }

    async getMenubarState() {
        return this.getRestClient().get('/api/preferences/v1.0/menubar/state');
    }
}

export default PreferenceService;
