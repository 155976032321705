import { RestClientService } from './RestClientService';
require('dotenv').config();

class GroupingService {

    constructor() {
        this.state = {
            restClient: RestClientService.getClient()
        };
    }

    getRestClient() {
        return this.state.restClient;
    }

    async fetchBiblio(projectId) {
        return this.getRestClient().get(`/api/grouping/v1.0/${projectId}/biblio`);
    }

    async fetchGroups(projectId) {
        return this.getRestClient().get(`/api/grouping/v1.0/${projectId}`);
    }

    async createGroup(projectId, bucket, newGroup) {
        newGroup.projectId = projectId;
        newGroup.bucket = bucket;
        return this.getRestClient().post(`/api/grouping/v1.0/${projectId}`, newGroup);
    }

    async mapToGroup(projectId, groupId, positionMap) {
        return this.getRestClient().put(`/api/grouping/v1.0/${projectId}/${groupId}/positions`, positionMap);
    }

    async deleteGroup(projectId, groupId) {
        return this.getRestClient().delete(`/api/grouping/v1.0/${projectId}/${groupId}`);
    }

    async reorderGroups(projectId, bucketId, positionMap) {
        return this.getRestClient().put(`/api/grouping/v1.0/${projectId}/positions/${bucketId}`, positionMap);
    }

    async reorderTags(projectId, positionsMap) {
        return this.getRestClient().put(`/api/grouping/v1.0/${projectId}/tags/positions`, positionsMap);
    }

    async renameGroup(projectId, groupId, title) {
        return this.getRestClient().patch(`/api/grouping/v1.0/${projectId}/${groupId}/title`, title);
    }

    async fetchTags(projectId) {
        return this.getRestClient().get(`/api/grouping/v1.0/${projectId}/tags`);
    }

    async deleteTag(projectId, tagId) {
        return this.getRestClient().delete(`/api/grouping/v1.0/${projectId}/tags/${tagId}`);
    }

    async createTag(projectId, label, color) {
        return this.getRestClient().post(`/api/grouping/v1.0/${projectId}/tags`, {label:label, color: color});
    }

    async updateTag(projectId, id, label, color) {
        return this.getRestClient().put(`/api/grouping/v1.0/${projectId}/tags/${id}`, {label:label, color: color});
    }

    async mapTagGroup(projectId, groupId, tagId) {
        return this.getRestClient().patch(`/api/grouping/v1.0/${projectId}/${groupId}/tag`, tagId);
    }

    async getGroupingAsExcelFile(projectId) {
        return this.getRestClient().get('/api/grouping/v1.0/' + projectId + '/export/xlsx', {responseType: 'blob'});
    }

    async generateGrouping(projectId, codificationLibraryId = 0, includedProjectIds = []) {
        return this.getRestClient().post(`/api/grouping/v1.0/${projectId}/generate?codificationLibraryId=${codificationLibraryId}&includedProjectIds=${includedProjectIds}`);
    }
}

export default GroupingService;
