import React from 'react';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import { makeStyles } from 'tss-react/mui';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ISO6391 from 'iso-639-1';

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  download : {
    marginBottom: theme.spacing(2),
    verticalAlign: 'middle',
    "& svg": {
      verticalAlign: 'middle',
      marginRight: '8px'
    }
  },
  sectionTitle : {
    marginBottom: theme.spacing(2),
    fontWeight: "bold",
  },
  sectionBody : {
    marginBottom: theme.spacing(2),
  },
  divider : {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  bottomLink: {
    cursor: 'pointer'
  },
  linkDocumentation: {
    fontSize: 16,
    marginBottom: 20
  },
  listTypoItem: {
    marginTop: 4
  },
  buttonApi: {
    marginTop: 20
  },
  marginRightButton: {
    marginRight: 10
  },
  marginTopButtonPdf: {
    marginTop: 20
  }
}));

export default function Documentation(props) {

  const { t } = props;

  const { classes } = useStyles();

  const text = t('react.documentation.text.download.list');
  const textArray = text.split("\n");

  return (
    <Paper className={classes.root}>
      <Typography variant="h5" className={classes.sectionTitle}>
        {t('react.documentation.title.download')}
      </Typography>
      <Typography variant="body1" className={classes.download}>
          <PictureAsPdfIcon color="error"/>{t('react.documentation.text.main')}
      </Typography>
      {textArray.map((item, index) => (
        <Typography key={index} className={classes.listTypoItem}>{item}</Typography>
      ))}
      <Typography className={classes.marginTopButtonPdf}>{t('react.documentation.text.download')}</Typography>
      <div className={classes.marginTopButtonPdf}>
        <Link target="new" href="files/Toolkit-plateforme-fr-r3mscore-diy-janvier-2024.pdf" >
          <Button variant="contained" size="large" color="primary" className={classes.marginRightButton}>
            {ISO6391.getNativeName('fr')}
          </Button>
        </Link>
        <Link target="new" href="files/Toolkit-plateforme-en-r3mscore-diy-janvier-2024.pdf" >
          <Button variant="contained" size="large" color="primary">
            {ISO6391.getNativeName('en')}
          </Button>
        </Link>
      </div>
      <Divider className={classes.divider}/>
      <Typography variant="h5" className={classes.sectionTitle}>
        {t('react.documentation.title.excel')}
      </Typography>
      <Typography variant="body1" className={classes.download}>
        {t('react.documentation.text.excel')}
      </Typography>
      <Typography>{t('react.documentation.text.download')}</Typography>
      <div className={classes.marginTopButtonPdf}>
        <Link target="new" href="files/Tuto-plateforme-fr-v1.pdf" >
          <Button variant="contained" size="large" color="primary" className={classes.marginRightButton}>
            {ISO6391.getNativeName('fr')}
          </Button>
        </Link>
        <Link target="new" href="files/Tuto-plateforme-en-v1.pdf" >
          <Button variant="contained" size="large" color="primary">
            {ISO6391.getNativeName('en')}
          </Button>
        </Link>
      </div>
      <Divider className={classes.divider}/>
      <Typography variant="h5" className={classes.sectionTitle}>
        {t('react.documentation.title.surveys')}
      </Typography>
      <Typography variant="body1" className={classes.download}>
        {t('react.documentation.text.surveys')}
      </Typography>
      <Typography>{t('react.documentation.surveys.download')}</Typography>
      <div className={classes.marginTopButtonPdf}>
        <Link target="new" href="files/Toolkit-formulaires-fr-r3mscore-octobre-2024.pdf" >
          <Button variant="contained" size="large" color="primary" className={classes.marginRightButton}>
            {ISO6391.getNativeName('fr')}
          </Button>
        </Link>
        <Link target="new" href="files/Toolkit-formulaires-en-r3mscore-octobre-2024.pdf" >
          <Button variant="contained" size="large" color="primary" className={classes.marginRightButton}>
            {ISO6391.getNativeName('en')}
          </Button>
        </Link>
      </div>
      <Divider className={classes.divider}/>
      <Typography variant="h5" className={classes.sectionTitle}>
        Api
      </Typography>
      <Typography className={classes.listTypoItem}>{t('react.documentation.text.api')}</Typography>
      <Link href={process.env.REACT_APP_R3MSCORE_DOCS_URL} >
          <Button variant="outlined" size="small" color="primary" className={classes.buttonApi}>
            Api Reference
          </Button>
        </Link>
    </Paper>
  );
};
