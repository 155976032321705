import React, { useState, useContext, useReducer, useRef } from 'react';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import { SurveyContext } from './context';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { isBlank } from '../../utils/utils.js';
import { isBlankHtml } from '../../utils/surveysUtils.js';
import QuillEditor from './QuillEditor.js';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import ParamsImage from './ParamsImage';
import Button from '@mui/material/Button';
import BlockHeader from './BlockHeader.js';

const useStyles = makeStyles()(theme => ({
    previewRoot: {
        marginBottom: '25px',
        margin: '0',
        padding: '5px',
        width: `250px`,
        height: `150px`,
        display: 'inline-block',
        borderStyle: 'dotted',
        borderRadius: '7px',
    },
    previewImg: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '140px',
        position: 'relative',
        top: '-50px'
    },
    previewBtns: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        height: '50px',
        position: 'relative', top: '25%', zIndex: '100'
    },
    alignCenter: {
        textAlign: 'center',
        verticalAlign: 'middle',
        maxWidth: '100%',
        maxHeight: '200px'
    },
    paddingPaper: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 1,
        paddingBottom: 30,
    },
    marginBottomTop: {
        marginBottom: 20,
        marginTop: 20,
    },
    formControlSelect: {
        minWidth: '150px'
    },
    gridImage: {
        marginTop: 20,
        marginBottom: 20,
    },
    textFieldContent: {
        marginTop: 40
    },
}));

export default function OpenQuestion(props) {

    const { classes } = useStyles();

    const { t, uuid } = props

    const { formIdParameter, generateSetImageButton, generateDeleteImageButton, isBlockCurrentlyDisplayed,
        setNeedToSave, getComponentConfiguration, mediaFiles } = useContext(SurveyContext);

    const [uploading, setUploading] = useState(false);
    const [uploadButtonVisibility, setUploadButtonVisibility] = useState(false);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [openDialogImage, setOpenDialogImage] = useState(false);

    const quillRef = useRef();

    const changeField = (fieldName, value) => {
        switch (fieldName) {
            case "ref":
                getComponentConfiguration(uuid).ref = value;
                break;
            case "title":
                getComponentConfiguration(uuid).openQuestion.stimulusName = value;
                break;
            case "question":
                getComponentConfiguration(uuid).openQuestion.question = value;
                break;
            case "enabledBlock":
                getComponentConfiguration(uuid).active = !getComponentConfiguration(uuid).active;
                break;
            case "minimumCharacters":
                getComponentConfiguration(uuid).openQuestion.minimumNumberOfCharacters = value;
                break;
        }
        setNeedToSave(true);
        forceUpdate();
    }

    const handleChangeTextFieldNumber = (event) => {
        getComponentConfiguration(uuid).openQuestion.nbTextFields = event.target.value;
        setNeedToSave(true);
        forceUpdate();
    }

    const handleChangeTextFieldRows = (event) => {
        getComponentConfiguration(uuid).openQuestion.nbTextFieldRows = event.target.value;
        setNeedToSave(true);
        forceUpdate();
    }

    if(!isBlockCurrentlyDisplayed(uuid)) return null;

    return (
        <>
        {/* Define some popup and dialog */}
        <ParamsImage
            {...props}
            uuid={uuid}
            openDialogImage={openDialogImage}
            setOpenDialogImage={setOpenDialogImage}
            enableFullScreenMode={false}
        />
        {/* Header of the block */}
        <BlockHeader
            {...props}
            blockRef={getComponentConfiguration(uuid).ref}
            setBlockRef={(newValue) => changeField('ref', newValue)}
            isActive={getComponentConfiguration(uuid).active}
            setIsActive={(newValue) => changeField('enabledBlock', newValue)}
            title={t("react.project.collectforms.block.openQuestion")}
        />
        <Paper className={classes.paddingPaper}>
            <Grid item xs={3} xm={4} className={classes.gridImage}>
                <div id={`preview_img_${uuid}`} className={classes.previewRoot}
                    onMouseOver={e => setUploadButtonVisibility(true)}
                    onMouseOut={e => setUploadButtonVisibility(false)}
                >
                    {<div id={`preview_button_${uuid}`} className={classes.previewBtns}>
                        <div style={{ display: (formIdParameter > 0 && (uploading || getComponentConfiguration(uuid).imageId === 0 || uploadButtonVisibility)) ? 'block' : 'none' }}>
                            {!uploading && generateSetImageButton(uuid, setUploading)}
                            {!uploading && generateDeleteImageButton(uuid)}
                            {uploading && <CircularProgress />}
                        </div>
                    </div>}
                    {getComponentConfiguration(uuid).imageId > 0 && <div className={classes.alignCenter}>
                        <img
                            id={`img_${uuid}`}
                            className={classes.previewImg}
                            src={mediaFiles[getComponentConfiguration(uuid).imageId]}
                            style={{ opacity: uploadButtonVisibility ? '0.3' : '1' }} />
                    </div>}
                </div>
            </Grid>
            {getComponentConfiguration(uuid).imageId > 0 &&
                <Button style={{marginBottom: 20}} onClick={() => setOpenDialogImage(true)} variant="contained" endIcon={<ImageSearchIcon />}>
                    {t("react.surveys.button.params.image")}
                </Button>
            }
            <TextField
                error={isBlank(getComponentConfiguration(uuid).openQuestion.stimulusName)}
                label={`${t("react.project.collectforms.block.stimulusname")} (${(getComponentConfiguration(uuid).openQuestion.stimulusName || '').length} / 255)`}
                fullWidth
                value={getComponentConfiguration(uuid).openQuestion.stimulusName}
                onChange={e => changeField('title', e.target.value)}
                inputProps={{ maxLength: 255 }}
            ></TextField>
            <QuillEditor
                ref={quillRef}
                value={getComponentConfiguration(uuid).openQuestion.question}
                onChange={(value) => changeField('question', value)}
                isError={isBlankHtml(getComponentConfiguration(uuid).openQuestion.question)}
                style={{height: 400, marginTop: 30, paddingBottom: 42}}
                placeholder= {t("react.project.collectforms.openquestioncontent")}
                {...props}
            />
            <Grid container className={classes.gridImage}>
                <Grid item xs={4}>
                    <FormControl className={classes.formControlSelect}>
                        <TextField
                            select
                            value={getComponentConfiguration(uuid).openQuestion.nbTextFields}
                            onChange={handleChangeTextFieldNumber}
                            label={t("react.project.collectforms.numberTextFields")}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <FormControl className={classes.formControlSelect}>
                        <TextField
                            select
                            value={getComponentConfiguration(uuid).openQuestion.nbTextFieldRows}
                            onChange={handleChangeTextFieldRows}
                            label={t("react.project.collectforms.numberTextFieldRows")}
                        >
                            <MenuItem value={1}>1</MenuItem>
                            <MenuItem value={2}>2</MenuItem>
                            <MenuItem value={3}>3</MenuItem>
                            <MenuItem value={4}>4</MenuItem>
                            <MenuItem value={5}>5</MenuItem>
                            <MenuItem value={6}>6</MenuItem>
                            <MenuItem value={7}>7</MenuItem>
                            <MenuItem value={8}>8</MenuItem>
                            <MenuItem value={9}>9</MenuItem>
                            <MenuItem value={10}>10</MenuItem>
                        </TextField>
                    </FormControl>
                </Grid>
                <Grid item xs={4} style={{ position: 'relative' }}>
                    <TextField
                        value={getComponentConfiguration(uuid).openQuestion.minimumNumberOfCharacters}
                        onChange={e => changeField('minimumCharacters', e.target.value)}
                        label={t("react.project.collectforms.minimumNumberOfCharacters")}
                        type='number'
                        className={classes.formControlSelect}
                    />
                </Grid>
            </Grid>
        </Paper>
    </>
    );
}
