import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import { LoadData } from '../../Constants.js';
import SurveysService from '../../services/SurveysService';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import InputAdornment from '@mui/material/InputAdornment';
import SaveIcon from '@mui/icons-material/Save';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import { makeStyles } from 'tss-react/mui';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { isBlank } from '../../utils/utils.js';
import DoneIcon from '@mui/icons-material/Done';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import DoneAllIcon from '@mui/icons-material/DoneAll';

const surveysService = new SurveysService();

const useStyles = makeStyles()(theme => ({
    containerSticky: {
      position: 'sticky',
      top: '64px',
      background: 'white',
      zIndex: '99',
      borderBottom: '1px solid rgba(0,0,0,0.2)',
      width: '100%'
    }
  }));

export default function Datamap(props) {

    const { formIdParameter } = useParams();

    const { classes } = useStyles();
   
    const {
        t,
        openSnackbar,
        showSpinner
    } = props;

    const [loadData, setLoadData] = useState(LoadData.Load);
    const [form, setForm] = useState('');
    const [datamapObject, setDatamapObject] = useState([]);
    const [selectedBlocks, setSelectedBlocks] = useState([]);
    const [needToSave, setNeedToSave] = useState(false);
    const [mouseOverIdentifier, setMouseOverIdentifier] = useState([undefined, undefined]);
    const [renameIdentifier, setRenameIdentifier] = useState([undefined, undefined]);
    const [renameText, setRenameText] = useState(undefined);

    useEffect(() => {
        if (loadData !== LoadData.Load) return;

        setLoadData(LoadData.Loading);

        var promises = [
            surveysService.fetchForm(formIdParameter),
            surveysService.getDatamap(formIdParameter)
        ];

        Promise.all(promises)
            .then(function (results) {
                setForm(results[0].data);
                setDatamapObject(results[1].data);
            })
            .catch(err => {
                openSnackbar('error', t("react.error.fetch.message"));
            }).finally(() => {
                setLoadData(LoadData.Loaded);
            });
    }, [loadData, formIdParameter]);
    
    const calculateStartEnd = (inputArray, startProcessingAt) => {
        var processedArray = [];
        for (let i = 0; i < inputArray.length; i++) {
            if(i < startProcessingAt || !inputArray[i].editable) {
                // push untouched
                processedArray.push(inputArray[i]);
                continue;
            }

            var previousMapping = inputArray[i - 1];

            // change the start if over the end of the previous
            if(inputArray[i].startAt <= previousMapping.endAt) {
                inputArray[i].startAt = previousMapping.endAt + 1;
            }
            // compute the end
            inputArray[i].endAt = inputArray[i].startAt + inputArray[i].width - 1;

            processedArray.push(inputArray[i]);
        }

        return processedArray;
    };

    const saveDatamap = () => {
        showSpinner(true);
        surveysService.saveDatamap(formIdParameter, datamapObject)
            .then((result) => {
                setDatamapObject(result.data);
                setNeedToSave(false);
            })
            .catch(err => {
                openSnackbar('error', t("react.error.fetch.message"));
            })
            .finally(() => {
                showSpinner(false);
            });
    };

    const clickOnCheckbox = (blockId) => {
        setSelectedBlocks(prevSelectedBlocks => {
            if (prevSelectedBlocks.includes(blockId)) {
                return prevSelectedBlocks.filter(id => id !== blockId);
            } else {
                return [...prevSelectedBlocks, blockId];
            }
        });
    };

    const onSelectAllClick = () => {
        setSelectedBlocks(prevSelectedBlocks => {
            return prevSelectedBlocks.length > 0 ? [] : datamapObject.filter(d => d.exportable).map(d => d.id);
        } );
    };

    const clickOnCheckboxExportable = (blockId) => {
        var tempArr = [...datamapObject];
        var findObj = tempArr.find(obj => obj.id === blockId);
        findObj.exportable = !findObj.exportable;
        setNeedToSave(true);
        setDatamapObject(tempArr);
    };

    const alignTop = () => {
        let tempArr = [...datamapObject];

        // Extract the selected blocks
        const selectedBlockObjects = selectedBlocks
            .map(id => tempArr.find(block => block.id === id))
            .sort((a, b) => a.position - b.position);

        selectedBlockObjects.forEach((selectedBlock, index) => {
            const currentIndex = tempArr.indexOf(selectedBlock);
            if (currentIndex > 3) { // Only allow moving if current index is greater than 3
                tempArr.splice(currentIndex, 1);
                tempArr.splice((3 + index), 0, selectedBlock);
            }
        });

        // Update the position property of each block
        tempArr.forEach((block, index) => {
            block.position = index;
        });

        // init the start of the new first editable block
        tempArr[3].startAt = tempArr[2].endAt + 1;

        setDatamapObject(calculateStartEnd(tempArr, 3));
        setNeedToSave(true);
    };

    const alignBottom = () => {
        let tempArr = [...datamapObject];

        // Extract the selected block objects
        const selectedBlockObjects = selectedBlocks
            .map(id => tempArr.find(block => block.id === id))
            .sort((a, b) => a.position - b.position);

        selectedBlockObjects.forEach(selectedBlock => {
            const currentIndex = tempArr.indexOf(selectedBlock);
            tempArr.splice(currentIndex, 1);
            tempArr.push(selectedBlock);
        });

        let refreshFrom = tempArr.length - selectedBlocks.length;

        setDatamapObject(calculateStartEnd(tempArr, refreshFrom));
        setNeedToSave(true);
    };

    const moveUpward = () => {    
        let tempArr = [...datamapObject];
        
        const selectedBlockObjects = selectedBlocks
            .map(id => tempArr.find(block => block.id === id))
            .sort((a, b) => a.position - b.position);

        const firstIndexToRefreshAt = tempArr.findIndex(it => it.id === selectedBlockObjects[0].id) - 1;

        selectedBlockObjects.forEach(selectedBlock => {
            const currentIndex = tempArr.indexOf(selectedBlock);
            if (currentIndex > 3) { // Ensure we don't move above index 3
                const previousAboveBlock = tempArr[currentIndex - 2];
                const aboveBlock = tempArr[currentIndex - 1];
                if (!selectedBlocks.includes(aboveBlock.id)) {
                    selectedBlock.startAt = previousAboveBlock.endAt + 1;

                    // Update the positions in the tempArr
                    tempArr[currentIndex - 1] = selectedBlock;
                    tempArr[currentIndex] = aboveBlock;
                    
                    // Update the position property of each block
                    tempArr[currentIndex - 1].position = currentIndex - 1;
                    tempArr[currentIndex].position = currentIndex;
                }
            }
        });
        setDatamapObject(calculateStartEnd(tempArr, firstIndexToRefreshAt));
        setNeedToSave(true);
    };

    const moveDownward = () => {
        let tempArr = [...datamapObject];
        
        const selectedBlockObjects = selectedBlocks
            .map(id => tempArr.find(block => block.id === id))
            .sort((a, b) => b.position - a.position);

        selectedBlockObjects.forEach(selectedBlock => {
            const currentIndex = tempArr.indexOf(selectedBlock);
            if (currentIndex < tempArr.length - 1) {
                const belowBlock = tempArr[currentIndex + 1];
                if (!selectedBlocks.includes(belowBlock.id)) {
                    selectedBlock.startAt = belowBlock.endAt + 1;

                    // Update the positions in the tempArr
                    tempArr[currentIndex + 1] = selectedBlock;
                    tempArr[currentIndex] = belowBlock;
                    
                    // Update the position property of each block
                    tempArr[currentIndex + 1].position = currentIndex + 1;
                    tempArr[currentIndex].position = currentIndex;
                }
            }
        });
        setDatamapObject(calculateStartEnd(tempArr));
        setNeedToSave(true);
    };

    const changeWidth = (mappingIdx, svalue) => {
        let value = parseInt(svalue);
        if (value < 1) return;

        var tempArr = [...datamapObject];
        tempArr[mappingIdx].width = value;
        tempArr[mappingIdx].endAt = tempArr[mappingIdx].startAt + tempArr[mappingIdx].width - 1;

        setDatamapObject(calculateStartEnd(tempArr, mappingIdx + 1));
        setNeedToSave(true);
    };

    const changeStartAt = (mappingIdx, svalue) => {
        let value = parseInt(svalue);
        if (value < 1) return;

        var tempArr = [...datamapObject];

        // it's not possible to go down before the previous
        if(tempArr[mappingIdx - 1].endAt >= value) return;

        tempArr[mappingIdx].startAt = value;
        tempArr[mappingIdx].endAt = tempArr[mappingIdx].startAt + tempArr[mappingIdx].width - 1;

        setDatamapObject(calculateStartEnd(tempArr, mappingIdx + 1));
        setNeedToSave(true);
    };

    const buildDatamap = () => {
        showSpinner(true);

        surveysService.buildDatamap(formIdParameter)
            .then(result => {
                setDatamapObject(result.data);
                setNeedToSave(false);
            })
            .catch(err => {
                openSnackbar('error', t("react.collectors.datamap.build.error"));
            }).finally(() => {
                showSpinner(false);
            });
    };

    const handleQuit = (event) => {
        event.preventDefault();
        props.navigate(`/surveys/${formIdParameter}`);
    };

    const handleMouseOver = (identifier, type) => {
        setMouseOverIdentifier([identifier, type]);
    };

    const handleStartRename = (event, identifier, initalText, type) => {
        event.preventDefault();
        setRenameIdentifier([identifier, type]);
        setRenameText(initalText);
        setMouseOverIdentifier([undefined, undefined]);
    };

    const handleClickAway = (event) => {
        event.preventDefault();
        setRenameIdentifier([undefined, undefined]);
        setRenameText(undefined);
        setMouseOverIdentifier([undefined, undefined]);
    };

    const handleValidRename = (event, type, idP) => {
        event.preventDefault();
        const tempArr = [...datamapObject];
        tempArr.find(obj => obj.id === idP)[type] = renameText;
        setDatamapObject(tempArr);
        setRenameIdentifier([undefined, undefined]);
        setRenameText(undefined);
        setMouseOverIdentifier([undefined, undefined]);
        setNeedToSave(true);
    };

    const checkAllExportable = () => {
        let oneChecked = datamapObject.find(obj => obj.editable && obj.exportable);
        const updatedDatamapObject = datamapObject.map(obj => {
            if (obj.editable) {
                return {
                    ...obj, 
                    exportable: !oneChecked
                };
            }
            return obj;
        });

        setNeedToSave(true);
        setDatamapObject(updatedDatamapObject);
    };

    const printName = (question) => {
        if(renameIdentifier[0] === question.id && renameIdentifier[1] === 'name') {
            return (<ClickAwayListener onClickAway={handleClickAway}>
                <TextField
                    type="text"
                    value={renameText}
                    onChange={e => setRenameText(e.target.value)}
                    fullWidth={true}
                    error={isBlank(renameText)}
                    autoFocus
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title={t("react.datamap.rename.save")}><span>
                                <IconButton component="span"
                                    onClick={(e) => handleValidRename(e, 'name', question.id)}
                                    disabled={isBlank(renameText)}
                                    size="large">
                                    <DoneIcon style={{ color : !isBlank(renameText) ? '#66bb6a' : '#eee'}} />
                                </IconButton></span>
                            </Tooltip>
                        </InputAdornment>
                        )
                    }}
                /></ClickAwayListener>
            );
        }

        return (
            <>
                {question.name}
                {question.editable && 
                    <Tooltip title={t('react.datamap.rename.tooltip')}>
                        <IconButton component="span" onClick={(e) => handleStartRename(e, question.id, question.name, 'name')}>
                            <EditOutlinedIcon fontSize="small" sx={{verticalAlign: 'sub', display: mouseOverIdentifier[0] === question.id && mouseOverIdentifier[1] === 'name' ? 'inline-flex': 'none'}} />
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    };

    const printLabel = (question) => {
        if(renameIdentifier[0] === question.id && renameIdentifier[1] === 'label') {
            return (
                <ClickAwayListener onClickAway={handleClickAway}>
                    <TextField
                        type="text"
                        value={renameText}
                        onChange={e => setRenameText(e.target.value)}
                        fullWidth={true}
                        error={isBlank(renameText)}
                        autoFocus
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <Tooltip title={t("react.datamap.rename.save")}><span>
                                    <IconButton component="span"
                                        onClick={(e) => handleValidRename(e, 'label', question.id)}
                                        disabled={isBlank(renameText)}
                                        size="large">
                                        <DoneIcon style={{ color : !isBlank(renameText) ? '#66bb6a' : '#eee'}} />
                                    </IconButton></span>
                                </Tooltip>
                            </InputAdornment>
                            )
                        }}
                /></ClickAwayListener>
            );
        }

        return (
            <>
                {question.label}
                {question.editable && 
                    <Tooltip title={t('react.datamap.rename.tooltip')}>
                        <IconButton component="span" onClick={(e) => handleStartRename(e, question.id, question.label, 'label')}>
                            <EditOutlinedIcon fontSize="small" sx={{verticalAlign: 'sub', opacity: mouseOverIdentifier[0] === question.id && mouseOverIdentifier[1] === 'label' ? '1': '0'}} />
                        </IconButton>
                    </Tooltip>
                }
            </>
        );
    };

    if (loadData !== LoadData.Loaded) {
        return (
            <Grid item xs={12} container>
                <Grid item xs={12}>
                    <LinearProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <div>
            <div className={classes.containerSticky}>
                <Toolbar style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <Tooltip title={t("react.collectors.list.tooltip.survey")}>
                                <IconButton component="span" size='small' onClick={event => handleQuit(event)}>
                                    <ArrowBackIcon />
                                </IconButton>
                            </Tooltip>
                            <Typography style={{position: 'absolute', left: 65, top: 21}}>{form.name}</Typography>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row-reverse'}}>
                            <Tooltip title={t("react.datamap.save")}>
                                <IconButton component="span" onClick={() => saveDatamap()}>
                                    <SaveIcon color={needToSave ? "error" : ""}/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("react.collectors.datamap.align.top")}>
                                <IconButton component="span" disabled={selectedBlocks.length === 0} onClick={() => alignTop()}>
                                    <VerticalAlignTopIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("react.collectors.datamap.upward")}>
                                <IconButton component="span" disabled={selectedBlocks.length === 0} onClick={() => moveUpward()}>
                                    <ArrowUpwardIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("react.collectors.datamap.downward")}>
                                <IconButton component="span" disabled={selectedBlocks.length === 0} onClick={() => moveDownward()}>
                                    <ArrowDownwardIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("react.collectors.datamap.align.bottom")}>
                                <IconButton component="span" disabled={selectedBlocks.length === 0} onClick={() => alignBottom()}>
                                    <VerticalAlignBottomIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("react.collectors.datamap.checkAll")}>
                                <IconButton component="span" onClick={() => checkAllExportable()}>
                                    <DoneAllIcon/>
                                </IconButton>
                            </Tooltip>
                            <Tooltip title={t("react.collectors.datamap.build")}>
                                <IconButton component="span" onClick={() => buildDatamap()}>
                                    <AutoFixHighIcon/>
                                </IconButton>
                            </Tooltip>
                        </div>
                </Toolbar>
            </div>
            <TableContainer component={Paper} style={{marginTop: 0}}>
                <Table sx={{ minWidth: 650 }}>
                    <TableHead>
                        <TableRow>
                            <TableCell component="th" scope="row" style={{width: 80}}>
                                <Checkbox
                                    id='checkbox-all'
                                    indeterminate={selectedBlocks.length > 0 && selectedBlocks.length < datamapObject.length}
                                    checked={selectedBlocks.length === datamapObject.length}
                                    onChange={onSelectAllClick}
                                />
                            </TableCell>
                            <TableCell style={{width: 250}} >{t("react.collectors.datamap.name")}</TableCell>
                            <TableCell style={{width: 330}}>{t("react.collectors.datamap.label")}</TableCell>
                            <TableCell style={{width: 150}}>{t("react.collectors.datamap.export")}</TableCell>
                            <TableCell align="left">{t("react.collectors.datamap.width")}</TableCell>
                            <TableCell align="left">{t("react.collectors.datamap.start")}</TableCell>
                            <TableCell align="right">{t("react.collectors.datamap.end")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {datamapObject.map((question, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row" style={{width: 80}}>
                                    {question.editable ? 
                                        <Checkbox id={`checkboxSelect-${question.id}`} color="primary" onChange={() => clickOnCheckbox(question.id)} checked={selectedBlocks.includes(question.id)}/>
                                        : <Checkbox color="primary" disabled checked={question.exportable}/>
                                    }
                                </TableCell>
                                <TableCell
                                    onMouseOver={e => handleMouseOver(question.id, 'name')} 
                                    onMouseOut={e => handleMouseOver(undefined, undefined)}
                                >                                
                                    {printName(question, 'name')}
                                </TableCell>
                                <TableCell
                                    onMouseOver={e => handleMouseOver(question.id, 'label')} 
                                    onMouseOut={e => handleMouseOver(undefined, undefined)}
                                >
                                    {printLabel(question, 'label')}
                                </TableCell>
                                <TableCell component="th" scope="row" style={{width: 80}}>
                                    {question.editable ? 
                                        <Checkbox color="primary" onChange={() => clickOnCheckboxExportable(question.id)} checked={question.exportable}/>
                                        : <Checkbox color="primary" disabled checked={question.exportable}/>
                                    }
                                </TableCell>
                                <TableCell align="left">
                                    {question.editable ? 
                                        <TextField
                                            value={question.width}
                                            onChange={(e) => changeWidth(index, e.target.value)}
                                            style={{width: 50}}
                                            type='number'
                                        /> : <Typography>{question.width}</Typography>
                                    }
                                </TableCell>
                                <TableCell align="left">
                                    {question.editable ? 
                                        <TextField
                                            value={question.startAt}
                                            onChange={(e) => changeStartAt(index, e.target.value)}
                                            style={{width: 50}}
                                            type='number'
                                        /> : <Typography>{question.startAt}</Typography>
                                    }
                                </TableCell>
                                <TableCell align="right"><Typography>{question.endAt}</Typography></TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};