import React, { useState, useEffect } from 'react';
import {toolbarStyles} from '../../common.js';
import clsx from 'clsx';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import CachedIcon from '@mui/icons-material/Cached';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import LinearProgress from '@mui/material/LinearProgress';
import StoreIcon from '@mui/icons-material/Store';
import TableSortLabel from '@mui/material/TableSortLabel';
import { RestClientService } from '../../services/RestClientService';
import EditResellersDialog from './EditResellersDialog';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import { useParams } from "react-router-dom";
import {LoadData} from '../../Constants.js'
import dateFormat from 'dateformat';
import {getItemAriaLabel} from '../../utils/pagination.js'
import {Helmet} from "react-helmet";

const restClient = RestClientService.getClient();

function createData(id, name, description, creationDateValue, updateDateValue) {
  const creationDate = new Date()
  creationDate.setTime(creationDateValue)
  const updateDate = new Date()
  updateDate.setTime(updateDateValue)
  return { id, name, description, creationDate, updateDate };
}

const headCells = [
  { id: 'name', label: 'react.resellers.table.header.name', minWidth: 170 },
  { id: 'description', label: 'react.resellers.table.header.description', minWidth: 200 },
  {
    id: 'creationDate',
    label: 'react.resellers.table.header.creationdate',
    minWidth: 170,
    align: 'right',
    format: value => dateFormat(value, "UTC:dd/mm/yyyy"),
  },
  {
    id: 'updateDate',
    label: 'react.resellers.table.header.updatedate',
    minWidth: 170,
    align: 'right',
    format: value => dateFormat(value, "UTC:dd/mm/yyyy"),
  },
];

function EnhancedTableHead(props) {

  const { t, classes, onSelectAllClick, numSelected, rowCount, sortBy, sortDirection, handleChangeSort } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            id='checkbox-all'
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.align}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={sortBy === headCell.id ? sortDirection : false}
          >
          <TableSortLabel
              active={sortBy === headCell.id}
              direction={sortDirection}
              onClick={e => handleChangeSort(e, headCell.id)}
            >
            {t(headCell.label)}
          {sortBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {sortDirection === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function EnhancedTableToolbar(props) {
  const { classes } = toolbarStyles();
  const { t, numSelected, countForPagination, handleDeleteResellers, handleEditReseller, setLoadData } = props;

  const handleClickRefresh = (event) => {
    event.preventDefault();
    setLoadData(LoadData.Load);
  };

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <StoreIcon className={classes.mainIcon}/>
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
        {t("react.resellerlist.number.of.selected", {'numSelected': numSelected})}
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          {t('react.resellerlist.title', {'size': countForPagination})}
        </Typography>
      )}
      {numSelected > 0 &&
          <Tooltip title={t('react.button.delete')}>
            <IconButton onClick={handleDeleteResellers} size="large">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
      }
      <Tooltip title={t('react.button.add')}>
        <IconButton onClick={(e) => handleEditReseller(e, 0)} size="large">
          <ControlPointIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('react.button.refresh')}>
        <IconButton onClick={handleClickRefresh} size="large">
          <CachedIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
}

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  tableRowCursorPointer : {
    cursor: 'pointer',
  },
}));

export default function ResellersList(props) {

  const {
    t,
    openSnackbar,
  } = props;

  // URL parameter
  const { resellerIdParameter } = useParams();

  const { classes } = useStyles();

  const [countForPagination, setCountForPagination] = useState(0);
  const [rows, setRows] = useState([]);
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [sortBy, setSortBy] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    event.preventDefault();
    setPage(newPage);
    setLoadData(LoadData.Load);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(+event.target.value);
    setPage(0);
    setLoadData(LoadData.Load);
  };

  const handleChangeSort = (event, headCellId) => {
    event.preventDefault();
    if(sortBy !== headCellId) {
      setSortBy(headCellId);
      setSortDirection('asc')
    } else {
      setSortDirection(sortDirection === 'desc' ? 'asc' : 'desc');
    }
    setLoadData(LoadData.Load);
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    restClient.get('/api/resellers/v1.0/?from=' + (page*rowsPerPage) + '&size=' + rowsPerPage + '&sort=' + sortBy+ '&order=' + sortDirection)
    .then(result => {
      setCountForPagination(result.data.total);

      const newRows = [];
      for(let i = 0; i < result.data.hits.length; i++) {
        let hit = result.data.hits[i];
        newRows.push(createData(hit.id, hit.name, hit.description, hit.creationDate, hit.updateDate));
      }
      setRows(newRows);

      if(resellerIdParameter > 0) {
        setEditDialogResellerId(resellerIdParameter);
        setEditDialogOpen(true);
      }
    })
    .catch(err => {
      setRows([]);
      setCountForPagination(0);
      openSnackbar('error', t("react.error.while.loading"));
    }).finally(() => {
      setLoadData(LoadData.Loaded);
    });
  }, [loadData, page, rowsPerPage, resellerIdParameter, sortBy, sortDirection]);

  const isSelected = id => selected.indexOf(id) !== -1;

  const [editDialogResellerId, setEditDialogResellerId] = useState(0);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const handleEditReseller = (event, resellerIdToEdit) => {
    event.preventDefault();
    setEditDialogResellerId(resellerIdToEdit);
    setEditDialogOpen(true);
  };
  const editDialogCallbackAfterConfirm = () => {
    setEditDialogOpen(false);
    if(resellerIdParameter) {
      props.navigate('/resellers');
    }
    setLoadData(LoadData.Load);
  };
  const editDialogCallbackAfterCancel = () => {
    setEditDialogOpen(false);
    if(resellerIdParameter) {
      props.navigate('/resellers');
    }
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteResellers = (event, row) => {
    event.preventDefault();
    setDeleteDialogOpen(true)
  };
  const handleDeleteDialogConfirm = () => {
    var promises = [];
    for(var i = 0; i < selected.length; i++) {
      promises.push(restClient.delete('/api/resellers/v1.0/' + selected[i]));
    }

    Promise.all(promises)
    .then(function(values) {
      setDeleteDialogOpen(false);
      setLoadData(LoadData.Load);
      setSelected([]);
    })
    .catch(err => {
      openSnackbar('error', t("react.resellerlist.error.while.deleting"));
    });
  };
  const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(false);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length);

  return (
    <div className={classes.root}>
      <Helmet title={t('react.helmet.resellerslist')} />
      { loadData !== LoadData.Loaded ? ( <LinearProgress /> )
      : (
      <Paper className={classes.paper}>
        <PopupTwoButtons
            variant='warning'
            openState={deleteDialogOpen}
            callbackOnclose={handleDeleteDialogCancel}
            callbackOnclickLeftButton={handleDeleteDialogCancel}
            callbackOnclickRightButton={handleDeleteDialogConfirm}
            title={t('react.resellerlist.confirm.delete.title')}
            content={t('react.resellerlist.confirm.delete.description')}
            leftButton={t('react.button.cancel')}
            rightButton={t('react.button.delete')}
            unlockCheckboxLabel={t('react.popuptwobuttons.delete.help.for.the.disabled')}
        />
        <EditResellersDialog
          {...props}
          resellerId={editDialogResellerId}
          openState={editDialogOpen}
          callbackAfterConfirm={editDialogCallbackAfterConfirm}
          callbackAfterCancel={editDialogCallbackAfterCancel}
        />
        <EnhancedTableToolbar
          {...props}
          numSelected={selected.length}
          countForPagination={countForPagination}
          handleDeleteResellers={handleDeleteResellers}
          handleEditReseller={handleEditReseller}
          setLoadData={setLoadData} />
        <div className={classes.tableWrapper}>
          <Table
            className={classes.table}
            size="medium"
          >
            <EnhancedTableHead
              {...props}
              classes={classes}
              numSelected={selected.length}
              onSelectAllClick={handleSelectAllClick}
              rowCount={rows.length}
              sortBy={sortBy}
              sortDirection={sortDirection}
              handleChangeSort={handleChangeSort}
            />
            <TableBody>
              {rows.map((row, index) => {
                  const isItemSelected = isSelected(row.id);

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                      className={classes.tableRowCursorPointer}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          id={`checkbox-${row.id}`}
                          onClick={event => handleClick(event, row.id)}
                          checked={isItemSelected}
                        />
                      </TableCell>
                      {headCells.map(column => {
                        const value = row[column.id];
                        return (
                          <TableCell key={column.id} align={column.align}
                            onClick={event => handleEditReseller(event, row.id)} >
                            {column.format  ? column.format(value) : value}
                          </TableCell>
                        );
                      })}
                        </TableRow>
                      );
                    })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </div>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={countForPagination}
          rowsPerPage={rowsPerPage}
          page={page}
          labelRowsPerPage={t('react.list.number.lignes.per.page')}
          labelDisplayedRows={({ from, to, count }) => t('react.list.number.from.to.count', {'from':from, 'to':to, 'count':count})}
          getItemAriaLabel={(type) => getItemAriaLabel(t, type)}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      ) }
    </div>
  );
}
