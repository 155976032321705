import React, { useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { useParams } from "react-router-dom";
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { makeStyles } from 'tss-react/mui';
import Grid from '@mui/material/Grid';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import SecurityIcon from '@mui/icons-material/Security';
import LinearProgress from '@mui/material/LinearProgress';
import Tooltip from '@mui/material/Tooltip';
import UserEditPassword from './UserEditPassword';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import { AuthService } from '../../services/AuthService';
import UserService from '../../services/UserService';
import TranslationService from '../../services/TranslationService';
import FormHelperText from '@mui/material/FormHelperText';
import { isValidEmail, isBlank } from '../../utils/utils';
import FormControl from '@mui/material/FormControl';
import CircularProgress from '@mui/material/CircularProgress';
import { LoadData } from '../../Constants.js';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import dateFormat from 'dateformat';
import CustomerService from '../../services/CustomerService';
import MenuItem from '@mui/material/MenuItem';
import {Helmet} from "react-helmet";

const translationService = new TranslationService();
const userService = new UserService();
const customerService = new CustomerService();

const useStyles = makeStyles()(theme => ({
  paper: {
    flexGrow: 1,
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
  },
  formControl: {
    minWidth: 120,
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  supervisedButton: {
    color: "#fff", 
    backgroundColor: "#ba000d",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:hover': {
      backgroundColor: "#e61e2c",
    },
  },
  supervisedButtonDisabled: {
    color: "#fff", 
    backgroundColor: "#ed9fa4",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
},
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  }
}));

export default function UserEdit(props) {

  const { showSpinner, openSnackbar, t } = props;

  // URL parameter
  const { userIdParameter } = useParams();

  const { classes } = useStyles();

  const [id, setId] = useState(0);
  const [uid, setUid] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirsname] = useState("");
  const [lastname, setLastname] = useState("");
  const [customerId, setCustomerId] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [preferredLanguage, setPreferredLanguage] = useState('none');
  const [supportedLanguages, setSupportedLanguages] = useState([]);

  // date are the number of milliseconds for this date since the epoch, which is defined as the midnight at the beginning of January 1, 1970, UTC.
  const [expirationDate, setExpirationDate] = useState(new Date());
  const [creationDate, setCreationDate] = useState(new Date());
  const [updateDate, setUpdateDate] = useState(new Date());

  const [rights, setRights] = useState([]);
  const [checkboxStates, setCheckboxStates] = useState({});
  const [saving, setSaving] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [displayErrors, setDisplayErrors] = useState({ emailLength: false, emailValidation: false, firstname: false, lastname: false, customerId: false, preferredLanguage: false });

  const handleClickEditDialogCancel = (event) => {
    event.preventDefault();
    props.navigate('/users');
  };

  const handleClickEditDialogConfirm = (data) => {
    let errorsInFields = {...displayErrors};
    errorsInFields.firstname = isBlank(firstname);
    errorsInFields.lastname = isBlank(lastname);
    errorsInFields.emailLength = isBlank(email);
    errorsInFields.emailValidation = !isValidEmail(email);
    errorsInFields.customerId = customerId === 0;
    errorsInFields.preferredLanguage = isBlank(preferredLanguage) || preferredLanguage === 'none';
    setDisplayErrors(errorsInFields);

    let nbErrors = Object.entries(errorsInFields).filter(value => value[1] === true).length;
    if (nbErrors > 0) {
      return;
    }

    let userBody = {
      'id': id,
      'uid': uid,
      'email': email,
      'firstname': firstname,
      'lastname': lastname,
      'password': null,
      'customerId': customerId,
      'preferredLanguage': preferredLanguage,
      'expirationDate': expirationDate,
      'creationDate': creationDate,
      'updateDate': updateDate,
    };

    let newRights = [];
    Object.keys(checkboxStates).forEach(cbs => {
      let newRight = {};
      newRight['name'] = cbs;
      newRight['has_role'] = checkboxStates[cbs];
      newRights.push(newRight);
    });

    setSaving(true);

    userService.saveUser(userBody, newRights)
      .then((results) => {
        let savedUser = results[0];
        if (savedUser.id === AuthService.getUser().id) {
          // if the saved used is 'me', I can update my new selected language to apply changes immediatly
          AuthService.updateCurrentUserPreferredLanguage(savedUser.preferredLanguage);
        }
        props.navigate('/users');
      })
      .catch(err => {
        if (err.response.status === 400 && err.response.data.message === 'Customer does not have active license') {
          openSnackbar('error', t("react.users.save.license.problem"));
        } else if (err.response.status === 409 && err.response.data.message !== undefined) {
          openSnackbar('error', err.response.data.message);
        } else {
          // display generic message
          openSnackbar('error', err.message);
        }
        setSaving(false);
      });
  };

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if (loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);
    setDisplayErrors({ emailLength: false, emailValidation: false, firstname: false, lastname: false, customerId: false, preferredLanguage: false });

    let promises = [
      translationService.getSupportedLanguages(),
      customerService.get500CustomersOrderByNameAsc(),
    ];

    if (userIdParameter > 0) {
      promises.push(userService.getUserRights(userIdParameter));
      promises.push(userService.getUser(userIdParameter));
    } else {
      promises.push(userService.getRights());
    }

    Promise.all(promises)
    .then(results => {
      setSupportedLanguages(results[0].data);
      setCustomers(results[1].data.hits);
      if (results[1].data.total > 200) {
        openSnackbar('error', t('react.useredit.warning.customers.maxlimit', { size: 200 }));
      }

      let newState = {};
      results[2].data.forEach(r => {
        newState[r.name] = r.has_role || false;
      });
      setRights(results[2].data);
      setCheckboxStates(newState);

      let user = userIdParameter > 0 ? results[3] : null;
      if (user) {
        setId(user.data.id);
        setUid(user.data.uid);
        setEmail(user.data.email);
        setFirsname(user.data.firstname);
        setLastname(user.data.lastname);
        setCustomerId(user.data.customerId);
        setPreferredLanguage(user.data.preferredLanguage.toUpperCase());
        setExpirationDate(user.data.expirationDate);
        setCreationDate(user.data.creationDate);
        setUpdateDate(user.data.updateDate);
      } else {
        setId(0);
        setUid('');
        setEmail('');
        setFirsname('');
        setLastname('');
        setCustomerId(0);
        setPreferredLanguage('none');

        let exp = new Date();
        exp.setFullYear(exp.getFullYear() + 1);
        setExpirationDate(exp.getTime());
      }
    })
    .catch(err => {
      openSnackbar('error', err.message);
    }).finally(() => {
      setLoadData(LoadData.Loaded);
    });
  }, [loadData, userIdParameter]);

  const handleCheckboxChange = (event, rightName) => {
    setCheckboxStates({ ...checkboxStates, [rightName]: event.target.checked });
  };

  const handleChangeEmailAndUid = (event) => {
    event.preventDefault();
    setEmail(event.target.value);
    setUid(event.target.value);
  };

  const [openPassword, setOpenPassword] = useState(false);
  const handlePasswordDialogCancel = () => {
    setOpenPassword(false);
  };
  const handlePasswordDialogConfirm = (newPassword) => {
    setChangingPassword(true);
    userService.setPassword(userIdParameter, newPassword)
      .then(() => {
        openSnackbar('success', t('react.reset.password.changed'));
        setOpenPassword(false);
      })
      .catch(err => {
        if (err.response.status === 400 && err.response.data.message === 'This password is not valid') {
          openSnackbar('error', t("react.users.changepassword.invalid"));
        } else {
          openSnackbar('error', t('react.reset.password.error', { reason: err.response.data.message }));
        }
      }).finally(() => {
        setChangingPassword(false);
      });
  };

  const handleSetExpirationDate = (event) => {
    event.preventDefault();

    if(isBlank(event.target.value)) return;

    let expDate = `${event.target.value}T23:59:59.000Z`;
    setExpirationDate(new Date(expDate).getTime());
  };

  const today = new Date().getTime();
  const isExpired = useMemo(() => {
    return expirationDate < today;
  }, [expirationDate]);

  // -- Special behavior to log as someone else ----------------------------------------------------

  const handleClickConnectAs = async (event) => {
    event.preventDefault();
    showSpinner(true);

    let nowInMillis = new Date().valueOf();
    AuthService.buildBearerAccessToken(new Date(nowInMillis + 3600000 /* 1 hour */), '*', 'onBehalf', userIdParameter)
      .then(result => {
        AuthService.signInBearer(result.data.token)
          .then(user => {
            if (user) {
              AuthService.markUserAsUnderSupervision();
              props.navigate('/');
            }
          })
          .catch(err => {
            openSnackbar('error', t('react.users.connect.as.error.with.token'));
          });
      })
      .catch(err => {
        openSnackbar('error', t('react.users.connect.as.error', { reason: err.response.data.message }));
      }).finally(() => {
        showSpinner(false);
      });
  };

  // -- End Special behavior to log as someone else ----------------------------------------------------

  if (loadData !== LoadData.Loaded) {
    return (
      <LinearProgress />
    );
  }

  const helmetTitle = id > 0 ? 
    `${firstname} ${lastname}` : 
    t('react.useredit.title.add');

  const pageTitle = id > 0 ? 
    t('react.useredit.title', { "firstname": firstname, "lastname": lastname }) : 
    t('react.useredit.title.add');

  return (
      <Paper className={classes.paper}>
        <Helmet title={helmetTitle} />
        <UserEditPassword
          {...props}
          open={openPassword}
          firstname={firstname}
          lastname={lastname}
          callbackAfterConfirm={handlePasswordDialogConfirm}
          callbackAfterCancel={handlePasswordDialogCancel}
          saving={changingPassword}
        />
        <Typography variant="h5" component="h3" paragraph>{pageTitle}</Typography>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <TextField
              autoFocus
              margin="dense"
              id="email"
              label={t('react.useredit.email')}
              type="text"
              value={email}
              onChange={handleChangeEmailAndUid}
              fullWidth
              className={classes.textField}
              name="email"
              error={displayErrors.emailLength || displayErrors.emailValidation}
              helperText={displayErrors.emailLength && t("react.validation.email.empty") || displayErrors.emailValidation && t("react.validation.email.invalid")}
            />
            <TextField
              margin="dense"
              id="firstname"
              label={t('react.useredit.firstname')}
              type="text"
              value={firstname}
              onChange={e => setFirsname(e.target.value)}
              fullWidth
              className={classes.textField}
              name="firstname"
              error={displayErrors.firstname}
              helperText={displayErrors.firstname && t("react.validation.firstname.empty")}
            />
            <TextField
              margin="dense"
              id="lastname"
              label={t('react.useredit.lastname')}
              type="text"
              value={lastname}
              onChange={e => setLastname(e.target.value ? e.target.value.toUpperCase() : "")}
              fullWidth
              className={classes.textField}
              name="lastname"
              error={displayErrors.lastname}
              helperText={displayErrors.lastname && t("react.validation.lastname.empty")}
            />
            <FormControl className={classes.formControl} error={displayErrors.customerId}>
              <TextField
                select
                value={customerId}
                label={t('react.useredit.client')}
                fullWidth
                onChange={e => setCustomerId(e.target.value)}
                name='customerId'
                id='customerId-simple'
              >
                <MenuItem key="0" value="0" disabled>{t('react.useredit.select.customer')}</MenuItem>
                {customers.map(customer => (
                  <MenuItem key={customer.id} value={customer.id} >
                    {customer.name}
                  </MenuItem>
                ))}
              </TextField>
              {displayErrors.customerId && <FormHelperText>{t("react.validation.customerId.empty")}</FormHelperText> }
            </FormControl>
            <FormControl className={classes.formControl} error={displayErrors.preferredLanguage}>
              <TextField
                select
                value={preferredLanguage}
                label={t('react.useredit.language')}
                onChange={e => setPreferredLanguage(e.target.value.toUpperCase())}
                name="preferredLanguage"
                id='preferredLanguage-native-simple'
                fullWidth
              >
                <MenuItem key="none" value="none" disabled>{t('react.useredit.select.language')}</MenuItem>
                {supportedLanguages.map(language => (
                  <MenuItem key={language.toUpperCase()} value={language.toUpperCase()} >
                    {language.toUpperCase()}
                  </MenuItem>
                ))}
              </TextField>
              {displayErrors.preferredLanguage &&  <FormHelperText>{t('react.validation.preferred.language.empty')}</FormHelperText> }
            </FormControl>
            <TextField
              margin="dense"
              id="expirationDate"
              label={t('react.useredit.expirationdate')}
              type="date"
              value={dateFormat(expirationDate, "UTC:yyyy-mm-dd")}
              onChange={e => handleSetExpirationDate(e)}
              disabled={!AuthService.isManager()}
              fullWidth
              InputLabelProps={{
                shrink: true,
              }}
              className={classes.textField}
            />
            <TextField
              margin="dense"
              id="creationDate"
              label={t('react.useredit.creationdate')}
              type="datetime-local"
              value={dateFormat(creationDate, "UTC:yyyy-mm-dd'T'HH:MM")}
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className={classes.textField}
            />
            <TextField
              margin="dense"
              id="updateDate"
              label={t('react.useredit.updatedate')}
              value={dateFormat(updateDate, "UTC:yyyy-mm-dd'T'HH:MM")}
              type="datetime-local"
              disabled={true}
              InputLabelProps={{
                shrink: true,
              }}
              fullWidth
              className={classes.textField}
            />
          </Grid>
          {rights.length > 0 &&
            <Grid item xs={6}>
              <Typography variant='h5'>
                {t('react.useredit.rights.title')}
              </Typography>
              <div className={classes.demo}>
                <List >
                  {rights.map(right => {
                    // can the current used modify rights of the user he his looking at ?
                    let modifiable = right.type === 'standard' ||
                      (right.type === 'manager' && AuthService.isManager()) ||
                      (right.type === 'admin' && AuthService.isAdmin()) ||
                      (right.type === 'superadmin' && AuthService.isSuperAdmin());
                    return (
                      <ListItem key={right.name}>
                        <Checkbox
                          checked={!!checkboxStates[right.name]}
                          onChange={event => handleCheckboxChange(event, right.name)}
                          value={right.name}
                          disabled={!modifiable}
                        />
                        <ListItemText
                          primary={t(`react.useredit.rights.valueof.${right.name}`)}
                        />
                      </ListItem>
                    );
                  })}
                </List>
              </div>
            </Grid>
          }
          <Grid item xs={9}>
            <Button
              variant="outlined"
              size="large"
              onClick={handleClickEditDialogCancel}
              color="primary"
              startIcon={<CloseIcon />}
              className={classes.button}
            >
              {t('react.button.cancel')}
            </Button>
            {AuthService.isManager() &&
              <Button
                variant="outlined"
                size="large"
                color="primary"
                startIcon={<SaveIcon />}
                className={classes.button}
                disabled={saving}
                onClick={handleClickEditDialogConfirm}
              >
                {t('react.button.save')}
                {saving && <CircularProgress size={24} className={classes.buttonProgress} />}
              </Button>
            }
            {id > 0 && id !== AuthService.getUser().id && AuthService.hasRightOfSupervision() &&
              <Button
                disabled={isExpired}
                variant="outlined"
                size="large"
                onClick={handleClickConnectAs}
                startIcon={<SupervisedUserCircleIcon />}
                className={isExpired ? classes.supervisedButtonDisabled : classes.supervisedButton}
              >
                {t('react.users.button.connect.as')}
              </Button>
            }
          </Grid>
          <Grid item xs={3} container justifyContent="flex-end">
            {id > 0 &&
              <Tooltip title={t('react.users.changepassword.button.tooltip')}>
                <Button
                  title=""
                  variant="outlined"
                  size="large"
                  onClick={event => setOpenPassword(true)}
                  startIcon={<SecurityIcon />}
                  className={classes.button}
                >
                  {t('react.users.changepassword.button')}
                </Button>
              </Tooltip>
            }
          </Grid>
        </Grid>
      </Paper>
  );
}
