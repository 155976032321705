import React, { useState, useEffect } from 'react';
import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { RestClientService } from '../../services/RestClientService';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Input from '@mui/material/Input';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import CodificationService from '../../services/CodificationService'
import { isBlank } from '../../utils/utils.js';
import VerticalSplitIcon from '@mui/icons-material/VerticalSplit';
import TranslateIcon from '@mui/icons-material/Translate';
import InputLabel from '@mui/material/InputLabel';
import { Select } from '@mui/material';
import {Helmet} from "react-helmet";

const restClient = RestClientService.getClient();
const codificationService = new CodificationService();

const useStyles = makeStyles()(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  textField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
}));

export default function AdminTools(props) {

  const { classes } = useStyles();

  const {
    t,
    showSpinner,
    openSnackbar,
  } = props;

  const [supportedLanguages, setSupportedLanguages] = useState([]);

  useEffect(() => {
    codificationService.getSupportedLanguages()
      .then(result => {
        setSupportedLanguages(result.data);
      })
      .catch(err => {
        openSnackbar('error', t("react.codification.error.while.loading"));
        setSupportedLanguages([]);
      });
  }, []);

  // check missing translations

  const [missingTranslationFile, setMissingTranslationFile] = useState({ name: "" });
  const [missingTranslationFromLang, setMissingTranslationFromLang] = useState('');
  const [missingTranslationToLang, setMissingTranslationToLang] = useState('');
  const handleSelectUploadFileMissingTranslation = (event) => {
    event.preventDefault();
    if (event.target.files.length === 1) {
      let fnlc = event.target.files[0].name.toLowerCase();
      if (fnlc.endsWith('.xlsx')) {
        setMissingTranslationFile(event.target.files[0]);
      } else {
        openSnackbar('error', "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement).");
      }
    }
  };
  const handleUploadFileMissingTranslation = (event) => {
    showSpinner(true);
    event.preventDefault();
    var formData = new FormData();
    formData.append("uploadedFile", missingTranslationFile);
    restClient.post(`/api/admin/tools/v1.0/codification/translations/missing?from=${missingTranslationFromLang}&to=${missingTranslationToLang}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    })
    .then(response => {
      openSnackbar('success', t("react.codification.toolbar.button.import.started"));
    })
    .catch(err => {
    }).finally(() => {
      showSpinner(false);
    });
  };

  // translate input file

  const [translateInputFile, setTranslateInputFile] = useState({ name: "" });
  const [translateInputFileFromLang, setTranslateInputFileFromLang] = useState('');
  const [translateInputFileToLang, setTranslateInputFileToLang] = useState('');
  const handleSelectUploadFileToTranslate = (event) => {
    event.preventDefault();
    if (event.target.files.length === 1) {
      let fnlc = event.target.files[0].name.toLowerCase();
      if (fnlc.endsWith('.xlsx')) {
        setTranslateInputFile(event.target.files[0]);
        // setMissingTranslationFileButtonDisabled(false);
      } else {
        openSnackbar('error', "Ce type de fichier n'est pas supporté (Fichiers xlsx seulement).");
      }
    }
  };
  const handleUploadFileToTranslate = (event) => {
    showSpinner(true);
    event.preventDefault();
    var formData = new FormData();
    formData.append("uploadedFile", translateInputFile);
    restClient.post(`/api/admin/tools/v1.0/codification/translate?from=${translateInputFileFromLang}&to=${translateInputFileToLang}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    })
    .then(response => {
      openSnackbar('success', t("react.codification.toolbar.button.import.started"));
    })
    .catch(err => {
    }).finally(() => {
      showSpinner(false);
    });
  };

  return (
    <Paper className={classes.paper}>
      <Helmet title={t('react.helmet.admintools')} />
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <VerticalSplitIcon style={{ marginRight: '10px' }}/><Typography className={classes.heading}>Rechercher des traductions manquantes</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid container item xs={6} spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="missingTranslationFromLang-label" variant='standard'>Langue d'origine</InputLabel>
                  <Select 
                    labelId="missingTranslationFromLang-label"
                    id="missingTranslationFromLang"
                    value={missingTranslationFromLang}
                    data-testid= "select-missing-translation-original-lang"
                    onChange={e => setMissingTranslationFromLang(e.target.value)}
                  >
                    {supportedLanguages.map(language => {
                      return (
                        <MenuItem key={language} value={language} data-value={language.toLowerCase()}>{language}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="missingTranslationToLang-label" variant='standard'>Langue de la traduction</InputLabel>
                  <Select 
                    labelId="missingTranslationToLang-label"
                    id="missingTranslationToLang"
                    value={missingTranslationToLang}
                    data-testid="select-missing-translation-lang"
                    onChange={e => setMissingTranslationToLang(e.target.value)}
                  >
                    {supportedLanguages.map(language => {
                      return (
                        <MenuItem key={language} value={language} data-value={language.toLowerCase()}>{language}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>Choisir un fichier de réponses pour y chercher les traductions manquantes</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input
                  disabled
                  id="missingTranslationFilename"
                  value={missingTranslationFile.name}
                  className={classes.textField}
                  placeholder="Sélectionner un fichier"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <label htmlFor="contained-button-file_3">
                        <Button variant="contained" color="primary" component="span">
                          Choisir
                        </Button>
                      </label>
                    </InputAdornment>
                  }
                />
                <input
                  onChange={handleSelectUploadFileMissingTranslation}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: 'none' }}
                  id="contained-button-file_3"
                  data-testid="upload-file-input-to-research"
                  type="file"
                />
              </FormControl>
              <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={handleUploadFileMissingTranslation}
                  data-testid="button-to-research"
                  disabled={isBlank(missingTranslationFile.name) || isBlank(missingTranslationFromLang) || isBlank(missingTranslationToLang) || missingTranslationFromLang === missingTranslationToLang}>
                  Envoyer
                </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          id="panel1a-header"
        >
          <TranslateIcon style={{ marginRight: '10px' }}/><Typography className={classes.heading}>Traduire un fichier de réponses</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={3}>
            <Grid container item xs={6} spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="translateInputFileFromLang-label" variant='standard'>Langue d'origine</InputLabel>
                  <Select 
                    labelId="translateInputFileFromLang-label"
                    id="translateInputFileFromLang"
                    value={translateInputFileFromLang}
                    data-testid= "select-translation-original-lang"
                    onChange={e => setTranslateInputFileFromLang(e.target.value)}
                  >
                    {supportedLanguages.map(language => {
                      return (
                        <MenuItem key={language} value={language}>{language}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="translateInputFileToLang-label" variant='standard'>Langue de la traduction</InputLabel>
                  <Select 
                    labelId="translateInputFileToLang-label"
                    id="translateInputFileToLang"
                    value={translateInputFileToLang}
                    data-testid= "select-translation-lang"
                    onChange={e => setTranslateInputFileToLang(e.target.value)}
                  >
                    {supportedLanguages.map(language => {
                      return (
                        <MenuItem key={language} value={language}>{language}</MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography>Choisir un fichier d'input à traduire</Typography>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Input
                  disabled
                  id="translateInputFileName"
                  value={translateInputFile.name}
                  className={classes.textField}
                  placeholder="Sélectionner un fichier"
                  fullWidth
                  endAdornment={
                    <InputAdornment position="end">
                      <label htmlFor="contained-button-file_4">
                        <Button variant="contained" color="primary" component="span">
                          Choisir
                        </Button>
                      </label>
                    </InputAdornment>
                  }
                />
                <input
                  onChange={handleSelectUploadFileToTranslate}
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                  style={{ display: 'none' }}
                  data-testid="upload-file-input-to-translate"
                  id="contained-button-file_4"
                  type="file"
                />
                </FormControl>
                <Button
                  variant="contained"
                  className={classes.button}
                  startIcon={<CloudUploadIcon />}
                  onClick={handleUploadFileToTranslate}
                  data-testid="button-to-translate"
                  disabled={isBlank(translateInputFile.name) || isBlank(translateInputFileFromLang) || isBlank(translateInputFileToLang) || translateInputFileFromLang === translateInputFileToLang}>
                  Envoyer
                </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Paper>
  );
};
