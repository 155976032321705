import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { RestClientService } from '../../services/RestClientService';
import SaveIcon from '@mui/icons-material/Save';
import CloseIcon from '@mui/icons-material/Close';
import dateFormat from 'dateformat';
import { isBlank } from '../../utils/utils';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

const restClient = RestClientService.getClient();

export default function EditResellersDialog(props) {
  const {
    t,
    openState,
    resellerId,
    callbackAfterConfirm,
    callbackAfterCancel
  } = props;

  const [id, setId] = useState(0);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [creationDate, setCreationDate] = useState(new Date());
  const [updateDate, setUpdateDate] = useState(new Date());
  const [displayErrorName, setDisplayErrorName] = useState(false);

  const handleClickEditDialogCancel = (event) => {
    event.preventDefault();
    callbackAfterCancel();
  };

  const handleClickEditDialogConfirm = () => {
    let err = isBlank(name);
    setDisplayErrorName(err);
    if (err) return;

    let body = {
      'id': id,
      'name': name,
      'description': description,
      'creationDate': creationDate,
      'updateDate': updateDate,
    };

    if (id === 0) {
      restClient.post('/api/resellers/v1.0/', body)
        .then(result => {
          callbackAfterConfirm();
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      restClient.put('/api/resellers/v1.0/' + id, body)
        .then(result => {
          callbackAfterConfirm();
        })
        .catch(err => {
          console.error(err);
          callbackAfterConfirm();
        });
    }
  };

  useEffect(() => {
    if (!openState) return;

    setDisplayErrorName(false);
    if (resellerId > 0) {
      restClient.get('/api/resellers/v1.0/' + resellerId)
        .then(result => {
          setId(result.data.id);
          setName(result.data.name);
          setDescription(result.data.description);
          setCreationDate(result.data.creationDate);
          setUpdateDate(result.data.updateDate);
        })
        .catch(err => {
          console.error(err);
        });
    } else {
      setId(0);
      setName("");
      setDescription("");
      setCreationDate("");
      setUpdateDate("");
    }
  }, [resellerId, openState]);

  return (
    <Dialog open={openState} onClose={handleClickEditDialogCancel}>
      {id > 0 ?
        <DialogTitleWithCloseIcon
          title={t('react.reselleredit.title.modify', { "name": name, "id": id })}
          callbackOnclose={handleClickEditDialogCancel}
        />
        : <DialogTitleWithCloseIcon
          title={t('react.reselleredit.title.new')}
          callbackOnclose={handleClickEditDialogCancel}
        />
      }
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          name="name"
          label={t('react.reselleredit.name')}
          type="text"
          value={name}
          autoComplete="off"
          onChange={e => setName(e.target.value)}
          fullWidth
          error={displayErrorName}
          helperText={displayErrorName && t("react.validation.name.empty")}
        />
        <TextField
          margin="dense"
          id="description"
          label={t('react.reselleredit.description')}
          type="text"
          value={description}
          onChange={e => setDescription(e.target.value)}
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          id="creationDate"
          label={t('react.reselleredit.creationdate')}
          type="datetime-local"
          value={dateFormat(creationDate, "UTC:yyyy-mm-dd'T'HH:MM")}
          disabled={true}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <TextField
          margin="dense"
          id="updateDate"
          label={t('react.reselleredit.updatedate')}
          value={dateFormat(updateDate, "UTC:yyyy-mm-dd'T'HH:MM")}
          type="datetime-local"
          disabled={true}
          InputLabelProps={{
            shrink: true,
          }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          size="large"
          startIcon={<CloseIcon />}
          onClick={handleClickEditDialogCancel} color="primary">
          {t('react.button.cancel')}
        </Button>
        <Button
          variant="outlined"
          size="large"
          startIcon={<SaveIcon />}
          color="primary"
          onClick={handleClickEditDialogConfirm}>
          {t('react.button.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
