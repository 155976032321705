import React, { useState, useEffect, useMemo } from 'react';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import {toolbarStyles} from '../../common.js';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { withStyles } from 'tss-react/mui';
import Tooltip from '@mui/material/Tooltip';
import CachedIcon from '@mui/icons-material/Cached';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ProjectService from '../../services/ProjectService';
import Checkbox from '@mui/material/Checkbox';
import LayersIcon from '@mui/icons-material/Layers';
import {LoadData} from '../../Constants.js'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { isBlank, indexOf } from '../../utils/utils.js';
import InputAdornment from '@mui/material/InputAdornment';
import DoneIcon from '@mui/icons-material/Done';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import Box from '@mui/material/Box';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Trans } from 'react-i18next';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import WarningIcon from '@mui/icons-material/Warning';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { GrAggregate } from "react-icons/gr";
import MoreIcon from '@mui/icons-material/More';
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

const projectService = new ProjectService();

const BaseLineTableCell = withStyles(TableCell, {
    root: {
        verticalAlign: 'baseline',
    }
});

export default function PanelFilters(props) {

    const { t, project, openSnackbar, showSpinner } = props;

    const { classes: toolbarClasses } = toolbarStyles();

    const [filters, setFilters] = useState([]);
    const [selected, setSelected] = useState([]);
    const [destinationId, setDestinationId] = useState(0);
    const [openSelectMergeDestination, setOpenSelectMergeDestination] = useState(false);
    const [renameIdentifier, setRenameIdentifier] = useState(undefined);
    const [renameText, setRenameText] = useState(undefined);
    const [mouseOverIdentifier, setMouseOverIdentifier] = useState(undefined);
    const [expandedIds, setExpandedIds] = useState(new Set());
    const [selectedValues, setSelectedValues] = useState([]);
    const [selectedValuesParent, setSelectedValuesParent] = useState(0);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [compoundDialogOpen, setCompoundDialogOpen] = useState(false);
    const [changeDisplayTypeIdentifier, setChangeDisplayTypeIdentifier] = useState(undefined);

    const resetAllFields = (resetFilters = false, keepExpanded = false, keepSelected = false, keepSelectedValues = false) => {
        if(resetFilters) setFilters([]);
        if(!keepSelected) setSelected([]);
        setDestinationId(0);
        setOpenSelectMergeDestination(false);
        setRenameIdentifier(undefined);
        setRenameText(undefined);
        setMouseOverIdentifier(undefined);
        if(!keepExpanded) setExpandedIds(new Set());
        if(!keepSelectedValues) {
            setSelectedValues([]);
            setSelectedValuesParent(0);
        }
        setDeleteDialogOpen(false);
        setCompoundDialogOpen(false);
        setChangeDisplayTypeIdentifier(undefined);
    }

    const [loadData, setLoadData] = useState(LoadData.Load);
    useEffect(() => {
        if(loadData !== LoadData.Load) return;

        setLoadData(LoadData.Loading);

        projectService.getProjectFilters(project.id)
            .then(result => {
                setFilters(result.data);
                setSelected([]);
                setLoadData(LoadData.Loaded);
            })
            .catch(err => {
                setFilters([]);
                setSelected([]);
                setLoadData(LoadData.Loaded);
                openSnackbar('error', t('react.generic.error.while.loading'));
            });
    }, [project, loadData]);

    const handleSelect = (event, id) => {
        event.preventDefault();

        const newSelected = [...selected];
        const selectedIndex = newSelected.indexOf(id);
        if(selectedIndex >= 0) {
            newSelected.splice(selectedIndex, 1);
        } else {
            newSelected.push(id);
        }

        setSelected(newSelected);
        setSelectedValues([]);
    };

    const handleRefresh = (event) => {
        event.preventDefault();

        resetAllFields(true);

        setLoadData(LoadData.Load);
    };

    const deleteSelectedCallback = () => {
        setDeleteDialogOpen(false);

        if(selected.length > 0) {
            // do live modifications
            for (const idx in selected) {
                const pos = filters.findIndex(f => f.id === selected[idx]);
                filters.splice(pos, 1);
            }

            resetAllFields();

            projectService.deleteFilters(project.id, selected)
                .catch(err => {
                    resetAllFields(true, true, true);
                    openSnackbar('error', t('react.generic.error.while.deleting'));
                });
        } else if (selectedValues.length > 0) {
            // do live modifications
            const filterPos = filters.findIndex(f => f.id === selectedValuesParent);
            for (const idx in selectedValues) {
                const pos = filters[filterPos].values.findIndex(v => v.id === selectedValues[idx]);
                if(pos >= 0) {
                    filters[filterPos].values.splice(pos, 1);
                }
            }

            resetAllFields(false, true);

            projectService.deleteFilterValues(project.id, selectedValues)
                .catch(err => {
                    resetAllFields(true, true, false, true);
                    openSnackbar('error', t('react.generic.error.while.deleting'));
                });
        }
    }

    const handleSetHidden = (hidden) => {
        if(selected.length > 0) {
            // do live modifications
            for (const idx in selected) {
                const pos = filters.findIndex(f => f.id === selected[idx]);
                filters[pos].hidden = hidden;
            }

            resetAllFields(false, true, true);

            projectService.setFiltersHidden(project.id, selected, hidden)
                .catch(err => {
                    resetAllFields(true, true, true);
                    openSnackbar('error', t('react.generic.error.while.saving'));
                });
        } else if (selectedValues.length > 0) {
            // do live modifications
            const filterPos = filters.findIndex(f => f.id === selectedValuesParent);
            for (const idx in selectedValues) {
                const pos = filters[filterPos].values.findIndex(v => v.id === selectedValues[idx]);
                if(pos >= 0) {
                    filters[filterPos].values[pos].hidden = hidden;
                }
            }

            resetAllFields(false, true, false, true);

            projectService.setFilterValuesHidden(project.id, selectedValues, hidden)
                .catch(err => {
                    resetAllFields(true, true, false, true);
                    openSnackbar('error', t('react.generic.error.while.saving'));
                });
        }        
    };

    // -- merge filters -----------------------------------------------------------

    const handleOpenSelectDestination = (event) => {
        event.preventDefault();
        setDestinationId(0);
        setOpenSelectMergeDestination(true);
    };
    const handleCloseSelectDestination = (event) => {
        event.preventDefault();
        setDestinationId(0);
        setOpenSelectMergeDestination(false);
    };

    const handleValidMergeFilters = (event) => {
        event.preventDefault();

        setOpenSelectMergeDestination(false);

        if(selected.length > 0) {
            // we need at least 1 selected filter to be moved into another filter
            const inFilterId = parseInt(destinationId);
            const filterIds = selected
                .map(id => parseInt(id))
                .filter(id => id !== inFilterId);

            showSpinner(true);

            projectService.mergeFilters(project.id, filterIds, inFilterId)
                .then(result => {
                    resetAllFields(true)    
                    setLoadData(LoadData.Load);
                })
                .catch(err => {
                    openSnackbar('error', t('react.generic.error.while.saving'));
                })
                .finally(() => {
                    setDestinationId(0);
                    showSpinner(false);
                });
        } else if (selectedValues.length > 1) {
            // we need at least 2 selected values to be merge together
            const inFilterValueId = parseInt(destinationId);
            const filterValueIds = selectedValues
                .map(id => parseInt(id))
                .filter(id => id !== inFilterValueId);
    
            showSpinner(true);

            projectService.mergeFilterValues(project.id, filterValueIds, inFilterValueId)
                .then(result => {
                    resetAllFields(true, true);
                    setLoadData(LoadData.Load);
                })
                .catch(err => {
                    openSnackbar('error', t('react.generic.error.while.saving'));
                })
                .finally(() => {
                    setDestinationId(0);
                    showSpinner(false);
                });
        }
    };

    // -- rename filter -----------------------------------------------------------

    const handleStartRename = (event, identifier, initalText) => {
        event.preventDefault();

        setRenameIdentifier(identifier);
        setRenameText(initalText);

        setMouseOverIdentifier(undefined);
    };

    const handleClickAway = (event) => {
        event.preventDefault();

        setRenameIdentifier(undefined);
        setRenameText(undefined);
        setMouseOverIdentifier(undefined);
        setChangeDisplayTypeIdentifier(undefined);
    };

    const handleValidRename = (event, type, id) => {
        event.preventDefault();

        if(type === 'filter') {
            // do live modifications
            filters.filter(f => f.id === id)[0].name = renameText;

            resetAllFields(false, true, true);

            projectService.renameFilter(project.id, id, renameText)
                .catch(err => {
                    resetAllFields(true, true, true);
                    openSnackbar('error', t('react.generic.error.while.saving'));
                });
        } else if (type === 'filterValue') {
            // do live modifications
            filters.map(f => f.values)
                .flat()
                .filter(fv => fv.id === id)[0].value = renameText;

            resetAllFields(false, true, false, true);

            projectService.renameFilterValue(project.id, id, renameText)
                .catch(err => {
                    resetAllFields(true, true, false, true);
                    openSnackbar('error', t('react.generic.error.while.saving'));
                });
            }
    };

    const handleMouseOver = (identifier) => {
        setMouseOverIdentifier(identifier);
    };

    // -- folding -----------------------------------------------------------

    const handleExpand = (event, filter) => {
        event.preventDefault();

        var newExpandedIds = new Set(expandedIds);
        newExpandedIds.add(filter.id);
        setExpandedIds(newExpandedIds);
    };

    const handleFold = (event, filter) => {
        event.preventDefault();

        var newExpandedIds = new Set(expandedIds);
        newExpandedIds.delete(filter.id);
        setExpandedIds(newExpandedIds);
    };

    const printFilter = (filter) => {
        const identifier = `f-${filter.id}`;

        if(renameIdentifier === identifier) {
            return (<ClickAwayListener onClickAway={handleClickAway}>
                <TextField
                    id={identifier}
                    type="text"
                    value={renameText}
                    onChange={e => setRenameText(e.target.value)}
                    fullWidth={true}
                    error={isBlank(renameText)}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title={t("react.project.tab.filters.rename.save")}><span>
                                <IconButton
                                    onClick={(e) => handleValidRename(e, 'filter', filter.id)}
                                    disabled={isBlank(renameText)}
                                    size="large">
                                    <DoneIcon style={{ color : !isBlank(renameText) ? '#66bb6a' : '#eee'}} />
                                </IconButton></span>
                            </Tooltip>
                        </InputAdornment>
                        )
                    }}
                /></ClickAwayListener>
            );
        }

        // default behavior
        return (<Box 
            sx={{verticalAlign: 'middle'}}
            onMouseOver={e => handleMouseOver(identifier)} 
            onMouseOut={e => handleMouseOver(undefined)}>
                {filter.name} <Tooltip title={t('react.project.tab.filters.rename.tooltip')}>
                        <IconButton onClick={(e) => handleStartRename(e, identifier, filter.name)}>
                            <EditOutlinedIcon fontSize="small" sx={{verticalAlign: 'sub', display: mouseOverIdentifier === identifier ? 'inline-flex': 'none'}} />
                        </IconButton>
                    </Tooltip>
        </Box>);
    };

    // -- Move -----------------------------------------------------------

    const handleMoveSelectionUp = () => {
        if(selected.length > 0) handleMoveFilterUp();
        else if (selectedValues.length > 0) handleMoveFilterValueUp();
    }

    const handleMoveSelectionDown = () => {
        if(selected.length > 0) handleMoveFilterDown();
        else if (selectedValues.length > 0) handleMoveFilterValueDown();
    }

    const handleMoveFilterUp = () => {
        // do live modifications
        let newFilters = [...filters];
        const impactedFilters = newFilters.filter(f => selected.includes(f.id));
        let indexOfFirstToMoveUp = indexOf(newFilters, 'id', impactedFilters[0].id);

        if(indexOfFirstToMoveUp > 0) {
            impactedFilters.forEach(element => {
                const currentIndex = indexOf(newFilters, 'id', element.id);
                newFilters.splice(currentIndex, 1);
                newFilters.splice(currentIndex - 1, 0, element);
            });
        }

        setFilters(newFilters);
        resetAllFields(false, true, true);

        projectService.setFilterPositions(project.id, newFilters.map(f => f.id))
            .catch(err => {
                resetAllFields(true, true, false, true);
                openSnackbar('error', t('react.generic.error.while.saving'));
            });
    };

    const handleMoveFilterDown = () => {
        // do live modifications

        // NOTE we reverse all before and after (it's easier to manipulate)
        let newFilters = [...filters.toReversed()]
        const impactedFilters = newFilters.filter(f => selected.includes(f.id));

        let indexOfFirstToMoveUp = indexOf(newFilters, 'id', impactedFilters[0].id);
        if(indexOfFirstToMoveUp > 0) {
            impactedFilters.forEach(element => {
                const currentIndex = indexOf(newFilters, 'id', element.id);
                newFilters.splice(currentIndex, 1);
                newFilters.splice(currentIndex - 1, 0, element);
            });
        }

        const reorderedFilters = newFilters.toReversed();

        setFilters(reorderedFilters);
        resetAllFields(false, true, true);

        projectService.setFilterPositions(project.id, reorderedFilters.map(fv => fv.id))
            .catch(err => {
                resetAllFields(true, true, false, true);
                openSnackbar('error', t('react.generic.error.while.saving'));
            });
    };

    const handleMoveFilterValueUp = () => {
        // do live modifications
        const pos = filters.findIndex(f => f.id === selectedValuesParent)
        const newValues = [...filters[pos].values];

        const impactedFilterValues = newValues.filter(v => selectedValues.includes(v.id));

        let indexOfFirstToMoveUp = indexOf(newValues, 'id', impactedFilterValues[0].id);
        if(indexOfFirstToMoveUp > 0) {
            impactedFilterValues.forEach(element => {
                const currentIndex = indexOf(newValues, 'id', element.id);
                newValues.splice(currentIndex, 1);
                newValues.splice(currentIndex - 1, 0, element);
            });
        }

        filters[pos].values = newValues;

        resetAllFields(false, true, false, true);

        projectService.setFilterValuesOrder(project.id, filters[pos].id, newValues.map(fv => fv.id))
            .catch(err => {
                resetAllFields(true, true, false, true);
                openSnackbar('error', t('react.generic.error.while.saving'));
            });
    };

    const handleMoveFilterValueDown = () => {
        // do live modifications
        const pos = filters.findIndex(f => f.id === selectedValuesParent)

        // NOTE we reverse all before and after (it's easier to manipulate)
        const newValues = [...filters[pos].values.toReversed()];
        const impactedFilterValues = newValues.filter(v => selectedValues.includes(v.id));

        let indexOfFirstToMoveUp = indexOf(newValues, 'id', impactedFilterValues[0].id);
        if(indexOfFirstToMoveUp > 0) {
            impactedFilterValues.forEach(element => {
                const currentIndex = indexOf(newValues, 'id', element.id);
                newValues.splice(currentIndex, 1);
                newValues.splice(currentIndex - 1, 0, element);
            });
        }

        // reorder values before the set
        const reorderedValues = newValues.toReversed();

        filters[pos].values = reorderedValues;

        resetAllFields(false, true, false, true);

        projectService.setFilterValuesOrder(project.id, filters[pos].id, reorderedValues.map(fv => fv.id))
            .catch(err => {
                resetAllFields(true, true, false, true);
                openSnackbar('error', t('react.generic.error.while.saving'));
            });        
    };

    // -- Create compound -----------------------------------------------------------

    const hasCompoundSelected = useMemo(() => {
        return filters.map(f => f.values).flat()
            .filter(v => selectedValues.includes(v.id))
            .filter(v => v.compound).length > 0;
    }, [filters, selectedValues]);

    const createCompoundCallback = () => {
        const impactedFilter = filters.filter(f => f.values.filter(v => selectedValues.includes(v.id)).length > 0)[0];

        const actions =  [
        {
            "action": "createCompoundFilterValue", 
            "filterId": impactedFilter.id, 
            "filterValueIds": selectedValues,
            "order": (impactedFilter.values.length + 1)
        }];

        projectService.applyFilterActions(project.id, actions)
            .then(result => {
                resetAllFields(true, true);
                setLoadData(LoadData.Load);
            })
            .catch(err => {
                openSnackbar('error', t('react.generic.error.while.saving'));
            })
            .finally(() => {
                setDestinationId(0);
                showSpinner(false);
            });
    };

    // -- filter values -----------------------------------------------------------

    const handleSelectValue = (event, filterId, filterValueId) => {
        event.preventDefault();

        // NOTE: it's not possible to select values from multiple filter

        let newSelectedValues = undefined;

        if(selectedValuesParent !== filterId) {
            newSelectedValues = [filterValueId];
        } else {
            newSelectedValues = [...selectedValues];
            const index = newSelectedValues.indexOf(filterValueId);
            if(index >= 0) {
                newSelectedValues.splice(index, 1);
            } else {
                newSelectedValues.push(filterValueId);
            }
        }

        // always disable filter checkbox
        setSelected([]);
        setSelectedValuesParent(filterId);
        setSelectedValues(newSelectedValues);
    };

    const printFilterValues = (filter) => {
            return (
            <Collapse in={expandedIds.has(filter.id)} unmountOnExit>
                <List disablePadding dense>
                {filter.values.map((v, idx) => {
                    return (
                        <ListItem key={v.id} disablePadding dense>
                            <ListItemIcon style={{ paddingLeft: '8px', paddingBottom: 5 }}>
                                <Typography>{idx + 1}</Typography>
                            </ListItemIcon>
                            <Tooltip title={v.compound === false ? "" :  t('react.dashboard.topicsdialog.tooltip.disabled.chebox')}>
                            <Checkbox
                                checked={selectedValues.includes(v.id)}
                                onChange={(e) => handleSelectValue(e, filter.id, v.id)}
                                sx={{
                                    color: v.compound ? "#fb9d12" : "primary",
                                    '&.Mui-checked': {
                                        color: v.compound ? "#fb9d12" : "primary",
                                    }}}
                            />
                            </Tooltip>
                            <Box style={{display:'flex', justifyContent:'flex-end', width: '100%'}}>
                                <Box sx={{width: '100%'}}>{printFilterValue(v)}</Box>
                                <Box sx={{width: '250px', textAlign:'center'}}>{v.hidden ? t('react.project.tab.filters.hidden') : t('react.project.tab.filters.visible')}</Box>
                                <Box sx={{width: '125px', display:'flex', flexFlow:'column'}}>{getFilterValueAlerts(v)}</Box>
                            </Box>
                        </ListItem >
                        )})}
            </List></Collapse>)
    };

    const printFilterValue = (filterValue) => {
        const identifier = `v-${filterValue.id}`;

        if(renameIdentifier === identifier) {
            return (<ClickAwayListener onClickAway={handleClickAway}>
                <TextField
                    id={identifier}
                    type="text"
                    value={renameText}
                    onChange={e => setRenameText(e.target.value)}
                    fullWidth={true}
                    sx={{ m: 1, width: '40ch' }}
                    error={isBlank(renameText)}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <Tooltip title={t("react.project.tab.filters.rename.save")}><span>
                                <IconButton
                                    onClick={(e) => handleValidRename(e, 'filterValue', filterValue.id)}
                                    disabled={isBlank(renameText)}
                                    size="large">
                                    <DoneIcon style={{ color : !isBlank(renameText) ? '#66bb6a' : '#eee'}} />
                                </IconButton></span>
                            </Tooltip>
                        </InputAdornment>
                        )
                    }}
                /></ClickAwayListener>
            );
        }

        // default behavior
        return (
            <Box 
                sx={{verticalAlign: 'middle', height: '36px', lineHeight:'36px'}}
                onMouseOver={e => handleMouseOver(identifier)} 
                onMouseOut={e => handleMouseOver(undefined)}
            >
                {filterValue.value} 
                <Tooltip title={t('react.project.tab.filters.rename.tooltip')}>
                    <IconButton onClick={(e) => handleStartRename(e, identifier, filterValue.value)}>
                        <EditOutlinedIcon fontSize="small" sx={{verticalAlign: 'sub', display: mouseOverIdentifier === identifier ? 'inline-flex': 'none'}} />
                    </IconButton>
                </Tooltip>
            </Box>);
    };

    const getFilterAlerts = (filter) => {
        const nbStimulusUnderLowBase = filter.values
            .map(v => Object.values(v.frequencies_per_stimulus_id))
            .flat()
            .filter(fpsv => fpsv < 15).length;

        if(nbStimulusUnderLowBase > 0) {
            return (
                <Tooltip title={t('react.dashboard.topicsdialog.warning.message')}>
                    <IconButton size='small'>
                        <WarningIcon sx= {{color: '#fb9d12'}}/>
                    </IconButton>
                </Tooltip>)
        }

        return <VerifiedOutlinedIcon fontSize='small' sx= {{color: '#bde3b3', verticalAlign: 'middle'}} />;
    };

    const getFilterValueAlerts = (filterValue) => {
        const nbStimulusUnderLowBase = Object.values(filterValue.frequencies_per_stimulus_id)
            .filter(fpsv => fpsv < 15).length;

        if(nbStimulusUnderLowBase > 0) {
            return (
                <Tooltip title={t("react.dashboard.topicsdialog.lowbase.message", { 'numberOfStimuli': nbStimulusUnderLowBase, 'valueSlider': 15 })}>
                    <IconButton size='small'>
                        <WarningIcon sx= {{color: '#fb9d12'}}/>
                    </IconButton>
                </Tooltip>)
        }

        return null;
    };

    // -- filter values -----------------------------------------------------------

    const selectUnselectAll = (event) => {
        event.preventDefault();

        if(selectedValuesParent > 0 && selectedValues.length > 0) {
            // select / unselect values
            const filterValues = filters.filter(f => f.id === selectedValuesParent)[0].values;
            if(filterValues.length === selectedValues.length) {
                // everything is selected, so unselect all values
                setSelectedValues([]);
                setSelectedValuesParent(0);
            } else {
                setSelectedValues(filterValues.map(fv => fv.id));
            }
            setSelected([]);
        } else {
            let newSelected = filters.map(f => f.id);
            if(selected.length < newSelected.length) {
                setSelected(newSelected);
            } else {
                setSelected([]);
            }
            setSelectedValues([]);
            setSelectedValuesParent(0);
        }
    };

    // -- Change display Type -----------------------------------------------------------

    const handleSelectDisplayType = (event, filter) => {
        event.preventDefault();

        if(event.target.value === filter.displayType) {
            setChangeDisplayTypeIdentifier(undefined);
            return;
        }

        // do live modifications
        const filterPos = filters.findIndex(f => f.id === filter.id);
        filters[filterPos].displayType = event.target.value;

        resetAllFields(false, true, true, true);

        projectService.setFilterDisplayType(project.id, filter.id, event.target.value)
            .catch(err => {
                resetAllFields(true, true, true, true);
                openSnackbar('error', t('react.generic.error.while.saving'));
            });

        setChangeDisplayTypeIdentifier(undefined);
    };

    const printDisplayType = (filter) => {
        const identifier = `fbt-${filter.id}`;

        if(changeDisplayTypeIdentifier === identifier) {
            return (<ClickAwayListener onClickAway={handleClickAway}>
                <Select
                    key={identifier}
                    value={filter.displayType}
                    onChange={(e) => handleSelectDisplayType(e, filter)}
                    MenuProps={{ disablePortal: true }}
                >
                    <MenuItem value={'AREA'}>{t(`react.dashboard.topicsdialog.chart.name.area`)}</MenuItem>
                    <MenuItem value={'AREA_STACK'}>{t(`react.dashboard.topicsdialog.chart.name.area.stack`)}</MenuItem>
                    <MenuItem value={'BAR'}>{t(`react.dashboard.topicsdialog.chart.name.bar`)}</MenuItem>
                    <MenuItem value={'BAR_STACK'}>{t(`react.dashboard.topicsdialog.chart.name.bar.stack`)}</MenuItem>
                    <MenuItem value={'LINE'}>{t(`react.dashboard.topicsdialog.chart.name.line`)}</MenuItem>
                </Select></ClickAwayListener>
            );
        }

        let lblKey = '';
        switch (filter.displayType) {
            case 'AREA':
                lblKey = 'react.dashboard.topicsdialog.chart.name.area'
                break;
            case 'AREA_STACK':
                lblKey = 'react.dashboard.topicsdialog.chart.name.area.stack'
                break;
            case 'BAR':
                lblKey = 'react.dashboard.topicsdialog.chart.name.bar'
                break;
            case 'BAR_STACK':
                lblKey = 'react.dashboard.topicsdialog.chart.name.bar.stack'
                break;
            case 'LINE':
                lblKey = 'react.dashboard.topicsdialog.chart.name.line'
                break;
            default:
                break;
        }

        // default behavior
        return (<Box 
            sx={{verticalAlign: 'middle'}}
            onMouseOver={e => handleMouseOver(identifier)} 
            onMouseOut={e => handleMouseOver(undefined)}>
                {t(lblKey)} <Tooltip title={t('react.project.tab.filters.display.type.tooltip')}>
                        <IconButton onClick={() => setChangeDisplayTypeIdentifier(identifier)}>
                            <AutoGraphIcon fontSize="small" sx={{verticalAlign: 'sub', display: mouseOverIdentifier === identifier ? 'inline-flex': 'none'}} />
                        </IconButton>
                    </Tooltip>
        </Box>);
    };

    return (
        <>
        <PopupTwoButtons 
            variant='warning'
            openState={deleteDialogOpen}
            callbackOnclose={() => setDeleteDialogOpen(false)}
            callbackOnclickLeftButton={() => setDeleteDialogOpen(false)}
            callbackOnclickRightButton={deleteSelectedCallback}
            title={t('react.project.tab.filters.delete.title')}
            content={t('react.project.tab.filters.delete.body')}
            leftButton={t('react.button.cancel')}
            rightButton={t('react.button.delete')}
        />
        <PopupTwoButtons 
            variant='question'
            openState={compoundDialogOpen}
            callbackOnclose={() => setCompoundDialogOpen(false)}
            callbackOnclickLeftButton={() => setCompoundDialogOpen(false)}
            callbackOnclickRightButton={createCompoundCallback}
            title={t('react.project.tab.filters.compound.title')}
            content={t('react.project.tab.filters.compound.body')}
            leftButton={t('react.button.cancel')}
            rightButton={t('react.button.confirm')}
        />
        <Dialog open={openSelectMergeDestination}>
            <DialogTitleWithCloseIcon
                startIcon={<WarningIcon color='secondary' sx={{marginRight: '12px', verticalAlign: 'sub'}} />}
                title={t('react.project.tab.filters.mergepopup.title')}
                callbackOnclose={handleCloseSelectDestination}
            />
            <DialogContent>
                <DialogContentText>
                    <Trans
                        i18nKey={selected.length > 0 ? 'react.project.tab.filters.merge.filters.text' : 'react.project.tab.filters.merge.filtervalues.text'}
                    ></Trans>
                </DialogContentText>
                <FormControl>
                    <RadioGroup 
                        name="merge-two-filters"
                        value={destinationId}
                        onChange={evt => setDestinationId(evt.target.value)}
                    >
                    {selected.length > 0 && filters.filter(f => !selected.includes(f.id)).map((f) => (
                        <FormControlLabel key={f.id} value={f.id} control={<Radio />} label={f.name} />
                    ))}
                    {selectedValues.length > 0 && selectedValuesParent > 0 && filters.filter(f => f.id === selectedValuesParent).map(f => f.values).flat().filter(fv => selectedValues.includes(fv.id)).map((fv) => (
                        <FormControlLabel key={fv.id} value={fv.id} control={<Radio />} label={fv.value} />
                    ))}
                    </RadioGroup>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseSelectDestination}>{t('react.button.cancel')}</Button>
                <Button onClick={handleValidMergeFilters} disabled={destinationId === 0}>{t('react.button.valid')}</Button>
            </DialogActions>
        </Dialog>
        <Grid item xs={12}>
            <Grid item xs={12} style={{position:'sticky', top: 60, zIndex: 99, background:"whitesmoke"}}>
            <Toolbar className={toolbarClasses.root} >
                <Typography className={toolbarClasses.title} variant="h6" id="tableTitle">
                    {t('react.project.tab.filters.title')}
                </Typography>
                <Tooltip title={t('react.project.tab.filters.selectall')}><span>
                    <IconButton
                        size="large"
                        onClick={selectUnselectAll}
                        >
                        <LibraryAddCheckIcon />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.project.tab.filters.delete')}><span>
                    <IconButton
                        size="large"
                        disabled={!(selected.length > 0 || selectedValues.length > 0)}
                        onClick={() => setDeleteDialogOpen(true)}
                        >
                        <DeleteIcon />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.project.tab.filters.move.values')}><span>
                    <IconButton
                        size="large"
                        disabled={selected.length === 0}
                        onClick={handleOpenSelectDestination}
                        >
                        <MoreIcon style={{ transform: 'scaleX(-1)' }} />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.project.tab.filters.merge')}><span>
                    <IconButton
                        size="large"
                        disabled={selectedValues.length < 2}
                        onClick={handleOpenSelectDestination}
                        >
                        <LayersIcon />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.project.tab.filters.compound')}><span>
                    <IconButton
                        size="large"
                        disabled={selectedValues.length <= 1 || hasCompoundSelected}
                        onClick={() => setCompoundDialogOpen(true)}
                        >
                        <GrAggregate fontSize="20px" />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.project.tab.filters.moveup')}><span>
                    <IconButton
                        size="large"
                        disabled={selected.length === 0 && selectedValues.length === 0}
                        onClick={() => handleMoveSelectionUp()}
                        >
                        <KeyboardArrowUpIcon />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.project.tab.filters.mouvedown')}><span>
                    <IconButton
                        size="large"
                        disabled={selected.length === 0 && selectedValues.length === 0}
                        onClick={() => handleMoveSelectionDown()}
                        >
                        <KeyboardArrowDownIcon />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.project.tab.filters.show')}><span>
                    <IconButton
                        size="large"
                        disabled={!(selected.length > 0 || selectedValues.length > 0)}
                        onClick={() => handleSetHidden(false)}
                        >
                        <VisibilityIcon />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.project.tab.filters.hide')}><span>
                    <IconButton
                        size="large"
                        disabled={!(selected.length > 0 || selectedValues.length > 0)}
                        onClick={() => handleSetHidden(true)}
                        >
                        <VisibilityOffIcon />
                    </IconButton></span>
                </Tooltip>
                <Tooltip title={t('react.button.refresh')}><span>
                    <IconButton
                        size="large"
                        onClick={handleRefresh}
                        >
                        <CachedIcon />
                    </IconButton></span>
                </Tooltip>
            </Toolbar>
            </Grid>
            <Grid item xs={12}>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell align="left" sx={{minWidth: '400px'}}>{t('react.project.tab.filters.name')}</TableCell>
                            <TableCell align="left" sx={{minWidth: '180px'}}>{t('react.project.tab.filters.dataType')}</TableCell>
                            {/* <TableCell align="left">{t('react.project.tab.filters.functionType')}</TableCell>
                            <TableCell align="left">{t('react.project.tab.filters.displayType')}</TableCell> */}
                            <TableCell align="left">{t('react.project.tab.filters.visibility')}</TableCell>
                            <TableCell align="left">{t('react.project.tab.filters.state')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {filters.map((filter) => (
                        <React.Fragment key={`fragment-${filter.id}`}>
                        <TableRow
                            key={filter.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <BaseLineTableCell>
                                {!expandedIds.has(filter.id) && <Tooltip title={t('react.project.tab.filters.deploy.tooltip')}>
                                    <IconButton onClick={(e) => handleExpand(e, filter)}>
                                        <KeyboardArrowRightIcon />
                                    </IconButton>
                                </Tooltip>}
                                {expandedIds.has(filter.id) && <Tooltip title={t('react.project.tab.filters.close.tooltip')}>
                                    <IconButton onClick={(e) => handleFold(e, filter)}>
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                </Tooltip>}
                                <Checkbox
                                    id={`checkbox-${filter.id}`}
                                    onClick={event => handleSelect(event, filter.id)}
                                    checked={selected.includes(filter.id)}
                                />
                            </BaseLineTableCell>
                            <BaseLineTableCell>
                                {printFilter(filter)}
                            </BaseLineTableCell>
                            {/* <BaseLineTableCell align="left">{filter.dataType}</BaseLineTableCell>
                            <BaseLineTableCell align="left">{filter.functionType}</BaseLineTableCell> */}
                            <BaseLineTableCell align="left">
                                {printDisplayType(filter)}
                            </BaseLineTableCell>
                            <BaseLineTableCell align="left">{filter.hidden ? t('react.project.tab.filters.hidden') : t('react.project.tab.filters.visible')}</BaseLineTableCell>
                            <BaseLineTableCell align="center">{getFilterAlerts(filter)}</BaseLineTableCell>
                        </TableRow>
                        <TableRow key={`${filter.id}-values`}> 
                            <TableCell style={{padding: '0px 0px 0px 110px'}} colSpan={5}>
                                {printFilterValues(filter)}
                            </TableCell>
                        </TableRow>
                        </React.Fragment>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>
            </Grid>
        </Grid></>);
};