import React, { useState, useEffect, useContext, useReducer } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';
import { makeStyles } from 'tss-react/mui';
import EditIcon from '@mui/icons-material/Edit';
import { SurveyContext } from './context';
import TripleStateButton from '../shared/TripleStateButton/TripleStateButton';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { IMAGE_WIDTH_AUTO } from '../../utils/surveysUtils';

const useStyles = makeStyles()(theme => ({
    editIcon: {
        marginRight: '10px',
        verticalAlign: 'middle'
    },
    dialogComponent: {
        minHeight: '400px',
    },
    flexTripleStateButton: {
        display: 'flex',
        marginBottom: 15
    },
    labelSwitchQuestion: {
        marginTop: 10,
        marginRight: 20
    },
    takeAllSpace: {
      width: '100%',
      flexDirection: 'row'
  },
}));

export default function ParamsImage(props) {

    const { classes } = useStyles();

    const { t, uuid, setOpenDialogImage, openDialogImage, enableFullScreenMode } = props;

    const { setNeedToSave, getComponentConfiguration, mediaFiles } = useContext(SurveyContext);
    const [ignored, forceUpdate] = useReducer(x => x + 1, 0);
    const [imageFullScreen, setImageFullScreen] = useState(false);
    const [imageWidth, setImageWidth] = useState(800);
    const [imagePosition, setImagePosition] = useState('left');

    useEffect(() => {
      if (openDialogImage) {
        setImageFullScreen(getComponentConfiguration(uuid).openImageFullscreen);
        setImageWidth(getComponentConfiguration(uuid).imageWidth);
        setImagePosition(getComponentConfiguration(uuid).imagePosition);
      }
    }, [openDialogImage])

    const changeField = (fieldName, value) => {
      switch (fieldName) {
        case "imageWidth":
          setImageWidth(value);
          break;
        case "imagePosition":
          setImagePosition(value);
          break;
        case "imageFullScreen":
          setImageFullScreen(!imageFullScreen);
          break;
      }
    }

    const handleChangeStateFilter = (value) => {
      changeField("imagePosition", value);
    };

    const handleSave = () => {
      getComponentConfiguration(uuid).openImageFullscreen = imageFullScreen;
      getComponentConfiguration(uuid).imagePosition = imagePosition;
      getComponentConfiguration(uuid).imageWidth = imageWidth;
      setNeedToSave(true);
      forceUpdate();
      setOpenDialogImage(false);
    }

    const cancelPopup = () => {
      setOpenDialogImage(false);
    }

    const marks = [
        {
          value: 400,
          label: '400px',
        },
        {
          value: 500,
          label: '500px',
        },
        {
          value: 600,
          label: '600px',
        },
        {
          value: 700,
          label: '700px',
        },
        {
          value: 800,
          label: '800px',
        },
        {
          value: 900,
          label: '900px',
        },
        {
          value: 1000,
          label: '1000px',
        },
        {
          value: 1100,
          label: '1100px',
        },
        {
          value: 1200,
          label: '1200px',
        },
      ];

    if (!openDialogImage) return null;

    return (
        <Dialog
          maxWidth={'xl'}
          fullWidth={true}
          open={openDialogImage} 
        >
          <DialogTitleWithCloseIcon
              startIcon={<EditIcon color='primary' className={classes.editIcon} />}
              title={t("react.surveys.params.image")}
              callbackOnclose={() => cancelPopup()}
          />
          <DialogContent component="div" className={classes.dialogComponent}>
            <div className={classes.flexTripleStateButton}>
                <div className={classes.labelSwitchQuestion}>{t('react.surveys.image.mode')}</div>
                <TripleStateButton
                    stateValues={['left', 'center', 'right']}
                    stateLabels={[t('react.surveys.image.left'), t('react.surveys.image.center'), t('react.surveys.image.right')]}
                    stateValue={imagePosition}
                    setStateValue={handleChangeStateFilter}
                    style = {{height: '25px', marginTop: '10px'}}
                />
            </div>
            {enableFullScreenMode && <FormControlLabel
              className={classes.takeAllSpace}
              control={<Switch checked={imageFullScreen} color="secondary" onChange={e => changeField('imageFullScreen', e.target.value)} />}
              label={t("react.project.collectforms.open.image.fullscreen")}
              labelPlacement="start"
              style={{marginLeft: -10, marginBottom: 10}}
            />}
            {<FormControlLabel
              className={classes.takeAllSpace}
              control={<Switch 
                color="secondary" 
                checked={imageWidth === IMAGE_WIDTH_AUTO} 
                onChange={e => changeField('imageWidth', imageWidth === IMAGE_WIDTH_AUTO ? 400 : IMAGE_WIDTH_AUTO)} />
              }
              label={t("react.surveys.image.width.auto")}
              labelPlacement="start"
              style={{marginLeft: -10, marginBottom: 10}}
            />}
            <div>{t('react.surveys.image.width')}</div>
            <Slider
              onChange={(e) => changeField('imageWidth', e.target.value)}
              value={imageWidth}
              style={{width: 600}}
              step={100}
              marks={marks}
              min={400}
              max={1200}
              disabled={imageWidth === IMAGE_WIDTH_AUTO}
            />
            <div style={{display: 'flex', justifyContent: 'center', marginTop: 30}}>
              <img
                style={{width:`${imageWidth === IMAGE_WIDTH_AUTO ? 'auto' : imageWidth+'px'}`}}
                src={mediaFiles[getComponentConfiguration(uuid).imageId]}
              />
            </div>
          </DialogContent>
          <DialogActions>
              <Button onClick={() => handleSave()}>{t('react.button.valid')}</Button>
          </DialogActions>
        </Dialog>
    )
}