import { RestClientService } from './RestClientService';
require('dotenv').config();

class AiCompanionService {

  constructor() {
    this.state = {
      restClient: RestClientService.getClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  // -- ADMINISTRATIVE METHODS - Auth required -------------------------------------------------------------------------------

  async postReviewToR3m(reviewsInputFileLang, formData) {
      return this.getRestClient().post(`/api/ai/companion/v1.0/reviews/to-r3m?lang=${reviewsInputFileLang}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'blob'
      });
  }

  async postGenerateCodeFrame(formData, lang, vs, vc, vl) {
    return this.getRestClient().post(`/api/ai/companion/v1.0/thematization/create-codeframe?lang=${lang}&vs=${vs}&vc=${vc}&vl=${vl}`, formData , {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    });
  }

  async postThematizeWithCodeFrame(formData, vs, vc, vl, ts, tl, lang) {
    return this.getRestClient().post(`/api/ai/companion/v1.0/thematization/thematize-verbatims?vs=${vs}&vc=${vc}&vl=${vl}&ts=${ts}&tl=${tl}&tc=0&lang=${lang}`, formData , {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    });
  }
  
  async postImproveCodeFrame(formData, vs, vc, vl, ts, tl, lang) {
    return this.getRestClient().post(`/api/ai/companion/v1.0/thematization/improve-codeframe?vs=${vs}&vc=${vc}&vl=${vl}&ts=${ts}&tl=${tl}&tc=0&lang=${lang}`, formData , {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      responseType: 'blob'
    });
  }
}

export default AiCompanionService;
