import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LinearProgress from '@mui/material/LinearProgress';
import Toolbar from '@mui/material/Toolbar';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {toolbarStyles} from '../../common.js';
import SequenceService from '../../services/SequenceService';
import { RestClientService } from '../../services/RestClientService';
import { AuthService } from '../../services/AuthService';
import Sequence from './Sequence'
import { makeStyles } from 'tss-react/mui';
import DeleteIcon from '@mui/icons-material/Delete';
import PopupTwoButtons from '../shared/PopupTwoButtons/PopupTwoButtons';
import {LoadData} from '../../Constants.js'

const sequenceService = new SequenceService();

const useStyles = makeStyles()(theme => ({
  helpButton: {
    color: '#66bb6a',
  },
}));

export default function PanelMultiAnswers(props) {

  const { t, showSpinner, openSnackbar, project } = props;

  const { classes } = useStyles();
  const { classes: toolbarClasses } = toolbarStyles();

  const [sequences, setSequences] = useState([]);
  const [selected, setSelected] = useState([]);
  const [warnIfNotAllCompleted, setWarnIfNotAllCompleted] = useState(false);

  const [loadData, setLoadData] = useState(LoadData.Load);
  useEffect(() => {
    if(loadData !== LoadData.Load) return;

    setLoadData(LoadData.Loading);

    sequenceService.fetchProjectSequences(project.id)
      .then(result => {
        // get project's sequences and other them by date desc
        let newSequences = result.data.hits
          // DO NOT DISPLAY STORAGE SEQUENCE HERE
          .filter(sequence => !sequence.storage)
          .sort(function(seq1, seq2) { return seq2.creationDate - seq1.creationDate; });
        setSequences(newSequences);

        // [R3MCORE-486] display warning if a sequence is not 'completed' after one or more completed
        if(AuthService.isAdmin()) {
          let hasCompleteBefore = false;
          for(let sequence of newSequences) {
            if(sequence.state === 'completed') hasCompleteBefore = true;
            else if(sequence.state !== 'cancelled' && hasCompleteBefore) {
              setWarnIfNotAllCompleted(true);
              break;
            }
          }
        }
      })
      .catch(err => {
        setSequences([]);
        openSnackbar('error', t("react.project.error.while.loading"));
      }).finally(() => {
        setLoadData(LoadData.Loaded);
      });
  }, [loadData, project]);

  const handleClickDownloadSampleFile = (event) => {
    event.preventDefault();
    let restClient = RestClientService.getClient();
    restClient.get('/api/references/v1.0/project/sample/input/excel', {responseType: 'blob'})
      .then(response => {
        RestClientService.processBinaryResponse(response);
      })
      .catch(err => {
        openSnackbar('error', t("react.project.error.download.sample"));
      });
  };

  const handleClickAddNewSequence = (event) => {
    event.preventDefault();

    var newSequence = {
      id : 0,
      projectId: project.id,
      name: t('react.default.sequence.name', {sequenceNumber: sequences.length + 1}),
      state: 'draft',
      creationDate: new Date(),
      precodeNonImpactingUnknownWords: false,
      precodeMisspelledUnknownWords: false,
    }

    setSequences([newSequence].concat(sequences));
  };

  const handleClickCheckBox = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const handleDeleteSequences = (event) => {
    event.preventDefault();
    setDeleteDialogOpen(true)
  };

  const handleDeleteDialogCancel = () => {
      setDeleteDialogOpen(false);
  };

  const onConfirmDeleteSelectedSequences = () => {
    showSpinner(true);

    var promises = [];
    for(var i = 0; i < selected.length; i++) {
      promises.push(sequenceService.deleteSequence(selected[i]));
    }

    Promise.all(promises)
    .then(function(values) {
      handleReloadSequencesPanel();
      openSnackbar('success', t("react.project.success.delete.sequence"));
    })
    .catch(err => {
      openSnackbar('error', t("react.project.error.delete.sequence"));
    })
    .finally(function() {
      setSelected([]);
      setDeleteDialogOpen(false);
      showSpinner(false);
    });
  };

  const handleReloadSequencesPanel = () => {
    setLoadData(LoadData.Load);
  };

  if(loadData !== LoadData.Loaded) {
    return (
      <Grid item xs={12} container>
        <Grid item xs={12}>
          <LinearProgress />
        </Grid>
      </Grid>
    );
  }

  const canIAddNewSequence = () => {
    return project.sequenceMode === 'multi' && sequences !== undefined && (sequences.length === 0 || sequences[0].state /* the last one */ === 'completed');
  };

  return (
    <Grid item xs={12} container>
      <PopupTwoButtons
          variant='warning'
          openState={deleteDialogOpen}
          callbackOnclose={handleDeleteDialogCancel}
          callbackOnclickLeftButton={handleDeleteDialogCancel}
          callbackOnclickRightButton={onConfirmDeleteSelectedSequences}
          title={t('react.project.edit.datatab.sequences.confirm.delete.title')}
          content={t('react.project.edit.datatab.sequences.confirm.delete.description')}
          leftButton={t('react.button.cancel')}
          rightButton={t('react.button.delete')}
      />
      <PopupTwoButtons
          variant='warning'
          openState={warnIfNotAllCompleted}
          callbackOnclose={() => setWarnIfNotAllCompleted(false)}
          callbackOnclickRightButton={() => setWarnIfNotAllCompleted(false)}
          title={t('react.project.sequence.warning.not.all.completed.title')}
          content={t('react.project.sequence.warning.not.all.completed')}
          leftButton={t('react.button.cancel')}
          rightButton={t('react.button.ok')}
      />
      <Grid item xs={12}>
        <Toolbar className={toolbarClasses.root}>
          <Typography variant="h6" className={toolbarClasses.title}  >
            {t("react.project.edit.datatab.sequences")}
          </Typography>

          {selected.length > 0 &&
              <Tooltip title={t('react.button.delete')}>
                <IconButton onClick={handleDeleteSequences} size="large">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
          }

          { project.sequenceMode === 'multi' &&
          <Tooltip title={t('react.project.edit.datatab.sequences.add.sequence')}>
            <span>
            <IconButton
              onClick={handleClickAddNewSequence}
              disabled={canIAddNewSequence() === false}
              size="large">
              <AddCircleOutlineIcon color={canIAddNewSequence() ? "primary" : "disabled"}/>
            </IconButton>
            </span>
          </Tooltip>
          }
          <Tooltip title={t("react.project.edit.datatab.button.sample")}>
            <IconButton
              onClick={handleClickDownloadSampleFile}
              size="large">
              <HelpOutlineIcon color={"primary"} className={classes.helpButton}/>
            </IconButton>
          </Tooltip>
        </Toolbar>
      </Grid>
      {
        sequences
          .map((sequence, idx) => {
            return <Sequence
                {...props}
                key={`sequence${sequence.id}`}
                isExpanded={idx === 0}
                sequenceNumber={sequences.length - idx}
                sequence={sequence}
                handleReloadSequencesPanel={handleReloadSequencesPanel}
                handleClickCheckBox={handleClickCheckBox}
              />
          })
        }
    </Grid>
  );
}
