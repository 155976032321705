import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "tss-react/mui";
import Grid from "@mui/material/Grid";
import { isBlank } from "../../../../utils/utils";
import DialogTitleWithCloseIcon from "../../../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import ThematizationService from "../../../../services/ThematizationService";

const thematizationService = new ThematizationService();

const useStyles = makeStyles()((theme) => ({
	dropzoneArea: {
		backgroundColor: '#F0F0F0',
		width: "100%",
	},
	container: {
		width: "800px",
	},
	flexBetween: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		gap: theme.spacing(3),
	}
}));

export default function ThematizationDialog(props) {
	const { t, openState, callbackOnclose, showSpinner, codePlanToUse, openSnackbar } = props;

	const { classes } = useStyles();

	const alphabet = "ABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");

	const [verbatimsInputFile, setVerbatimsInputFile] = useState({ name: "" });
	const [verbatimsTabNumber, setVerbatimsTabNumber] = useState(1);
	const [verbatimsColumnNumber, setVerbatimsColumnNumber] = useState('A');
	const [verbatimsDataLineNumber, setVerbatimsDataLineNumber] = useState(1);

	useEffect(() => {
		setVerbatimsInputFile({name: ""});
		setVerbatimsTabNumber(1);
		setVerbatimsColumnNumber('A');
		setVerbatimsDataLineNumber(1);
	}, [openState]);

	const handleConfirm = () => {
		showSpinner(true);
		event.preventDefault();
		let formData = new FormData();
		formData.append("verbatimFile", verbatimsInputFile);
		thematizationService.thematizeFromCodeFrame(formData, codePlanToUse.id, (verbatimsTabNumber - 1), alphabet.findIndex(letter => letter === verbatimsColumnNumber), (verbatimsDataLineNumber - 1))
			.then((response) => {
				openSnackbar("success", t("react.codification.toolbar.button.import.started"));
			})
			.catch((err) => {
				openSnackbar("error", t("react.codification.toolbar.button.import.failed"));
			})
			.finally(() => {
				showSpinner(false);
				setVerbatimsInputFile({ name: "" });
				setVerbatimsTabNumber(1);
				setVerbatimsColumnNumber('A');
				setVerbatimsDataLineNumber(1);
			});
		showSpinner(false);
		callbackOnclose();
	};

	const handleSelectUploadVerbatimsFileForVerbatimsThematization = (event) => {
		event.preventDefault();
		if (event.target.files.length === 1) {
			let fnlc = event.target.files[0].name.toLowerCase();
			if (fnlc.endsWith(".xlsx")) {
				setVerbatimsInputFile(event.target.files[0]);
			} else {
				openSnackbar("error", t("react.codification.import.library.error.unsupported"));
			}
		}
	};

	if (!openState) return null;

	return (
		<Dialog open={openState} onClose={callbackOnclose} fullWidth={true}>
			<DialogTitleWithCloseIcon
				title={t("react.aitools.thematization.thematize.verbatims.title")}
				callbackOnclose={callbackOnclose}
			/>
			<Grid container spacing={0} >
				<Grid item xs={12} className={classes.container}>
					<DialogContent>
						<Grid container spacing={3}>
							<Grid item xs={12}>
								<Grid item xs={12}>
									<Typography>{t("react.thematization.edit.files.load.data")}</Typography>
									<FormControl fullWidth>
										<Input
											disabled
											id="verbatimsInputFile-01"
											value={verbatimsInputFile.name}
											className={classes.textField}
											placeholder={t("react.thematization.edit.selectfiles")}
											fullWidth
											endAdornment={
												<InputAdornment position="end">
													<label htmlFor="verbatimsInputFile-button-01">
														<Button variant="contained" color="primary" component="span" >
															{t("react.dashboard.import.image.popup.chose")}
														</Button>
													</label>
												</InputAdornment>
											}
										/>
										<input
											key={Date.now()}
											onChange={handleSelectUploadVerbatimsFileForVerbatimsThematization}
											accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
											style={{ display: "none" }}
											id="verbatimsInputFile-button-01"
											data-testid="verbatimsInputFile"
											type="file"
										/>
									</FormControl>
								</Grid>
								<Grid item xs={12} paddingTop={5}>
									<Typography>{t("react.thematization.edit.files.data")}</Typography>
								</Grid>
								<Grid item xs={12} className={classes.flexBetween}>
									<TextField
										id="outlined-number"
										label={t("react.aitools.thematization.tab.number")}
										type="number"
										fullWidth
										data-testid="verbatimsTabNumber"
										inputProps={{ min: 1, max: 20 }}
										value={verbatimsTabNumber}
										onChange={(e) => setVerbatimsTabNumber(e.target.value)}
									/>
									<TextField
										id="outlined-select"
										select
										label={t("react.aitools.thematization.column.number")}
										fullWidth
										data-testid="verbatimsColumnNumber"
										value={verbatimsColumnNumber}
										onChange={(e) => setVerbatimsColumnNumber(e.target.value)}
									>
										{alphabet.map((letter) => (
											<MenuItem key={letter} value={letter}>
												{letter}
											</MenuItem>
										))}
									</TextField>
									<TextField
										id="outlined-number"
										label={t("react.aitools.thematization.line.number")}
										type="number"
										fullWidth
										data-testid="verbatimsDataLineNumber"
										inputProps={{ min: 1, max: 50 }}
										value={verbatimsDataLineNumber}
										onChange={(e) => setVerbatimsDataLineNumber(e.target.value)}
									/>
								</Grid>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions className={classes.flexBetween}>
						<Button
							variant="outlined"
							size="large"
							startIcon={<CloseIcon />}
							onClick={callbackOnclose}
							color="primary"
						>
							{t("react.button.cancel")}
						</Button>
						<Button
							variant="outlined"
							size="large"
							startIcon={<UploadFileIcon />}
							color="primary"
							disabled={isBlank(verbatimsInputFile.name)}
							onClick={handleConfirm}>
							{t("react.project.edit.collectforms.button.send")}
						</Button>
					</DialogActions>
				</Grid>
			</Grid>
		</Dialog>
	);
}
