import React, { createContext, useState } from 'react';

// -- Normal Survey Context -----------------------------

const CollectorContext = createContext();
const CollectorContextProvider = ({ children, values = {} }) => {

  return (
    <CollectorContext.Provider value={{...values}}>
      {children}
    </CollectorContext.Provider>
  );
};

export { CollectorContext, CollectorContextProvider };

// -- Survey Context for the TestMode --------------------

const CollectorTestContext = createContext();
const CollectorTestContextProvider = ({ children, values = {} }) => {

    const [automaticAnsweringCallback, setAutomaticAnsweringCallback] = useState(() => () => { /* DO NOTHING */ });

    const generateAnswerAutomatically = () => {
        automaticAnsweringCallback();
    };

    return (
      <CollectorTestContext.Provider value={{...values, ...{generateAnswerAutomatically, setAutomaticAnsweringCallback}}}>
        {children}
      </CollectorTestContext.Provider>
    );
};
  
export { CollectorTestContext, CollectorTestContextProvider };
