import React, { useState, useContext, useEffect } from 'react';
import SaveIcon from '@mui/icons-material/Save';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CloseIcon from '@mui/icons-material/Close';
import { isBlank } from '../../utils/utils';
import { SurveyContext } from './context';
import { v4 as uuidv4 } from 'uuid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import i18next from 'i18next';
import SubjectIcon from '@mui/icons-material/Subject';
import DialogTitleWithCloseIcon from '../shared/DialogTitleWithCloseIcon/DialogTitleWithCloseIcon';

export default function AnswerGeneratorDialog(props) {

    const { t, uuid, openPopupGenerator, setOpenPopupGenerator, classes, onSaveAnswerGeneratorCallback, syntaxStructure, 
        getNextAnswerRef, getNextItemRef
    } = props;

    const { getComponentConfiguration, getFormConfiguration } = useContext(SurveyContext);

    const [popupMode, setPopupMode] = useState(0);
    const [valueCustomAnswer, setValueCustomAnswer] = useState('');
    const [selectPreRecorded, setSelectPreRecorded] = useState('');
    const [paramNumericQuestion, setParamNumericQuestion] = useState({
        startNumber: 1,
        stepLength: 1,
        numberOfAnswers: 10,
        prefix: '',
        suffix: '',
    });

    const getLang = (key, language) => {
        return i18next.t(key, { lng: language });
    };
    
    const preRecordedResponses = [
        {title: t('react.collectforms.generator.title.sector'), text: getLang('react.collectforms.generator.content.sector', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.frequency1'), text: getLang('react.collectforms.generator.content.frequency1', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.frequency2'), text: getLang('react.collectforms.generator.content.frequency2', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.frequency3'), text: getLang('react.collectforms.generator.content.frequency3', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.age1'), text: getLang('react.collectforms.generator.content.age1', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.age2'), text: getLang('react.collectforms.generator.content.age2', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.approval1'), text: getLang('react.collectforms.generator.content.approval1', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.approval2'), text: getLang('react.collectforms.generator.content.approval2', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.liking'), text: getLang('react.collectforms.generator.content.liking', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.purchaseIntent'), text: getLang('react.collectforms.generator.content.purchaseIntent', getFormConfiguration().lang)},
        {title: t('react.collectforms.generator.title.zoneInFrance'), text: `Ile-de-France(75,77,78,91,92,93,94,95)<br/>Nord-Ouest(27,76,18,28,36,37,41,45,14,50,61,44,49,53,72,85,22,29,35,56,16,17,79,86)<br/>Nord-Est(59,62,54,55,57,88,67,68,25,39,70,90,08,10,51,52,02,60,80,21,58,71,89)<br/>Sud-Ouest(24,33,40,47,64,09,12,31,32,46,65,81,82,19,23,87)<br/>Sud-Est(01,07,26,38,42,69,73,74,03,15,43,63,11,30,34,48,66,04,05,06,13,83,84,200)`},
        {title: t('react.collectforms.generator.title.numericalscale'), text: '1<br/>2<br/>3<br/>4<br/>5<br/>6<br/>7<br/>8<br/>9<br/>10'},
    ];

    useEffect(() => {
        if(!openPopupGenerator) return;

        setPopupMode(0);
        setValueCustomAnswer('');
        setParamNumericQuestion({
            startNumber: 1,
            stepLength: 1,
            numberOfAnswers: 10,
            prefix: '',
            suffix: '',
        });
        if (syntaxStructure === "question") {
            var valueCustomAnswer = getComponentConfiguration(uuid).question.answers.map(a => a.answer).join("\n");
        } else if (syntaxStructure === "battery_answers") {
            var valueCustomAnswer = getComponentConfiguration(uuid).battery.answers.map(a => a.text).join("\n");
        } else if (syntaxStructure === "battery_items") {
            var valueCustomAnswer = getComponentConfiguration(uuid).battery.items.map(a => a.name).join("\n");
        }
        setValueCustomAnswer(valueCustomAnswer);

        setSelectPreRecorded('');
    }, [openPopupGenerator])

    const handleSave = () => {
        const lines = valueCustomAnswer.split("\n").filter(l => l.length !== 0);
        if (popupMode === 0) {
            if (syntaxStructure === "question") {
                // Supprime les éléments qui étaient dans l'ancien tableau et qui ne sont pas dans le nouveau tableau
                getComponentConfiguration(uuid).question.answers = getComponentConfiguration(uuid).question.answers.filter(a => {
                    return lines.find(l => l === a.answer)
                });
                // Ajoute les éléments qui sont dans le nouveau tableau mais qui n'étaient pas dans l'ancien
                for (const element of lines) {
                    if (getComponentConfiguration(uuid).question.answers.find(a => a.answer === element) === undefined) {
                        getComponentConfiguration(uuid).question.answers.push({
                            answer: element.replace(/^[|,]+|[|,]+$/g, '').trim(),
                            quota: 0,
                            screenOut: false,
                            fixedPosition: false,
                            exclusive: false,
                            uuid: uuidv4(),
                            ref: getNextAnswerRef()
                        });
                    }
                }
            } else if (syntaxStructure === "battery_answers") {
                getComponentConfiguration(uuid).battery.answers = getComponentConfiguration(uuid).battery.answers.filter(a => {
                    return lines.find(l => l === a.text)
                });
                for (const element of lines) {
                    if (getComponentConfiguration(uuid).battery.answers.find(a => a.text === element) === undefined) {
                        getComponentConfiguration(uuid).battery.answers.push({
                            text: element.replace(/^[|,]+|[|,]+$/g, '').trim(),
                            uuid: uuidv4(),
                            ref: getNextAnswerRef()
                        });
                    }
                }
            } else if (syntaxStructure === "battery_items") {
                getComponentConfiguration(uuid).battery.items = getComponentConfiguration(uuid).battery.items.filter(i => {
                    return lines.find(l => l === i.name)
                });
                for (const element of lines) {
                    if (getComponentConfiguration(uuid).battery.items.find(i => i.name === element) === undefined) {
                        getComponentConfiguration(uuid).battery.items.push({
                            id: 0, 
                            batteryId: 0, 
                            uuid: uuidv4(),
                            name: element.replace(/^[|,]+|[|,]+$/g, '').trim(),
                            text: `<p>${element.replace(/^[|,]+|[|,]+$/g, '').trim()}</p>`,
                            textLeft: '',
                            textRight: '',
                            ref: getNextItemRef()
                        });
                    }
                }
            }  
        } else if (popupMode === 1) {
            Number.prototype.countDecimals = function () {
                if (Math.floor(this.valueOf()) === this.valueOf()) return 0;
                return this.toString().split(".")[1].length || 0;
            }
            let numberOfDecimals = Number(paramNumericQuestion.stepLength).countDecimals() > Number(paramNumericQuestion.startNumber).countDecimals() ? Number(paramNumericQuestion.stepLength).countDecimals() : Number(paramNumericQuestion.startNumber).countDecimals();
            if (syntaxStructure === "question") {
                getComponentConfiguration(uuid).question.answers = [];
                for (var i = 0; i < paramNumericQuestion.numberOfAnswers; i++) {
                    getComponentConfiguration(uuid).question.answers.push({
                        answer: i === 0 ? `${paramNumericQuestion.prefix}` + Number(paramNumericQuestion.startNumber).toFixed(numberOfDecimals) + `${paramNumericQuestion.suffix}` : `${paramNumericQuestion.prefix}` + (Number(paramNumericQuestion.startNumber) + Number(paramNumericQuestion.stepLength) * i).toFixed(numberOfDecimals) + `${paramNumericQuestion.suffix}`,
                        quota: 0,
                        screenOut: false,
                        fixedPosition: false,
                        exclusive: false,
                        uuid: uuidv4(),
                        ref: getNextAnswerRef()
                    });
                }
            } else if (syntaxStructure === "battery_answers") {
                getComponentConfiguration(uuid).battery.answers = [];
                for (var i = 0; i < paramNumericQuestion.numberOfAnswers; i++) {
                    getComponentConfiguration(uuid).battery.answers.push({
                        text: i === 0 ? `${paramNumericQuestion.prefix}` + Number(paramNumericQuestion.startNumber).toFixed(numberOfDecimals) + `${paramNumericQuestion.suffix}` : `${paramNumericQuestion.prefix}` + (Number(paramNumericQuestion.startNumber) + Number(paramNumericQuestion.stepLength) * i).toFixed(numberOfDecimals) + `${paramNumericQuestion.suffix}`,
                        uuid: uuidv4(),
                        ref: getNextAnswerRef()
                    });
                }
            }  
        }
        onSaveAnswerGeneratorCallback();
    };

    const selectPrecordedFunction = (textP) => {
        setValueCustomAnswer(textP.replace(/<br\s*\/?>/g, "\n"));
        setSelectPreRecorded(textP);
    }

    const setParamsNumericQuestion = (value, variable) => {
        setParamNumericQuestion({ ...paramNumericQuestion, [variable]: value });
    };

    const isFormInvalid = () => {
        if(popupMode === 0) {
            return isBlank(valueCustomAnswer);
        } else if(popupMode === 1) {
            return  isBlank(paramNumericQuestion.numberOfAnswers) || 
                    isBlank(paramNumericQuestion.stepLength) ||
                    isBlank(paramNumericQuestion.startNumber) ||
                    paramNumericQuestion.numberOfAnswers > 20;
        }
        return true;
    }

    if(!openPopupGenerator) return null;

    return (
        <Dialog open={openPopupGenerator} onClose={() => setOpenPopupGenerator(false)}>
            <DialogTitleWithCloseIcon
                startIcon={<SubjectIcon color='primary' style={{marginRight: '10px', verticalAlign: 'middle'}} />}
                title={syntaxStructure === "battery_items" ? t("react.project.collectforms.customitem.title") : t("react.project.collectforms.customquestion.title")}
                callbackOnclose={() => setOpenPopupGenerator(false)}
            />
            <DialogContent>  
                {syntaxStructure !== "battery_items" && <div style={{display: 'flex'}}>
                    <Button onClick={() => setPopupMode(0)} variant={popupMode === 0 ? "contained" : "outlined"} style={{ marginRight: 5, width: 220, fontSize: 13 }} color="primary">{t("react.project.collectforms.customquestion.button")}</Button>
                    <Button onClick={() => setPopupMode(1)} variant={popupMode === 1 ? "contained" : "outlined"} style={{width: 220, fontSize: 13}} color="primary">{t("react.project.collectforms.numericalscale.title")}</Button>
                </div>}
                {syntaxStructure !== "battery_items" && popupMode === 0 && <FormControl variant="standard" style={{width: 220, marginTop: 10}}>
                    <InputLabel>{t('react.collectforms.generator.inputlabel')}</InputLabel>
                    <Select
                        value={selectPreRecorded}
                        onChange={e => selectPrecordedFunction(e.target.value)}
                    >
                        {preRecordedResponses.map((r, index) => (
                            <MenuItem key={index} value={r.text}>{r.title}</MenuItem>
                        ))}
                    </Select>
                </FormControl>}
            </DialogContent>
            {popupMode === 0 ?
            <DialogContent className={classes.dialogComponent}>
                <TextField
                    variant='outlined'
                    fullWidth
                    multiline={true}
                    minRows='12'
                    label={syntaxStructure === "battery_items" ? t("react.project.collectforms.customitemcontent") : t("react.project.collectforms.customquestioncontent")}
                    value={valueCustomAnswer}
                    onChange={e => setValueCustomAnswer(e.target.value)}
                    style={{marginTop: -10}}
                />
            </DialogContent>  :
            <DialogContent component="div" className={classes.dialogComponent}>
                <div className={classes.marginResponses}>
                    <TextField
                        label={t("react.project.collectforms.digitalscale.startnumber")}
                        variant="outlined"
                        fullWidth
                        value={paramNumericQuestion.startNumber}
                        onChange={(e) => setParamsNumericQuestion(e.target.value, "startNumber")}
                        error={isBlank(paramNumericQuestion.startNumber)}
                        type="number"
                    />
                </div>
                <div className={classes.marginResponses}>
                    <TextField
                        label={t("react.project.collectforms.digitalscale.step")}
                        variant="outlined"
                        fullWidth
                        value={paramNumericQuestion.stepLength}
                        onChange={(e) => setParamsNumericQuestion(e.target.value, "stepLength")}
                        error={isBlank(paramNumericQuestion.stepLength)}
                        type="number"
                    />
                </div>
                <div className={classes.marginResponses}>
                    <TextField
                        label={t("react.project.collectforms.digitalscale.numberOfValues")}
                        variant="outlined"
                        fullWidth
                        value={paramNumericQuestion.numberOfAnswers}
                        onChange={(e) => setParamsNumericQuestion(e.target.value, "numberOfAnswers")}
                        error={isBlank(paramNumericQuestion.numberOfAnswers) || paramNumericQuestion.numberOfAnswers > 20}
                        helperText={paramNumericQuestion.numberOfAnswers > 20 && t("react.project.collectforms.digitalscale.helperText.numberOfAnswers")}
                        type="number"
                    />
                </div>
                <Grid container spacing={2} style={{ marginTop: 4 }}>
                    <Grid item xs={6}>
                        <TextField
                            label={t("react.project.collectforms.digitalscale.prefix")}
                            variant="outlined"
                            fullWidth
                            value={paramNumericQuestion.prefix}
                            onChange={(e) => setParamsNumericQuestion(e.target.value, "prefix")}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            label={t("react.project.collectforms.digitalscale.suffix")}
                            variant="outlined"
                            fullWidth
                            value={paramNumericQuestion.suffix}
                            onChange={(e) => setParamsNumericQuestion(e.target.value, "suffix")}
                            placeholder="€"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                </Grid>
            </DialogContent>}
        <DialogActions>
            <Button
                variant="outlined"
                size="large"
                startIcon={<CloseIcon />}
                onClick={() => setOpenPopupGenerator(false)}
            >
                {t('react.button.cancel')}
            </Button>
            <Button
                variant="outlined"
                size="large"
                startIcon={<SaveIcon />}
                color="primary"
                disabled={isFormInvalid()}
                onClick={() => handleSave()}
            >
                {t('react.button.save')}
            </Button>
        </DialogActions>
    </Dialog>
    );
}