import axios from 'axios';
require('dotenv').config();

export const RestClientService = {
    getClient,
    getAnonymousClient,
    setBasicAuth,
    setBearerAuth,
    getAiBridgeRestClient,
    processBinaryResponse,
};

// -- Authenticated backend Rest client -----------------------------------

const backendRestClient = axios.create({
    baseURL: process.env.REACT_APP_R3MSCORE_API_BASEURL,
    headers: { 'Content-Type': 'application/json' },
    logoutAfter403RedirectLoop: false
});

backendRestClient.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (401 === error.response.status || backendRestClient.defaults.logoutAfter403RedirectLoop) {
        window.location = '/logout';
    } else if (403 === error.response.status) {
        backendRestClient.defaults.logoutAfter403RedirectLoop = true;
        window.location = '/dashboard?error=403';
    } else {
        return Promise.reject(error);
    }
});

function getClient() {
    return backendRestClient;
}

function setBasicAuth(authdata) {
    backendRestClient.defaults.headers.common['Authorization'] = 'Basic ' + authdata;
}

function setBearerAuth(token) {
    backendRestClient.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

// -- Anonymous backend Rest client -----------------------------------

function getAnonymousClient() {
    return axios.create({
        baseURL: process.env.REACT_APP_R3MSCORE_API_BASEURL,
        headers: { 'Content-Type': 'application/json' }
    });
}

// -- Authenticated aiBridge Rest client -----------------------------------

const aiBridgeRestClient = axios.create({
    baseURL: process.env.REACT_APP_R3MSCORE_API_IABRIDGE_BASEURL,
    headers: { 'Content-Type': 'application/json' },
});

aiBridgeRestClient.defaults.headers.common['Authorization'] = 'Bearer ' + process.env.REACT_APP_R3MSCORE_API_IABRIDGE_TOKEN;

function getAiBridgeRestClient() {
    return aiBridgeRestClient;
}

function processBinaryResponse(response) {
    var regExp = new RegExp('filename="([^"]+)"', 'i');
    let filename = regExp.exec(response.headers['content-disposition'])[1];
    let url = window.URL.createObjectURL(new Blob([response.data]));
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
}