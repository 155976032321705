import { RestClientService } from './RestClientService';
require('dotenv').config();

class ThematizationService {

    constructor() {
        this.state = {
            restClient: RestClientService.getClient()
        };
    }

    getRestClient() {
        return this.state.restClient;
    }

    async getAllThematizations(page, rowsPerPage, sortBy, sortDirection, keywords) {
        return this.getRestClient().get(`/api/thematizations/v1.0/?include=users&from=${page*rowsPerPage}&size=${rowsPerPage}&sort=${sortBy}&order=${sortDirection}&keywords=${encodeURIComponent(keywords)}`);
    }

    async getThematization(thematizationId) {
        return this.getRestClient().get('/api/thematizations/v1.0/' + thematizationId);
    }

    async deleteThematizations(thematizationsIds) {
        return this.getRestClient().delete(`/api/thematizations/v1.0/${thematizationsIds}`);
    }

    async saveThematization(thematization) {
        if(thematization.id === 0) {
            return this.getRestClient().post('/api/thematizations/v1.0/', thematization);
        } else {
            return this.getRestClient().put('/api/thematizations/v1.0/' + thematization.id, thematization);
        }
    }

    async thematizeFromCodeFrame(formData, thematizationId, vs, vc, vl) {
        return this.getRestClient().post(`/api/thematizations/v1.0/${thematizationId}/thematize-verbatims/?vs=${vs}&vc=${vc}&vl=${vl}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
        });
    }

    async createCodeframeWithVerbatimsInThematization(thematizationId, formData, vs, vc, vl) {
        return this.getRestClient().post(`/api/thematizations/v1.0/${thematizationId}/create-codeframe-with-verbatims/?vs=${vs}&vc=${vc}&vl=${vl}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
        });
    }

    async loadCodeframeInThematization(thematizationId, formData, sheetIdx, startAtRow) {
        return this.getRestClient().post(`/api/thematizations/v1.0/${thematizationId}/load-codeframe/?sheetIdx=${sheetIdx}&startAtRow=${startAtRow}`, formData , {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob'
        })
    }
}

export default ThematizationService;
