import { RestClientService } from './RestClientService';
require('dotenv').config();

class ReferenceService {

  constructor() {
    this.state = {
      restClient: RestClientService.getClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  async fetchCoefficients() {
    return this.getRestClient().get('/api/references/v1.0/coefficients');
  }
}

export default ReferenceService;
