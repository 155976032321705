import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, Button, Typography, Grid, TextField } from '@mui/material';
import { isBlankHtml, renderLocaleNames } from '../../utils/surveysUtils.js';
import QuillEditor from './QuillEditor.js';

export default function TranslationEditDialog(props) {

    const { open, t, 
        callbackOnSave, callbackOnCancel,
        allTranslations, elementToEdit, classes
    } = props;

    const quillRef = useRef();

    const [editedValue, setEditedValue] = useState(undefined);

    useEffect(() => {
        if (!open || !elementToEdit) return;

        setEditedValue(elementToEdit.content);
    }, [open, elementToEdit]);

    const handleClose = () => {
        setEditedValue(undefined);
        callbackOnCancel();
    };

    const handleConfirm = () => {
        const result = editedValue;
        setEditedValue(undefined);
        callbackOnSave(editedLocale, elementToEdit, result);
    };

    if(!open || !elementToEdit ) return null;

    // IMPORTANT NOTE: if primaryElement is editable => IT LABEL SO WE DON'T USE QUILL
    const primaryTranslation = allTranslations.find(t => t.primary);
    const primaryElement = primaryTranslation.elements.find(e => e.uniqueKey === elementToEdit.uniqueKey);

    const editedLocale = allTranslations.find(t => t.elements.findIndex(e => e.id === elementToEdit.id) >= 0).locale;

    return (<Dialog open={open} onClose={handleClose} maxWidth={'md'} fullWidth={true}>
        <DialogContent>
            <Typography style={{ marginBottom: 20 }}>{t("react.survey.translations.modify.translation")}{primaryElement.itemRef}</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>{t("react.survey.translations.language")}{renderLocaleNames(primaryTranslation.locale)}</Typography>
                    <div className={classes.windowPrimaryText}>
                        <div dangerouslySetInnerHTML={{
                            __html:
                            primaryElement.content
                        }} />
                    </div>
                </Grid>
                <Grid item xs={6}>
                    <Typography style={{ marginTop: 10 }}>{t("react.survey.translations.language")}{renderLocaleNames(editedLocale)}</Typography>
                    {primaryElement.editable ? 
                        <TextField
                            variant='outlined'
                            multiline={true}
                            minRows={16}
                            maxRows={50}
                            margin="dense"
                            type="text"
                            value={editedValue}
                            fullWidth
                            style={{ height: 400, paddingBottom: 42, marginTop: 10 }}
                            onChange={e => setEditedValue(e.target.value)}
                        />
                        : 
                        <QuillEditor
                            ref={quillRef}
                            value={editedValue}
                            onChange={(v) => setEditedValue(v)}
                            isError={isBlankHtml(editedValue)}
                            style={{ height: 400, paddingBottom: 42, marginTop: 10 }}
                            placeholder={t("react.project.collectforms.content")}
                            {...props}
                        />}
                </Grid>
            </Grid>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose}>{t('react.button.cancel')}</Button>
            <Button onClick={handleConfirm}>{t('react.button.save')}</Button>
        </DialogActions>
    </Dialog>);
}