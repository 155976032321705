import { RestClientService } from './RestClientService';
require('dotenv').config();

class SequenceService {

  constructor() {
    this.state = {
      restClient: RestClientService.getClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  async fetchProjectSequences(projectId, withStimulus = false, onlyVisible = false) {
    const params = [];
    if(withStimulus) params.push('include=stimuli');
    if(onlyVisible) params.push('onlyVisible=true');
    return this.getRestClient().get(`/api/sequences/v1.0/project/${projectId}?${params.join('&')}`);
  }

  async fetchProjectDefaultSequence(projectId) {
      return this.getRestClient().get(`/api/sequences/v1.0/project/${projectId}/default-sequence`);
  }

  async fetchSequenceSubscriptionForecast(sequenceId) {
      return this.getRestClient().get(`/api/subscriptions/v1.0/sequence/${sequenceId}/forecast`);
  }

  async fetchSequence(sequenceId) {
      return this.getRestClient().get(`/api/sequences/v1.0/${sequenceId}`);
  }

  async saveSequence(sequence) {
    if(sequence.id === 0) {
      return this.getRestClient().post('/api/sequences/v1.0/', sequence);
    } else {
      return this.getRestClient().put(`/api/sequences/v1.0/${sequence.id}`, sequence);
    }
  }

  async updateSequenceState(sequenceId, newState) {
    return this.getRestClient().put(`/api/sequences/v1.0/${sequenceId}/state/${newState}`);
  }

  async updatePrecodeNonImpactingUnknownWords(sequenceId, valueState) {
    return this.getRestClient().put(`/api/sequences/v1.0/${sequenceId}/precodeNonImpactingUnknownWords/${valueState}`);
  }

  async updatePrecodeMisspelledUnknownWords(sequenceId, valueState) {
    return this.getRestClient().put(`/api/sequences/v1.0/${sequenceId}/precodeMisspelledUnknownWords/${valueState}`);
  }

  async deleteSequence(sequenceId) {
    return this.getRestClient().delete(`/api/sequences/v1.0/${sequenceId}`);
  }

  async uploadAnswersFile(sequenceId, file) {
      var formData = new FormData();
      formData.append("ignoreConflicts", true);
      formData.append("uploadedFile", file);

      return this.getRestClient().post(`/api/files/v1.0/sequence/${sequenceId}/answers`, formData, {
          headers: {
              'Content-Type': 'multipart/form-data'
          }
      });
  }

  async deleteAnswersFile(fileId) {
      return this.getRestClient().delete(`/api/files/v1.0/answers/${fileId}`);
  }

  async fetchAnswersFile(sequenceId) {
      return this.getRestClient().get(`/api/files/v1.0/sequence/${sequenceId}/answers`);
  }

  async fetchFiltersFile(sequenceId) {
    return this.getRestClient().get(`/api/files/v1.0/sequence/${sequenceId}/filters`);
  }

  async importFiltersFile(sequenceId, file) {
    var formData = new FormData();
    formData.append("uploadedFile", file);

    return this.getRestClient().post(`/api/sequences/v1.0/${sequenceId}/import-filters`, formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    });
  }
}

export default SequenceService;
