import React, { useEffect, useState } from 'react';
import { makeStyles } from 'tss-react/mui';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableHead from '@mui/material/TableHead';

const useStyles = makeStyles()(theme => ({
    container: {
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 3,
        paddingBottom: 3,
        height: 400,
        overflowY: 'auto'
    },
    titleCard: {
        fontSize: '1.2em', 
        fontWeight: 'bold',
        color: 'rgb(51,51,51)',
        marginBottom: 5
    },
}));

export default function OngoingStopsChart(props) {
    const { classes } = useStyles();

    const {
        t,
        ongoingStops,
        completionStates,
        survey,
        dashboardDataSurvey
    } = props;

    const findBlockTitle = (blockId) => {
        const formBlock = survey.blocks.find(b => b.id == blockId);

        if (blockId == 0) {
            return t('react.project.collectforms.block.introduction.title');
        } else if (!formBlock) {
            return t('react.surveyReporting.onGoingStops.delete.block.title');
        } else {
            var blockName = '';
            const dashboardBlock = dashboardDataSurvey.find(b => b.blockId == blockId);
            if (dashboardBlock && dashboardBlock.stimulusName) {
                blockName = dashboardBlock.stimulusName;
            } else if (dashboardBlock && dashboardBlock.title) {
                blockName = dashboardBlock.title;
            } else if (formBlock.type === "experience") {
                blockName = formBlock.experience.stimulusName;
            }  else if (formBlock.type === "thankyou") {
                blockName = t('react.project.collectforms.block.thankyou.title');
            } else if (formBlock.type === "openQuestion") {
                blockName = formBlock.openQuestion.stimulusName
            } else if (formBlock.type === "text") {
                blockName = t('react.project.collectforms.block.text.title');
            }
            return `${formBlock.ref}: ${blockName}`;
        }
    }

    const ids = survey.blocks.map(item => item.id);
    const ongoingEntries = Object.entries(ongoingStops);
    var sortedValues = [];

    ongoingEntries.forEach(([key, value], index) => {
        const findIndex = ids.indexOf(parseInt(key, 10));

        // Block introduction = index 0
        if (key == 0) {
            sortedValues[0] = [key, value];
        } else if (findIndex !== -1) {
            sortedValues[findIndex+1] = [key, value];
        } else {
            // Blocks deleted = end of the array
            sortedValues[index+1 + ongoingEntries.length] = [key, value];
        }
    });

    return (
        <div className={classes.container}>
            <div className={classes.titleCard}>
                {t("react.surveyReporting.ongoingStopsChart.title", {count: completionStates.ONGOING})}
            </div>
            {sortedValues.length > 0 && <Table size="small"> 
                <TableHead>
                    <TableRow>
                        <TableCell>{t("react.surveyReporting.ongoingStopsChart.nameBlock")}</TableCell>
                        <TableCell>{t("react.surveyReporting.ongoingStopsChart.numberGiveup")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {sortedValues.map((block, index) => (
                        <TableRow key={`stimulus-${index}`}>
                            <TableCell>{findBlockTitle(block[0])}</TableCell>
                            <TableCell>{block[1]}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>}
        </div>
    );
}