import React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import { makeStyles } from 'tss-react/mui';
import { isBlank } from '../../utils/utils';
import MicOffOutlinedIcon from '@mui/icons-material/MicOffOutlined';
import MicNoneOutlinedIcon from '@mui/icons-material/MicNoneOutlined';

const useStyles = makeStyles()(theme => ({
    powerSettingsNewIcon: {
      float: 'right',
    },
  }));
  
export default function BlockHeader(props) {

    const { t, blockRef, setBlockRef, isActive, setIsActive, title, hideRefInTitle } = props

    const { classes } = useStyles();

    const handleActiveButton = (event) => {
        event.preventDefault();
        event.stopPropagation();
        setIsActive(event.target.value);
    };

    const handleChangeBlockRef = (event) => {
        var correctedValue = checkEnteredValue(event.target.value);
        if(correctedValue === null) return;
        setBlockRef(correctedValue);
    };

    /**
     * [R3MSCORE-905] for the fonction replaceDynamicElements()
     * Rules for the block ref: 
     * - Allowed chars: A to Z, 0 to 9 and underscores
     * - Can't start by "VAR_XXX"
     * - Can't start by 'AXX', 'IXX' ou 'ZXX' 
     * - Uppercase only
     * - Whitespaces are forbidden
     */
    const checkEnteredValue = (value) => {
        if(isBlank(value)) return '';

        let text = value.toUpperCase();

        const regex = /^[A-Z0-9_]+$/;
        if (!regex.test(text)) {
            return null;
        }

        const startsWithForbiddenPrefix = /^(VAR_|A\d+|I\d+|Z\d+)/;
        if (startsWithForbiddenPrefix.test(text)) {
            return null;
        }

        return text;
    };

    const getActiveIcon = () => {
        return isActive ? <MicNoneOutlinedIcon/> : <MicOffOutlinedIcon />;
    };

    var titleToDisplay = hideRefInTitle ? title : `${title} - ${blockRef}`;

    return (
        <Accordion >
            <AccordionSummary
                expandIcon={<ArrowDropDownIcon />}
            >
                <Typography variant="h6" component="h6" width={'100%'}>
                    {titleToDisplay}
                    <Tooltip title={t(isActive ? "react.project.collectforms.disable" : "react.project.collectforms.enable")} className={classes.powerSettingsNewIcon}>
                        <IconButton
                            color={isActive ? 'success' : 'error'}
                            onClick={handleActiveButton}>
                                {getActiveIcon()}
                        </IconButton>
                    </Tooltip>
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <TextField 
                    label={t("react.project.collectforms.block.ref")} 
                    fullWidth 
                    value={blockRef} 
                    onChange={handleChangeBlockRef}
                    error={isBlank(blockRef)}
                />
            </AccordionDetails>
        </Accordion>
    );
};