import { RestClientService } from './RestClientService';
require('dotenv').config();

class AnalyticsService {

  constructor() {
    this.state = {
      restClient: RestClientService.getClient()
    };
  }

  getRestClient() {
    return this.state.restClient;
  }

  async fetchProjectsAnalytics(projectId) {
    return this.getRestClient().get(`/api/analytics/v1.0/project/${projectId}`);
  }

  async fetchAnalyticsContent(analyticsId) {
    return this.getRestClient().get(`/api/analytics/v1.0/${analyticsId}/content`);
  }

  async fireAnalyticsComputations(projectId, sequenceId, type) {
    return this.getRestClient().post(`/api/analytics/v1.0/compute/project/${projectId}/sequence/${sequenceId}/type/${type}`);
  }

  async fetchAnalyticsAsExcelFileMode(analyticsId) {
    return this.getRestClient().get(`/api/analytics/v1.0/${analyticsId}/export/xlsx`, { responseType: 'blob' });
  }
}

export default AnalyticsService;
